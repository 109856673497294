"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  collab
}) => generator({
  createComment: data => ({
    baseURL: collab,
    method: 'post',
    url: 'comments',
    data
  }),
  getComments: (moduleId, searchProps) => ({
    baseURL: collab,
    method: 'get',
    url: 'comments',
    config: {
      params: {
        moduleId,
        ...searchProps
      }
    }
  }),
  updateComment: (commentId, data, moduleId) => ({
    baseURL: collab,
    method: 'put',
    url: `comments/${commentId}`,
    config: {
      params: {
        moduleId
      }
    },
    data
  }),
  deleteComment: (commentId, moduleId) => ({
    baseURL: collab,
    method: 'delete',
    config: {
      params: {
        moduleId
      }
    },
    url: `comments/${commentId}`
  }),
  getCommentsByContainer: (ids, moduleId) => ({
    baseURL: collab,
    method: 'get',
    config: {
      params: {
        widget: ids,
        moduleId
      }
    },
    url: `comments`
  })
});
exports.default = _default;