import React from 'react'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItem from '@mui/material/ListItem'
import Logout from '../Logout'
import WelcomeDisplay from '../WelcomeDisplay'
import HelpLink from '../HelpLink'
import userManager from '../../lib/userManager'

const openZendesk = e => {
    e.preventDefault()
    e.stopPropagation()
    window.open('https://tada.zendesk.com/hc/en-us', '_blank')
}

const UserMenu = props => {
    const { user } = props
    return (
        <MenuList>
            <ListItem><WelcomeDisplay username={user.username} /></ListItem>
            <MenuItem onClick={openZendesk}><HelpLink disable /></MenuItem>
            <MenuItem onClick={() => userManager.signoutRedirect()}><Logout /></MenuItem>
        </MenuList>
    )
}

export default UserMenu
