"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  fetch: () => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sessions`
  }),
  create: (orgCloudName, projectId, name) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `sessions`,
    data: {
      orgCloudName,
      projectId,
      name
    }
  }),
  update: session => ({
    baseURL: orgWeb,
    method: 'put',
    url: `sessions`,
    data: session
  }),
  delete: sessionKey => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `sessions/${sessionKey}`
  })
});
exports.default = _default;