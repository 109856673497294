"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _announcement = _interopRequireDefault(require("./announcement"));
var _application = _interopRequireDefault(require("./application"));
var _architecture = _interopRequireDefault(require("./architecture"));
var _auth = _interopRequireDefault(require("./auth"));
var _comment = _interopRequireDefault(require("./comment"));
var _data = _interopRequireDefault(require("./data"));
var _database = _interopRequireDefault(require("./database"));
var _datasource = _interopRequireDefault(require("./datasource"));
var _definition = _interopRequireDefault(require("./definition"));
var _deployment = _interopRequireDefault(require("./deployment"));
var _filter = _interopRequireDefault(require("./filter"));
var _forms = _interopRequireDefault(require("./forms"));
var _insight = _interopRequireDefault(require("./insight"));
var _job = _interopRequireDefault(require("./job"));
var _log = _interopRequireDefault(require("./log"));
var _mlModel = _interopRequireDefault(require("./mlModel"));
var _model = _interopRequireDefault(require("./model"));
var _module2 = _interopRequireDefault(require("./module"));
var _notification = _interopRequireDefault(require("./notification"));
var _platform = _interopRequireDefault(require("./platform"));
var _project = _interopRequireDefault(require("./project"));
var _publishLog = _interopRequireDefault(require("./publishLog"));
var _report = _interopRequireDefault(require("./report"));
var _scenario = _interopRequireDefault(require("./scenario"));
var _schedule = _interopRequireDefault(require("./schedule"));
var _search = _interopRequireDefault(require("./search"));
var _session = _interopRequireDefault(require("./session"));
var _spatial = _interopRequireDefault(require("./spatial"));
var _task = _interopRequireDefault(require("./task"));
var _tableInterface = _interopRequireDefault(require("./tableInterface"));
var _transform = _interopRequireDefault(require("./transform"));
var _upload = _interopRequireDefault(require("./upload"));
var _user = _interopRequireDefault(require("./user"));
var _widget = _interopRequireDefault(require("./widget"));
var _visual = _interopRequireDefault(require("./visual"));
var _workspace = _interopRequireDefault(require("./workspace"));
var _trees = _interopRequireDefault(require("./trees"));
var _chat = _interopRequireDefault(require("./chat"));
var _backups = _interopRequireDefault(require("./backups"));
var _domain = _interopRequireDefault(require("./domain"));
var _tadaai = _interopRequireDefault(require("./tadaai"));
var _icon = _interopRequireDefault(require("./icon"));
var _message = _interopRequireDefault(require("./message"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = (generator, baseUrls) => ({
  announcementService: (0, _announcement.default)(generator, baseUrls),
  applicationService: (0, _application.default)(generator, baseUrls),
  architectureService: (0, _architecture.default)(generator, baseUrls),
  authService: (0, _auth.default)(generator, baseUrls),
  commentService: (0, _comment.default)(generator, baseUrls),
  dataService: (0, _data.default)(generator, baseUrls),
  databaseService: (0, _database.default)(generator, baseUrls),
  datasourceService: (0, _datasource.default)(generator, baseUrls),
  definitionService: (0, _definition.default)(generator, baseUrls),
  deploymentService: (0, _deployment.default)(generator, baseUrls),
  filterService: (0, _filter.default)(generator, baseUrls),
  formsService: (0, _forms.default)(generator, baseUrls),
  insightService: (0, _insight.default)(generator, baseUrls),
  jobService: (0, _job.default)(generator, baseUrls),
  logService: (0, _log.default)(generator, baseUrls),
  mlModelService: (0, _mlModel.default)(generator, baseUrls),
  modelService: (0, _model.default)(generator, baseUrls),
  moduleService: (0, _module2.default)(generator, baseUrls),
  notificationService: (0, _notification.default)(generator, baseUrls),
  platformService: (0, _platform.default)(generator, baseUrls),
  projectService: (0, _project.default)(generator, baseUrls),
  publishLogService: (0, _publishLog.default)(generator, baseUrls),
  reportService: (0, _report.default)(generator, baseUrls),
  scenarioService: (0, _scenario.default)(generator, baseUrls),
  scheduleService: (0, _schedule.default)(generator, baseUrls),
  searchService: (0, _search.default)(generator, baseUrls),
  sessionService: (0, _session.default)(generator, baseUrls),
  spatialService: (0, _spatial.default)(generator, baseUrls),
  taskService: (0, _task.default)(generator, baseUrls),
  tableInterfaceService: (0, _tableInterface.default)(generator, baseUrls),
  transformService: (0, _transform.default)(generator, baseUrls),
  treeService: (0, _trees.default)(generator, baseUrls),
  uploadService: (0, _upload.default)(generator, baseUrls),
  userService: (0, _user.default)(generator, baseUrls),
  widgetService: (0, _widget.default)(generator, baseUrls),
  visualService: (0, _visual.default)(generator, baseUrls),
  workspaceService: (0, _workspace.default)(generator, baseUrls),
  chatService: (0, _chat.default)(generator, baseUrls),
  backupService: (0, _backups.default)(generator, baseUrls),
  domainService: (0, _domain.default)(generator, baseUrls),
  aiService: (0, _tadaai.default)(generator, baseUrls),
  iconService: (0, _icon.default)(generator, baseUrls),
  messageService: (0, _message.default)(generator, baseUrls)
});
exports.default = _default;