import { Link as RouterLink } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Link, AppBar, Toolbar, IconButton, Tooltip, useTheme as useMUITheme } from '@mui/material'
import { DarkMode, LightMode } from '@mui/icons-material'
import { useTheme } from 'src/contexts/ThemeContext'
import { getAppLogo } from 'src/lib/appLogoUtils'
import useMediaQuery from '@mui/material/useMediaQuery'
import SwitchAnnouncementModeButton from '../SwitchAnnouncementModeButton'
import AuthButton from './AuthButton'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'
import { useAppMode } from 'src/contexts/AppModeContext'
import { PLATFORM_ROUTE_PATHS, SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'
import { usePlatformAuth } from 'src/contexts/PlatformAuthContext'
import PlatformUserMenu from './PlatformUserMenu'

const useStyles = makeStyles(({ custom, palette }) => ({
    toolbar: {
        width: '100%',
        maxWidth: custom.announcementsApp?.maxWidth,
        margin: 'auto',
        paddingLeft: custom.announcementsApp?.paddingBig,
        paddingRight: custom.announcementsApp?.paddingBig,
    },
    icon: {
        color: palette.text.primary,
    },
}))

const PlatformTopNav = () => {
    const { appMode, isPlatformEnv } = useAppMode()
    const { activeAccount } = usePlatformAuth()
    const classes = useStyles()
    const { themeMode, toggleTheme } = useTheme()
    const appLogo = getAppLogo()
    const muiTheme = useMUITheme()
    const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'))
    const logoSrc = isSmallScreen
        ? themeMode === 'light'
            ? appLogo.smallDark
            : appLogo.smallLight
        : themeMode === 'light'
        ? appLogo.dark
        : appLogo.light

    const ThemeModeIcon = themeMode === 'light' ? DarkMode : LightMode

    return (
        <AppBar position="sticky">
            <Toolbar className={classes.toolbar} disableGutters>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, margin: '5px' }}>
                    <Link
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        component={RouterLink}
                        to={
                            appMode === AnnouncementAppMode.Platform
                                ? PLATFORM_ROUTE_PATHS.HOME
                                : SOLUTION_ROUTE_PATHS.SELECT
                        }
                    >
                        <Box component="img" src={logoSrc} sx={{ height: { xs: '2rem', md: '2.5rem' } }} />
                    </Link>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>
                <Box sx={{ flexGrow: 0 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {!isPlatformEnv && <SwitchAnnouncementModeButton mode={appMode} />}
                        <Tooltip title={themeMode === 'light' ? 'Dark mode' : 'Light mode'}>
                            <IconButton className={classes.icon} onClick={toggleTheme}>
                                <ThemeModeIcon />
                            </IconButton>
                        </Tooltip>

                        {isPlatformEnv && (
                            <>{activeAccount ? <PlatformUserMenu sx={{ ml: 1 }} /> : <AuthButton sx={{ ml: 1 }} />}</>
                        )}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default PlatformTopNav
