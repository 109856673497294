"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  perform: ({
    query,
    ...rest
  }) => ({
    baseURL: integration,
    method: 'get',
    url: `search`,
    config: {
      params: {
        q: query,
        ...rest
      }
    }
  }),
  getRangeFilterBoundaries: (name, modelName) => ({
    baseURL: integration,
    method: 'get',
    url: `rangefilters`,
    config: {
      params: {
        name,
        modelName
      }
    }
  })
});
exports.default = _default;