"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getPublishLogs: latest => ({
    baseURL: orgWeb,
    method: 'get',
    url: `publishlogs`,
    data: {},
    config: {
      params: {
        latest
      }
    }
  })
});
exports.default = _default;