import React from 'react'
import { CircularProgress, Typography } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

const styles = {
    content: {
        textAlign: 'center',
        position: 'relative',
        top: '40vh',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    title: {
        marginBottom: '.75rem',
    },
    loader: {
        margin: 'auto',
    },
}

const Loading = props => {
    const { message, title, classes } = props
    return (
        <div className={classes.content}>
            <Typography variant="h4" className={classes.title}>
                {title || 'Hold Tight!'}
            </Typography>
            <CircularProgress className={classes.loader} color="primary" />
            <Typography variant="subtitle1">{message}</Typography>
        </div>
    )
}

export default withStyles(styles, { withTheme: true })(Loading)
