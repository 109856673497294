"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.widgetTagSuggest = exports.mentionListSuggest = void 0;
var _react = require("@tiptap/react");
var _tippy = _interopRequireDefault(require("tippy.js"));
var _MentionList = _interopRequireDefault(require("./MentionList"));
var _WidgetTagList = _interopRequireDefault(require("./WidgetTagList"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mentionListSuggest = exports.mentionListSuggest = {
  render: () => {
    let component;
    let popup;
    return {
      onStart: props => {
        component = new _react.ReactRenderer(_MentionList.default, {
          props,
          editor: props.editor
        });
        if (!props.clientRect) {
          return;
        }
        popup = (0, _tippy.default)('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start'
        });
      },
      onUpdate(props) {
        component.updateProps(props);
        if (!props.clientRect) {
          return;
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect
        });
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          return true;
        }
        return component.ref?.onKeyDown(props);
      },
      onExit() {
        popup[0].destroy();
        component.destroy();
      }
    };
  }
};
const widgetTagSuggest = exports.widgetTagSuggest = {
  render: () => {
    let component;
    let popup;
    return {
      onStart: props => {
        component = new _react.ReactRenderer(_WidgetTagList.default, {
          props,
          editor: props.editor
        });
        if (!props.clientRect) {
          return;
        }
        popup = (0, _tippy.default)('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start'
        });
      },
      onUpdate(props) {
        component.updateProps(props);
        if (!props.clientRect) {
          return;
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect
        });
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          return true;
        }
        return component.ref?.onKeyDown(props);
      },
      onExit() {
        popup[0].destroy();
        component.destroy();
      }
    };
  }
};