import { IconButton, SxProps, Theme, Tooltip } from '@mui/material'
import { AdminPanelSettings } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { usePlatformAuth } from 'src/contexts/PlatformAuthContext'
import { SCOPES } from 'src/lib/platformAuthConfig'

const AuthButton = ({ sx }: { sx?: SxProps<Theme> }) => {
    const { authInstance } = usePlatformAuth()
    const { enqueueSnackbar } = useSnackbar()

    const handleLogin = () => {
        if (!authInstance) return
        authInstance
            .loginPopup({ scopes: SCOPES })
            .then(_response => {
                enqueueSnackbar('Login successful!', { variant: 'success' })
            })
            .catch(error => {
                console.warn({ error })
                enqueueSnackbar('Login failed. Please try again.', { variant: 'error' })
            })
    }

    return (
        <Tooltip title="Admin Login">
            <IconButton sx={sx} onClick={handleLogin}>
                <AdminPanelSettings />
            </IconButton>
        </Tooltip>
    )
}

export default AuthButton
