"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.muiTheme = exports.defaultTypography = void 0;
const muiTheme = () => ({
  palette: {
    primary: {
      main: '#672771'
    },
    grayscale: {
      lightest: '#f2f2f2',
      lighter: '#e0e0e0',
      light: '#bdbdbd',
      dark: '#828282',
      darker: '#4e4e4e',
      darkest: '#333333'
    },
    status: {
      success: '#65BD59',
      warning: '#FFCE07',
      error: '#E92155'
    },
    tada: {
      purple: '#672771',
      blue: '#3AB7BC',
      red: '#C01F41',
      beige: '#E4DDCF',
      darkGray: '#707071',
      lightGray: '#BABABA',
      teal: '#2CB0AA'
    }
  },
  typography: defaultTypography,
  border: {
    default: '1px solid #e0e0e0',
    radius: {
      round: 4
    }
  },
  font: {
    emphasis: {
      low: {
        color: 'rgba(0, 0, 0, 0.3)'
      },
      medium: {
        color: 'rgba(0, 0, 0, 0.6)'
      },
      high: {
        color: '#000000'
      }
    }
  },
  custom: {
    rotateLeft: {
      transition: '.25s ease',
      transform: 'rotate(-360deg)'
    },
    rotateRight: {
      transition: '.25s ease',
      transform: 'rotate(360deg)'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 240,
          overflowX: 'hidden'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          padding: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        primary: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 10px 1px rgba(0,0,0,0.2)'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          flexWrap: 'nowrap'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&$textColorInherit': {
            fontWeight: 'normal'
          },
          '&$selected': {
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 10px 1px rgba(0,0,0,0.2)'
        }
      }
    }
  }
});
exports.muiTheme = muiTheme;
const defaultTypography = exports.defaultTypography = {
  fontFamily: "'Nunito', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  body: {
    fontSize: '.875rem',
    lineHeight: '1.43',
    letterSpacing: '0.3px'
  }
};