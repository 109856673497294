import React, { useCallback, useContext, useState } from 'react'
import { Button, Modal, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { AnnouncementRender } from 'genesis-suite/components'
import { baseUrls, moduleService, platformService } from 'src/lib/services'
import { AnnouncementFormData, getAnnouncementFromFormData } from './Stepper'
import { SolutionAuthContext } from 'src/contexts/SolutionAuthContext'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'
import { getPlatformAnnouncementFromFormData, PlatformAnnouncementFormData } from './PlatformStepper'
import { useAppMode } from 'src/contexts/AppModeContext'

interface PreviewButtonProps {
    title: string
    description: string
    startDate: string
    mode?: AnnouncementAppMode
}

const PreviewButton: React.FC<PreviewButtonProps> = () => {
    const [open, setOpen] = useState(false)
    const { getValues } = useFormContext<AnnouncementFormData | PlatformAnnouncementFormData>()
    const { appMode } = useAppMode()
    const { user } = useContext(SolutionAuthContext)
    const allValues = getValues()
    const announcement =
        appMode === AnnouncementAppMode.Platform
            ? getPlatformAnnouncementFromFormData(allValues as PlatformAnnouncementFormData)
            : getAnnouncementFromFormData(allValues as AnnouncementFormData)

    const getSrcUrlFromAttachementToken = useCallback(
        (token: string): string => {
            if (appMode !== AnnouncementAppMode.Platform && !user) {
                console.warn('User not authenticated')
                return ''
            }
            return appMode === AnnouncementAppMode.Platform
                ? `${baseUrls.platform}/File/id/${token}`
                : `${baseUrls.integration}/files?fileToken=${token}&accessKey=${user?.accessKey || ''}`
        },
        [appMode, user]
    )

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    return (
        <>
            <Button sx={{ marginRight: 2 }} variant="contained" color="info" onClick={handleOpen}>
                Preview
            </Button>
            <Modal open={open} onClose={handleClose} closeAfterTransition>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                    onClick={handleClose}
                >
                    <Box
                        sx={{ minWidth: 300, maxWidth: 900, maxHeight: '90vh', overflow: 'auto' }}
                        onClick={e => e.stopPropagation()}
                    >
                        <AnnouncementRender
                            announcement={announcement}
                            getSrcUrlFromAttachmentToken={getSrcUrlFromAttachementToken}
                            getAttachment={
                                appMode === AnnouncementAppMode.Platform
                                    ? platformService.getPlatformAttachment
                                    : moduleService.getAttachment
                            }
                            userAccessKey={user?.accessKey || null}
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default PreviewButton
