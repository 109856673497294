"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  accuracy: (projectId, modelId, apiVersion) => ({
    baseURL: integration,
    method: 'get',
    url: `mlModels/accuracy`,
    config: {
      params: {
        projectId,
        modelId,
        versionNumber: apiVersion
      }
    }
  }),
  deleteProject: (projectId, apiVersion) => ({
    baseURL: integration,
    method: 'delete',
    url: `mlModels`,
    config: {
      params: {
        projectId,
        versionNumber: apiVersion
      }
    }
  }),
  explain: (projectId, modelId, apiVersion) => ({
    baseURL: integration,
    method: 'get',
    url: `mlModels/explain`,
    config: {
      params: {
        projectId,
        modelId,
        versionNumber: apiVersion
      }
    }
  }),
  modelImportance: (modelId, apiVersion) => ({
    baseURL: integration,
    method: 'get',
    url: `mlModels/importance`,
    config: {
      params: {
        modelId,
        versionNumber: apiVersion
      }
    }
  }),
  predict: (ProjectId, Data, apiVersion) => ({
    baseURL: integration,
    method: 'post',
    url: `mlModels/playground`,
    data: {
      ProjectId,
      Data
    },
    config: {
      params: {
        versionNumber: apiVersion
      }
    }
  }),
  predictMultiple: (ProjectId, numRecords, modelName, target, ignore_features, apiVersion) => ({
    baseURL: integration,
    method: 'post',
    url: `mlModels/playgroundmultiple`,
    data: {
      ProjectId,
      numRecords,
      modelName,
      target,
      ignore_features
    },
    config: {
      params: {
        versionNumber: apiVersion
      }
    }
  }),
  projectModels: (projectId, apiVersion) => ({
    baseURL: integration,
    method: 'get',
    url: `mlModels/models`,
    config: {
      params: {
        projectId,
        versionNumber: apiVersion
      }
    }
  }),
  projects: (modelName, apiVersion) => ({
    baseURL: integration,
    method: 'get',
    url: `mlModels/projects`,
    config: {
      params: {
        modelName,
        versionNumber: apiVersion
      }
    }
  }),
  status: (projectId, modelId, apiVersion) => ({
    baseURL: integration,
    method: 'get',
    url: `mlModels/status`,
    config: {
      params: {
        projectId,
        modelId,
        versionNumber: apiVersion
      }
    },
    then: body => body?.[0]
  }),
  save: (model, apiVersion) => ({
    baseURL: integration,
    method: 'post',
    url: `mlModels/save`,
    config: {
      params: {
        versionNumber: apiVersion
      }
    },
    data: model,
    then: body => ({
      ...body,
      _id: body._id.$oid
    })
  }),
  train: (projectId, max_runtime, algorithm, apiVersion) => ({
    baseURL: integration,
    method: 'post',
    url: `mlModels/train`,
    config: {
      params: {
        projectId,
        max_runtime,
        algorithm,
        versionNumber: apiVersion
      }
    }
  })
});
exports.default = _default;