"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getAll: (baseURL, accessKey) => ({
    baseURL,
    method: 'get',
    url: `projects`,
    config: {
      params: {
        accessKey
      }
    }
  }),
  getById: (baseURL, projectId) => ({
    baseURL,
    method: 'get',
    url: `projects/${projectId}`
  }),
  create: (projectData, accessKey) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `projects`,
    data: projectData,
    config: {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        isInitial: projectData.isInitial,
        accessKey
      }
    }
  }),
  delete: (projectId, accessKey) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `projects/${projectId}`,
    config: {
      params: {
        accessKey
      }
    }
  }),
  update: projectData => ({
    baseURL: orgWeb,
    method: 'put',
    url: `projects/${projectData.id}`,
    data: projectData
  })
});
exports.default = _default;