"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getSites: () => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sites`
  }),
  getDeployments: siteId => ({
    baseURL: orgWeb,
    method: 'get',
    url: `deployments`,
    config: {
      params: {
        siteId
      }
    }
  }),
  validateAccess: (userId, password, siteId, deploymentId) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `orgdeployments/authenticate`,
    data: {
      userId,
      password
    },
    config: {
      params: {
        siteId,
        deploymentId
      }
    }
  }),
  createDeployment: (request, deploymentName, url, accessKey) => ({
    baseURL: url || orgWeb,
    method: 'post',
    url: `orgdeployments/deploy`,
    data: request,
    config: {
      params: {
        deploymentName,
        accessKey
      }
    }
  })
});
exports.default = _default;