"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  getResponse: data => ({
    baseURL: integration,
    method: 'post',
    url: `Chat/V2`,
    data: data
  }),
  getResponseV31: data => ({
    baseURL: integration,
    method: 'post',
    url: `Chat/V31`,
    data: data
  })
});
exports.default = _default;