import React, { useState } from 'react'
import { Box, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import BackButton from 'src/components/BackButton'
import { AnnouncementAppMode, PlatformAnnouncement } from 'genesis-suite/types/announcementTypes'
import { Cloud } from 'src/types'
import PlatformStepper from 'src/components/stepper/PlatformStepper'
import { platformService } from 'src/lib/services'
import { PLATFORM_ROUTE_PATHS } from 'src/lib/routePath'

const useStyles = makeStyles(({ custom, palette }) => {
    const { maxWidth, paddingBig } = custom.announcementsApp || {}
    return {
        container: {
            height: '100%',
            backgroundColor: palette.background.default,
        },
        maxWidthAndPadding: {
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
        },
        content: {
            height: '100%',
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
            display: 'flex',
            flexDirection: 'column',
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

export interface FormData {
    id?: string
    scope: string
    cloudId: string
    applicationId: string | null
    portalId: string | null
    type: string
    title: string
    description: string
    attachments: File[]
    startDate: Date
    endDate: Date
    whitelistedUserIds: string | null
}

const CreateAnnouncement: React.FC<{ activeCloud?: Cloud | null }> = () => {
    const classes = useStyles()

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [isCreating, setIsCreating] = useState<boolean>(false)

    const handleDraft = async (newAnnouncement: PlatformAnnouncement) => {
        setIsCreating(true)
        try {
            await platformService.createAnnouncement(newAnnouncement)
            enqueueSnackbar('Draft created successfully!', { variant: 'success' })
            navigate(PLATFORM_ROUTE_PATHS.HOME)
        } catch (error) {
            console.error('Error creating draft:', error)
            enqueueSnackbar('Failed to create draft. Please try again.', { variant: 'error' })
        } finally {
            setIsCreating(false)
        }
    }

    const handleCreate = async (newAnnouncement: PlatformAnnouncement) => {
        setIsCreating(true)
        try {
            const response: PlatformAnnouncement = await platformService.createAnnouncement(newAnnouncement)
            enqueueSnackbar('Announcement created successfully!', { variant: 'success' })
            if (response.id) {
                navigate(PLATFORM_ROUTE_PATHS.ANNOUNCEMENT_DETAILS(response.id))
            }
        } catch (error) {
            console.error('Error creating announcement:', error)
            enqueueSnackbar('Failed to create announcement. Please try again.', { variant: 'error' })
        } finally {
            setIsCreating(false)
        }
    }
    return (
        <Box className={classes.container}>
            <Box className={classes.maxWidthAndPadding}>
                <Toolbar className={classes.toolbar}>
                    <BackButton mode={AnnouncementAppMode.Platform} />
                </Toolbar>
            </Box>
            <Box className={classes.content}>
                <Box>
                    <Typography variant="h4" sx={{ marginTop: 4 }}>
                        Create an Announcement
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                        Use this page to create a platform announcement. Please provide clear and detailed information.
                    </Typography>
                    <PlatformStepper
                        sx={{ marginTop: 8, marginBottom: 4 }}
                        onDraft={handleDraft}
                        onFinish={handleCreate}
                        isDisabled={isCreating}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CreateAnnouncement
