"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useHead = elements => {
  (0, _react.useEffect)(() => {
    const createdElements = [];
    elements.forEach(elementOptions => {
      if (elementOptions.tag === 'title') {
        document.title = elementOptions.text;
        return;
      }
      if (elementOptions.tag === 'script' && elementOptions.src) {
        const existingScript = document.querySelector(`script[src="${elementOptions.src}"]`);
        if (existingScript) {
          return; // If script already exists, don't add it again
        }
      }
      const element = document.createElement(elementOptions.tag);
      Object.entries(elementOptions).forEach(([key, value]) => {
        if (key !== 'tag' && value !== undefined) {
          if (key === 'cssText' || key === 'innerHTML') {
            element.textContent = value;
          } else {
            element.setAttribute(key, value);
          }
        }
      });
      document.head.appendChild(element);
      createdElements.push(element);
    });

    // Cleanup on unmount
    return () => {
      createdElements.forEach(element => {
        document.head.removeChild(element);
      });
    };
  }, [elements]);
};
var _default = exports.default = useHead;