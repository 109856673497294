import { List, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloudListItem from './CloudListItem'
import React from 'react'
import { Cloud } from 'src/contexts/CloudsContext'

const useStyles = makeStyles({
    list: {
        width: '100%',
        backgroundColor: 'background.paper',
    },
    listItem: {
        borderBottom: '1px solid #e0e0e0',
    },
    paper: {
        width: '100%',
        padding: '16px',
        boxSizing: 'border-box',
        marginTop: '24px',
    },
})

type ClouddListProps = {
    clouds: Cloud[]
    onSelect: (cloudName: string, applicationName: string) => void
}

const CloudList: React.FC<ClouddListProps> = ({ clouds, onSelect }) => {
    const classes = useStyles()
    return (
        <Paper elevation={3} className={classes.paper}>
            <List className={classes.list}>
                {clouds.map(cloud => (
                    <CloudListItem key={cloud.id} cloud={cloud} onSelect={onSelect} />
                ))}
            </List>
        </Paper>
    )
}

export default CloudList
