"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  get: (cloudId, modelPartId) => ({
    baseURL: integration,
    method: 'get',
    url: `insights`,
    config: {
      params: {
        cloudId,
        modelPartId
      }
    }
  }),
  getById: (cloudId, modelPartId, id) => ({
    baseURL: integration,
    method: 'get',
    url: `insights`,
    config: {
      params: {
        id,
        cloudId,
        modelPartId
      }
    }
  }),
  getPropertyBoundaries: (application, insightName, propertyNames) => ({
    baseURL: integration,
    method: 'get',
    url: `insights/boundaries`,
    config: {
      params: {
        modelName: application,
        name: insightName,
        propertyNames: propertyNames.join(',')
      }
    },
    then: data => propertyNames.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: {
          min: data[0][`${cur}_Min`],
          max: data[0][`${cur}_Max`]
        }
      };
    }, {})
  }),
  delete: (cloudId, modelPartId, id) => ({
    baseURL: integration,
    method: 'delete',
    url: `insights/delete`,
    config: {
      params: {
        cloudId,
        modelPartId,
        id
      }
    }
  }),
  create: (cloudId, insightData) => ({
    baseURL: integration,
    method: 'post',
    url: `insights/save`,
    data: insightData,
    config: {
      params: {
        cloudId
      }
    }
  }),
  update: (cloudId, insightData) => ({
    baseURL: integration,
    method: 'post',
    url: `insights/save`,
    data: insightData,
    config: {
      params: {
        cloudId
      }
    }
  }),
  preview: (insightData, config) => ({
    baseURL: integration,
    method: 'put',
    url: `insights/preview`,
    data: insightData,
    config: {
      params: {
        ...config
      }
    },
    then: processDataResponse({
      ...config
    })
  }),
  clone: (id, name, modelName) => ({
    baseURL: integration,
    method: 'post',
    url: `insights/${id}/Clone`,
    config: {
      params: {
        name,
        modelName
      }
    }
  })
});
exports.default = _default;
const processDataResponse = params => body => {
  const {
    pageSize,
    pageIndex
  } = params;
  let {
    Data: values,
    TotalRecords: totalRecords,
    Aggregates: aggregates,
    loadTime
  } = body;
  let nextPageIndex = null;
  // return the next pageIndex if more results are available
  if (values.length === pageSize) nextPageIndex = pageIndex + 1;
  // correct totalRecords. sometimes is larger than reality or nulls and blanks are included
  if (pageSize > values.length || totalRecords <= pageSize) totalRecords = pageSize * (pageIndex - 1) + values.length;
  return {
    values,
    nextPageIndex,
    totalRecords,
    aggregates,
    loadTime
  };
};