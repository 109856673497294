import { CssBaseline } from '@mui/material'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { PlatformAuthProvider } from 'src/contexts/PlatformAuthContext'
import React from 'react'
import { platformAuthConfig } from 'src/lib/platformAuthConfig'
import { PlatformEnvironmentsProvider } from 'src/contexts/PlatformEnvironmentsContext'

export const pca = new PublicClientApplication(platformAuthConfig)

const PlatformProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <MsalProvider instance={pca}>
            <PlatformAuthProvider>
                <PlatformEnvironmentsProvider>
                    <CssBaseline />
                    {children}
                </PlatformEnvironmentsProvider>
            </PlatformAuthProvider>
        </MsalProvider>
    )
}

export default PlatformProviders
