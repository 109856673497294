"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  spatial
}) => generator({
  getGrid: (nwBoundCenter, seBoundCenter, resolution) => ({
    baseURL: spatial,
    method: 'get',
    url: `GridCells/grid`,
    config: {
      params: {
        nwBoundCenter,
        seBoundCenter,
        resolution
      }
    }
  }),
  getAllGridCells: () => ({
    baseURL: spatial,
    method: 'get',
    url: `GridCells`
  })
});
exports.default = _default;