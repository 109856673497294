"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = hexToRgbA;
function hexToRgbA(hex, alpha = 1) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    const color = [c >> 16 & 255, c >> 8 & 255, c & 255].join(',');
    return `rgba(${color}, ${alpha})`;
  }
  throw new Error('Bad Hex');
}