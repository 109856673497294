"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("../../utils");
var _default = (generator, {
  integration
}) => generator({
  getFilterGroups: (modelName, widgetId, pageNumber = 1, pageSize = 200) => ({
    baseURL: integration,
    method: 'get',
    url: `widgets/FilterGroups`,
    config: {
      params: {
        modelName,
        widgetId,
        pageNumber,
        pageSize
      }
    }
  }),
  saveFilterGroup: (modelName, data) => ({
    baseURL: integration,
    method: 'post',
    url: `widgets/SaveFilterGroup`,
    data,
    config: {
      params: {
        modelName
      }
    }
  }),
  deleteFilterGroup: (modelName, widgetId, filterGroupId) => ({
    baseURL: integration,
    method: 'delete',
    url: `widgets/DeleteFilterGroup`,
    config: {
      params: {
        modelName,
        widgetId,
        filterGroupId
      }
    }
  }),
  getReportFilterGroups: id => ({
    baseURL: integration,
    method: 'get',
    url: `reportFilterGroups`,
    config: {
      params: {
        reportId: id
      }
    }
  }),
  getNetworkFilters: (modelName, pageNo = 1, pageSize = 200) => ({
    baseURL: integration,
    method: 'get',
    url: `NetworkFilters`,
    config: {
      params: {
        modelName,
        pageNo,
        pageSize
      }
    },
    then: data => (0, _utils.toCamel)(data)
  }),
  getAppNetworkFilters: (modelName, visorId, pageNo = 1, pageSize = 200) => ({
    baseURL: integration,
    method: 'get',
    url: `NetworkFilters/visorFilters`,
    config: {
      params: {
        modelName,
        visorId,
        pageNo,
        pageSize
      }
    },
    then: data => (0, _utils.toCamel)(data)
  }),
  getPerspectiveNetworkFilters: (modelName, perspectiveId, pageNo = 1, pageSize = 200) => ({
    baseURL: integration,
    method: 'get',
    url: `NetworkFilters/perspectiveFilters`,
    config: {
      params: {
        modelName,
        perspectiveId,
        pageNo,
        pageSize
      }
    },
    then: data => (0, _utils.toCamel)(data)
  }),
  getMinMaxDate: (name, property, ae) => ({
    baseURL: integration,
    method: 'get',
    url: `${name}/${property}`,
    config: {
      params: {
        ae
      }
    }
  })
});
exports.default = _default;