"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  getIcons: () => ({
    baseURL: integration,
    method: 'get',
    url: `icons`
  }),
  uploadIcon: (data, categoryName) => ({
    baseURL: integration,
    method: 'post',
    url: `icons`,
    data,
    config: {
      params: {
        categoryName
      }
    }
  })
});
exports.default = _default;