"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getJobId: jobType => ({
    baseURL: orgWeb,
    method: 'post',
    url: `jobs`,
    data: {},
    config: {
      params: {
        jobType
      }
    }
  })
});
exports.default = _default;