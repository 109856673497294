"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  getBackupSchedules: params => ({
    baseURL: integration,
    method: 'get',
    url: `backups/all`,
    config: {
      params: params
    }
  }),
  getBackupSchedulesById: (params, data) => ({
    baseURL: integration,
    method: 'post',
    url: `backups/history`,
    data,
    config: {
      params: params
    }
  }),
  InitializeBackupJob: (appId, cloudName, id) => ({
    baseURL: integration,
    method: 'put',
    url: `backups/InitializeBackupJob`,
    config: {
      params: {
        appId,
        cloudName,
        id
      }
    }
  })
});
exports.default = _default;