import { useContext } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import ZendeskController from 'genesis-suite/components/ZendeskController'
import { Box, Link, AppBar, Toolbar, IconButton, Tooltip, useTheme as useMUITheme } from '@mui/material'
import { SolutionAuthContext } from '../../contexts/SolutionAuthContext'
import CloudIcon from '@mui/icons-material/Cloud'
import { DarkMode, LightMode } from '@mui/icons-material'
import { useTheme } from 'src/contexts/ThemeContext'
import { getAppLogo } from 'src/lib/appLogoUtils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CloudsContext } from 'src/contexts/CloudsContext'
import { UserProfile } from '../nav'
import { PLATFORM_ROUTE_PATHS, SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'
import SwitchAnnouncementModeButton from '../SwitchAnnouncementModeButton'
import { useAppMode } from 'src/contexts/AppModeContext'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'

const useStyles = makeStyles(({ custom, palette }) => ({
    toolbar: {
        width: '100%',
        maxWidth: custom.announcementsApp?.maxWidth,
        margin: 'auto',
        paddingLeft: custom.announcementsApp?.paddingBig,
        paddingRight: custom.announcementsApp?.paddingBig,
    },
    icon: {
        color: palette.text.primary,
    },
}))

export default function SolutionTopNav() {
    const { appMode, isPlatformEnv } = useAppMode()
    const { user } = useContext(SolutionAuthContext)
    const classes = useStyles()
    const location = useLocation()
    const navigate = useNavigate()
    const { themeMode, toggleTheme } = useTheme()
    const appLogo = getAppLogo()
    const muiTheme = useMUITheme()
    const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'))
    const { clouds } = useContext(CloudsContext)

    const logoSrc = isSmallScreen
        ? themeMode === 'light'
            ? appLogo.smallDark
            : appLogo.smallLight
        : themeMode === 'light'
        ? appLogo.dark
        : appLogo.light

    const onSelectApp = () => {
        navigate(SOLUTION_ROUTE_PATHS.SELECT, { state: { preventAutoRedirect: true } })
    }
    const ThemeModeIcon = themeMode === 'light' ? DarkMode : LightMode

    return (
        <AppBar position="sticky">
            <Toolbar className={classes.toolbar} disableGutters>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, margin: '5px' }}>
                    <Link
                        state={true}
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        component={RouterLink}
                        to={
                            appMode === AnnouncementAppMode.Solution
                                ? SOLUTION_ROUTE_PATHS.SELECT
                                : PLATFORM_ROUTE_PATHS.HOME
                        }
                    >
                        <Box component="img" src={logoSrc} sx={{ height: { xs: '2rem', md: '2.5rem' } }} />
                    </Link>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>
                <Box sx={{ flexGrow: 0, margin: '5px' }}>
                    {user && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {!isPlatformEnv && <SwitchAnnouncementModeButton mode={appMode} />}
                            {location.pathname !== SOLUTION_ROUTE_PATHS.SELECT && clouds && clouds.length > 1 && (
                                <Tooltip title="Change Cloud">
                                    <IconButton className={classes.icon} onClick={onSelectApp}>
                                        <CloudIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title={themeMode === 'light' ? 'Dark mode' : 'Light mode'}>
                                <IconButton sx={{ marginRight: 1 }} className={classes.icon} onClick={toggleTheme}>
                                    <ThemeModeIcon />
                                </IconButton>
                            </Tooltip>
                            <ZendeskController userAlias={user && user.alias} />
                            <UserProfile />
                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    )
}
