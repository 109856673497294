"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = require("../../utils/helpers");
var _default = (generator, {
  integration,
  orgWeb
}) => generator({
  saveResourceValues: (appName, name, data, mergeTableName, formId, perspectiveId, widgetId, visorId, modelServiceId) => {
    return {
      baseURL: integration,
      method: 'post',
      url: `${appName}/${name}/data`,
      data,
      config: {
        params: {
          formId,
          perspectiveId,
          widgetId,
          visorId,
          modelServiceId,
          ...(mergeTableName && {
            mergeTableName
          })
        }
      }
    };
  },
  verifyBlockchain: (appName, name, data, formId) => {
    return {
      baseURL: integration,
      method: 'post',
      url: `${appName}/${name}/verify`,
      data,
      config: {
        params: {
          formId
        }
      }
    };
  },
  generateCert: (appName, name, data, formId) => {
    return {
      baseURL: integration,
      method: 'post',
      url: `${appName}/${name}/verify`,
      data,
      config: {
        params: {
          formId,
          generateCertificatePdf: true,
          downloadContent: false
        },
        responseType: 'blob'
      }
    };
  },
  getResourceValues: (application, name, searchProps, backupId) => {
    if (searchProps?.contextFilters?.length > 0) {
      const {
        controlField,
        sort,
        searchText,
        pageSize,
        pageIndex,
        filters = '',
        fieldNames,
        keepNulls,
        contextFilters,
        resourceType
      } = searchProps;
      const data = {
        PageNumber: pageIndex,
        PageSize: pageSize,
        ContextFilters: contextFilters,
        FieldNames: fieldNames?.split(','),
        filterExpression: getFilterExpression(filters, controlField, searchText, keepNulls)
      };
      if (sort) {
        data['sortOrders'] = {
          [controlField]: sort == 'd' ? 'Descending' : 'Ascending'
        };
      }
      return {
        baseURL: integration,
        method: 'post',
        url: `gsmdata`,
        data: data,
        config: {
          params: {
            modelname: application,
            resourceName: name,
            resourceType
          }
        },
        then: processDataResponse({
          ps: pageSize,
          pi: pageIndex
        })
      };
    } else {
      const dataParams = getDataParams(searchProps);
      const params = (0, _helpers.configParams)(dataParams, backupId);
      return {
        baseURL: integration,
        method: 'get',
        url: `${application}/${name}/data`,
        config: {
          params
        },
        then: processDataResponse(params)
      };
    }
  },
  getResourceCounts: application => ({
    baseURL: integration,
    method: 'get',
    url: `gsmdata/counts`,
    config: {
      params: {
        modelname: application
      }
    }
  }),
  getModelNodes: appName => ({
    baseURL: integration,
    method: 'get',
    url: `/Services/${appName}/nodes`
  }),
  getModelLinks: appName => ({
    baseURL: integration,
    method: 'get',
    url: `/Services/${appName}/links`
  }),
  getModelData: (appName, name) => ({
    baseURL: integration,
    method: 'get',
    url: `/Services/${appName}/${name}`
  }),
  filterResourceValues: (modelName, name, data, visorFormId) => ({
    baseURL: integration,
    method: 'post',
    url: visorFormId ? `dataforms/listvalues` : `nodes/filters`,
    config: {
      params: {
        modelName,
        name,
        formId: visorFormId
      }
    },
    data
  }),
  getResourceValuesById: (cloudId, type, id, searchProps, backupId, appId) => {
    const params = (0, _helpers.configDataParams)({
      ...getDataParams(searchProps),
      cloudId: cloudId
    }, backupId, appId);
    return {
      baseURL: integration,
      method: 'get',
      url: `${type}/${id}/data`,
      config: {
        params
      },
      then: processDataResponse(params)
    };
  },
  deleteResourceValues: (application, resourceName, body) => {
    return {
      baseURL: integration,
      method: 'delete',
      url: `${application}/${resourceName}/data`,
      data: body
    };
  },
  getServers: () => ({
    baseURL: orgWeb,
    method: 'get',
    url: `servers`
  }),
  initPublishCloud: (data, sessionKey) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `projects/${data.projectId}/publish`,
    data,
    config: {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        accessKey: sessionKey
      }
    }
  }),
  getAllClouds: () => ({
    baseURL: integration,
    method: 'get',
    url: `clouds`
  }),
  getClouds: (baseURL, accessKey) => ({
    baseURL,
    method: 'get',
    url: `clouds`,
    config: {
      params: {
        accessKey
      }
    }
  }),
  deleteCloud: (baseURL, id) => ({
    baseURL,
    method: 'delete',
    url: `clouds`,
    config: {
      params: {
        id
      }
    }
  }),
  getModels: (baseURL, cloudId, accessKey) => ({
    baseURL,
    method: 'get',
    url: `models`,
    config: {
      params: {
        accessKey,
        cloudId
      }
    }
  }),
  getModelParts: (baseURL, cloudId, modelId, accessKey) => ({
    baseURL,
    method: 'get',
    url: `modelparts`,
    config: {
      params: {
        accessKey,
        cloudId,
        modelId
      }
    }
  }),
  getModelPart: (baseURL, cloudId, modelId, ModelPartId) => ({
    baseURL,
    method: 'get',
    url: `modelparts/${ModelPartId}`,
    config: {
      params: {
        cloudId,
        modelId
      }
    }
  }),
  profileResource: (application, name, backupId) => {
    return {
      baseURL: integration,
      method: 'get',
      url: `${application}/${name}/data`,
      config: {
        params: (0, _helpers.configParams)({
          pi: 1,
          ps: 500,
          dodebug: true
        }, backupId)
      }
    };
  },
  getLayers: (application, backupId) => {
    return {
      baseURL: integration,
      method: 'get',
      url: `layers`,
      config: {
        params: (0, _helpers.configParams)({
          modelName: application
        }, backupId)
      },
      then: body => body?.sort((a, b) => a?.Name?.localeCompare(b?.Name))
    };
  }
});
exports.default = _default;
function getDataParams(searchProps) {
  let ps;
  let pi;
  let params = {};
  if (searchProps) {
    const {
      controlField,
      sort,
      searchText,
      pageSize,
      pageIndex,
      filters = '',
      fieldNames,
      keepNulls,
      aggregateExpressions,
      networkFilters
    } = searchProps;
    if (pageSize) ps = pageSize;
    if (pageIndex) pi = pageIndex;
    params = {
      ps,
      pi
    };
    if (fieldNames) params.fieldNames = fieldNames;
    if (controlField) {
      if (sort) params.sort = `${controlField}~${sort}`;
    }
    const cf = getFilterExpression(filters, controlField, searchText, keepNulls);
    if (cf) params.cf = cf;
    if (aggregateExpressions) params.ae = aggregateExpressions;
    params.filters = networkFilters;
  }
  return params;
}
function getFilterExpression(filters, controlField, searchText, keepNulls) {
  let cf;
  const cleanedFilters = filters?.replace(/[^\x20-\x7E]/g, '');
  if (controlField) {
    // filter controlField to start with filter
    if (searchText) cf = `${controlField}<c>${searchText}`;
    // remove nulls only when keepNulls is false
    else if (keepNulls == false) cf = `${controlField} IS NOT null`;
  }
  if (cleanedFilters && cf) cf = `${cleanedFilters} AND ${cf}`;else if (cleanedFilters) cf = cleanedFilters;
  return cf;
}
const processDataResponse = params => body => {
  const {
    ps,
    pi
  } = params;
  let {
    Data: values,
    TotalRecords: totalRecords,
    RecordCount,
    Aggregates: aggregates,
    loadTime
  } = body;
  if (totalRecords === undefined) totalRecords = RecordCount;
  let nextPageIndex = null;
  // return the next pageIndex if more results are available
  if (values.length === ps) nextPageIndex = pi + 1;
  // correct totalRecords. sometimes is larger than reality or nulls and blanks are included
  if (ps > values.length || totalRecords <= ps) totalRecords = ps * (pi - 1) + values.length;
  return {
    values,
    nextPageIndex,
    totalRecords,
    aggregates,
    loadTime
  };
};