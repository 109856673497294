"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = addQueryParams;
/**
 * Append query params to end of url
 * @param path url path
 * @param params object of query params
 */
function addQueryParams(path, params) {
  let delimiter = '?';
  const paramString = Object.keys(params).reduce((acc, cur, index) => {
    if (index === 1) delimiter = '&';
    return `${acc}${delimiter}${cur}=${params[cur]}`;
  }, '');
  return path + paramString;
}