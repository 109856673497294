import { Box, Toolbar, Typography } from '@mui/material'
import BackButton from 'src/components/BackButton'
import makeStyles from '@mui/styles/makeStyles'
import PlatformStepper from 'src/components/stepper/PlatformStepper'
import { AnnouncementAppMode, PlatformAnnouncement } from 'genesis-suite/types/announcementTypes'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PLATFORM_ROUTE_PATHS } from 'src/lib/routePath'
import { useSnackbar } from 'notistack'
import { platformService } from 'src/lib/services'

const useStyles = makeStyles(({ custom, palette }) => {
    const { maxWidth, paddingBig } = custom.announcementsApp || {}
    return {
        container: {
            height: '100%',
            backgroundColor: palette.background.default,
        },
        maxWidthAndPadding: {
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
        },
        content: {
            height: '100%',
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
            display: 'flex',
            flexDirection: 'column',
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

const UpdateAnnouncement = () => {
    const classes = useStyles()
    const location = useLocation()
    const navigate = useNavigate()
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [announcement, setAnnouncement] = useState<PlatformAnnouncement | null>(null)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const state = location.state as { announcement?: PlatformAnnouncement }
        if (!state?.announcement) {
            navigate(PLATFORM_ROUTE_PATHS.HOME)
        } else {
            setAnnouncement(state.announcement)
        }
    }, [location.state, navigate])

    const hasAnnouncementStarted = (): boolean => {
        if (!announcement) return false
        const startDate = new Date(announcement.startDate)
        const now = new Date()
        return startDate <= now
    }

    const handleDraft = async (updatedAnnouncement: PlatformAnnouncement) => {
        setIsUpdating(true)
        try {
            const response = await platformService.updateAnnouncement(updatedAnnouncement)
            if (response) {
                navigate(PLATFORM_ROUTE_PATHS.HOME)
                enqueueSnackbar('Draft updated successfully!', { variant: 'success' })
            }
        } catch (error) {
            console.error('Error updating draft:', error)
            enqueueSnackbar('Failed to update draft. Please try again.', { variant: 'error' })
        } finally {
            setIsUpdating(false)
        }
    }

    const handleUpdate = async (updatedAnnouncement: PlatformAnnouncement) => {
        setIsUpdating(true)
        try {
            const response = await platformService.updateAnnouncement(updatedAnnouncement)
            if (response) {
                navigate(PLATFORM_ROUTE_PATHS.ANNOUNCEMENT_DETAILS(updatedAnnouncement.id || ''))

                if (announcement?.isDraft) {
                    enqueueSnackbar('Announcement created successfully!', { variant: 'success' })
                } else {
                    enqueueSnackbar('Announcement updated successfully!', { variant: 'success' })
                }
            }
        } catch (error) {
            console.error('Error updating announcement:', error)
            if (announcement?.isDraft) {
                enqueueSnackbar('Failed to create announcement. Please try again.', { variant: 'error' })
            } else {
                enqueueSnackbar('Failed to update announcement. Please try again.', { variant: 'error' })
            }
        } finally {
            setIsUpdating(false)
        }
    }

    if (!announcement) {
        return (
            <Box className={classes.container}>
                <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
                    Announcement not found.
                </Typography>
            </Box>
        )
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.maxWidthAndPadding}>
                <Toolbar className={classes.toolbar}>
                    <BackButton mode={AnnouncementAppMode.Platform} />
                </Toolbar>
            </Box>
            <Box className={classes.content}>
                <Box>
                    <Typography variant="h4" sx={{ marginTop: 4 }}>
                        {announcement?.isDraft ? 'Edit Draft' : 'Update Announcement'}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                        {announcement && announcement.isDraft
                            ? 'Make changes to your draft before publishing.'
                            : hasAnnouncementStarted()
                            ? 'This announcement has already started. You can only update the scope and availability settings.'
                            : 'Use this page to update your announcement details.'}
                    </Typography>
                    <PlatformStepper
                        initialData={announcement}
                        sx={{ marginTop: 8, marginBottom: 4 }}
                        onDraft={handleDraft}
                        onFinish={handleUpdate}
                        isDisabled={isUpdating}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export default UpdateAnnouncement
