"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("../../utils");
var _default = (generator, {
  integration
}) => generator({
  getScenarios: modelName => ({
    baseURL: integration,
    method: 'get',
    url: `scenarios/get`,
    config: {
      params: {
        modelName
      }
    },
    then: res => {
      return (0, _utils.toCamel)(res);
    }
  }),
  saveScenario: (modelName, scenario) => ({
    baseURL: integration,
    method: 'post',
    url: 'scenarios',
    config: {
      params: {
        modelName
      }
    },
    data: scenario
  }),
  deleteScenario: (modelName, scenarioId) => ({
    baseURL: integration,
    method: 'delete',
    url: 'scenarios/delete',
    config: {
      params: {
        modelName,
        id: scenarioId
      }
    }
  }),
  requestToPublishScenario: scenario => ({
    baseURL: integration,
    method: 'post',
    url: 'scenarios/PublishRequest',
    data: scenario
  }),
  getUserScenarios: (modelName, requestType) => ({
    baseURL: integration,
    method: 'get',
    url: `scenarios/getScenarioRequests`,
    config: {
      params: {
        modelName,
        requestType
      }
    },
    then: res => {
      return (0, _utils.toCamel)(res);
    }
  }),
  updatePublishScenario: scenario => ({
    baseURL: integration,
    method: 'put',
    url: 'scenarios/UpdatePublishRequest',
    config: {
      params: scenario
    }
  })
});
exports.default = _default;