import React from 'react'
import {
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Box,
    CircularProgress,
    Typography,
    SelectChangeEvent,
    Theme,
    SxProps,
} from '@mui/material'
import { PlatformEnvironment } from 'genesis-suite/types/announcementTypes'

interface EnvironmentMultiSelectProps {
    value: string[]
    onChange: (value: string[]) => void
    environments: PlatformEnvironment[]
    isLoading: boolean
    error?: boolean
    helperText?: string
    placeholder?: string
    fullWidth?: boolean
    sx?: SxProps<Theme>
    valueKey?: 'id' | 'name'
}

const EnvironmentMultiSelect: React.FC<EnvironmentMultiSelectProps> = ({
    value,
    onChange,
    environments,
    isLoading,
    error = false,
    helperText,
    placeholder = 'Select Environment(s)',
    fullWidth = true,
    sx = {},
    valueKey = 'id',
    ...formControlProps
}) => {
    const allEnvironmentsSelected = environments.length > 0 && value.length === environments.length
    const isIndeterminate = value.length > 0 && value.length < environments.length

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const selectedValue = event.target.value
        if (selectedValue.includes('select-all')) {
            if (allEnvironmentsSelected) {
                onChange([])
            } else {
                onChange(environments.map(env => env[valueKey]))
            }
        } else {
            if (Array.isArray(selectedValue)) {
                onChange(selectedValue)
            } else {
                onChange([selectedValue])
            }
        }
    }

    return (
        <FormControl margin="normal" fullWidth={fullWidth} error={error} sx={{ ...sx }} {...formControlProps}>
            <Select
                multiple
                displayEmpty
                input={<OutlinedInput />}
                value={value}
                onChange={handleChange}
                renderValue={selected => {
                    if (selected.length === 0) {
                        return placeholder
                    }
                    const selectedEnvs = environments.filter(env => selected.includes(env[valueKey]))
                    return selectedEnvs.map(env => env.name).join(', ')
                }}
                inputProps={{ 'aria-label': 'Select Environments' }}
            >
                {isLoading ? (
                    <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                            <CircularProgress size={20} />
                            <Typography variant="body2" sx={{ marginLeft: 2 }}>
                                Loading environments...
                            </Typography>
                        </Box>
                    </MenuItem>
                ) : environments.length > 0 ? (
                    [
                        <MenuItem key="select-all" value="select-all" style={{ fontWeight: 'bold' }}>
                            <Checkbox checked={allEnvironmentsSelected} indeterminate={isIndeterminate} />
                            <ListItemText primary="Select All" />
                        </MenuItem>,
                        environments.map(env => (
                            <MenuItem key={env.id} value={env[valueKey]}>
                                <Checkbox checked={value.includes(env[valueKey])} />
                                <ListItemText primary={env.name} />
                            </MenuItem>
                        )),
                    ]
                ) : (
                    <MenuItem disabled>
                        <Typography variant="body2">No environments available.</Typography>
                    </MenuItem>
                )}
            </Select>
            {error && helperText && (
                <Typography variant="body2" color="error">
                    {helperText}
                </Typography>
            )}
        </FormControl>
    )
}

export default EnvironmentMultiSelect
