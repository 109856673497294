"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _visualTypes = require("../../types/visualTypes");
var _fileSaver = _interopRequireDefault(require("file-saver"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = (generator, {
  integration
}) => generator({
  getAccount: () => ({
    baseURL: integration,
    method: 'get',
    url: `security/me`
  }),
  updateAccount: data => ({
    baseURL: integration,
    method: 'post',
    url: `security/me`,
    data
  }),
  deleteUser: (modelname, userId) => ({
    baseURL: integration,
    method: 'delete',
    url: `security/users`,
    config: {
      params: {
        modelname,
        userId
      }
    }
  }),
  getUserById: userId => ({
    baseURL: integration,
    method: 'get',
    url: `security/users`,
    config: {
      params: {
        userId
      }
    }
  }),
  getUsers: modelName => ({
    baseURL: integration,
    method: 'get',
    url: `security/users`,
    config: {
      params: {
        modelName
      }
    }
  }),
  getUsersV2: data => ({
    baseURL: integration,
    method: 'post',
    url: `users/ModelUsers`,
    config: {
      params: {
        version: 'v2'
      }
    },
    data
  }),
  getSuperUsers: () => ({
    baseURL: integration,
    method: 'get',
    url: `security/users`,
    config: {
      params: {
        su: 1
      }
    }
  }),
  getSuperUsersV2: (pi, ps, search) => ({
    baseURL: integration,
    method: 'get',
    url: `security/users`,
    config: {
      params: {
        su: 1,
        version: 'v2',
        pi,
        ps,
        search
      }
    }
  }),
  saveUser: data => ({
    baseURL: integration,
    method: 'post',
    url: `security/users`,
    data
  }),
  saveUsers: data => ({
    baseURL: integration,
    method: 'post',
    url: `security/saveusers`,
    data
  }),
  search: searchTerm => ({
    baseURL: integration,
    method: 'get',
    url: `security/usersearch`,
    config: {
      params: {
        searchTerm
      }
    }
  }),
  export: (modelName, searchTerm, exportType, userId) => ({
    baseURL: integration,
    method: 'post',
    url: `security/export`,
    config: {
      params: {
        modelName
      },
      responseType: 'blob'
    },
    data: {
      ExportType: exportType,
      Search: searchTerm
    },
    then: response => {
      const {
        data,
        headers
      } = response;
      if (!data || !data.size) return;
      const {
        ['content-disposition']: dispString
      } = headers;
      const fileName = dispString ? dispString.slice(dispString.indexOf('"') + 1, dispString.lastIndexOf('"')) : `${modelName}_Users__Requested_By_${userId}.${exportType === _visualTypes.ExportTypes.PDF ? 'pdf' : 'xlsx'}`;
      _fileSaver.default.saveAs(data, fileName);
    },
    returnBody: false
  }),
  getUsersSummary: modelName => ({
    baseURL: integration,
    method: 'get',
    url: `security/Summary`,
    config: {
      params: {
        modelName
      }
    }
  }),
  userSearch: data => ({
    baseURL: integration,
    method: 'post',
    url: `users/UserSearch`,
    data
  }),
  getUserPreferences: modelName => ({
    baseURL: integration,
    method: 'get',
    url: `users/preferences`,
    config: {
      params: {
        modelName
      }
    }
  }),
  getNetworkfilters: (modelName, pi) => ({
    baseURL: integration,
    method: 'get',
    url: `networkfilters`,
    config: {
      params: {
        modelName: modelName,
        pageSize: pi
      }
    }
  }),
  getUserNetworkfilters: (modelName, userId) => ({
    baseURL: integration,
    method: 'post',
    url: `networkfilters/UserFilters`,
    data: {
      ModelName: modelName,
      UserId: userId
    }
  }),
  saveUserFilterPermissions: (data, methodType) => ({
    baseURL: integration,
    method: methodType,
    url: `networkfilters/userFilterPermissions`,
    data: data.UserFilterPermissionsIds,
    config: {
      params: {
        modelName: data.ModelName,
        userId: data.UserId,
        deleteAll: data.DeleteAll
      }
    }
  })
});
exports.default = _default;