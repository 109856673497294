import { PlatformEnvironment } from 'genesis-suite/types/announcementTypes'
import { useSnackbar } from 'notistack'
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { usePlatformAuth } from './PlatformAuthContext'
import { platformService } from 'src/lib/services'

interface PlatformEnvironmentsContextProps {
    environments: PlatformEnvironment[]
    isLoading: boolean
    selectedEnvironments: PlatformEnvironment[]
    setSelectedEnvironments: (environments: PlatformEnvironment[]) => void
}

interface EnvironmentsProviderProps {
    children: ReactNode
}

const PlatformEnvironmentsContext = createContext<PlatformEnvironmentsContextProps | undefined>(undefined)

const PlatformEnvironmentsProvider: FC<EnvironmentsProviderProps> = ({ children }) => {
    const [environments, setEnvironments] = useState<PlatformEnvironment[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()
    const { activeAccount } = usePlatformAuth()
    const [selectedEnvironments, setSelectedEnvironments] = useState<PlatformEnvironment[]>([])

    useEffect(() => {
        const fetchEnvironments = async () => {
            setIsLoading(true)
            try {
                const response: PlatformEnvironment[] = await platformService.getEnvironments()
                const sortedEnvironments = response.sort((a, b) => a.name.localeCompare(b.name))
                setEnvironments(sortedEnvironments)
            } catch (error) {
                console.error('Error fetching environments:', error)
                enqueueSnackbar('Failed to load environments. Please try again later.', { variant: 'error' })
            } finally {
                setIsLoading(false)
            }
        }

        if (activeAccount) fetchEnvironments()
    }, [enqueueSnackbar, activeAccount])

    return (
        <PlatformEnvironmentsContext.Provider
            value={{ environments, isLoading, selectedEnvironments, setSelectedEnvironments }}
        >
            {children}
        </PlatformEnvironmentsContext.Provider>
    )
}

export { PlatformEnvironmentsContext, PlatformEnvironmentsProvider }

export const usePlatformEnvironments = (): PlatformEnvironmentsContextProps => {
    const context = useContext(PlatformEnvironmentsContext)
    if (!context) {
        return { environments: [], isLoading: false, selectedEnvironments: [], setSelectedEnvironments: () => void {} }
    }
    return context
}
