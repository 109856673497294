"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, baseUrls) => generator({
  get: (baseURL, accessKey) => ({
    baseURL,
    method: 'get',
    url: `orgclouds`,
    config: {
      params: {
        accessKey,
        onlyForUser: true
      }
    }
  }),
  create: (baseURL, databaseObject) => ({
    baseURL,
    method: 'post',
    url: `orgclouds`,
    data: databaseObject,
    config: {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }),
  delete: (baseURL, databaseId) => ({
    baseURL,
    method: 'delete',
    url: `orgclouds`,
    config: {
      params: {
        id: databaseId
      }
    }
  }),
  getDefault: baseURL => ({
    baseURL,
    method: 'get',
    url: `orgclouds/defaultcloud`
  }),
  updateDefault: (baseURL, cloudId, accessKey) => ({
    baseURL,
    method: 'put',
    url: `orgclouds/defaultcloud`,
    data: {},
    config: {
      params: {
        accessKey,
        cloudId
      }
    }
  })
});
exports.default = _default;