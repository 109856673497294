import React, { useState } from 'react'
import { Avatar, IconButton, Menu, MenuItem, Tooltip, Typography, Box, Theme, SxProps, Divider } from '@mui/material'
import { usePlatformAuth } from 'src/contexts/PlatformAuthContext'
import { useSnackbar } from 'notistack'
import { Logout } from '@mui/icons-material'

const PlatformUserMenu = ({ sx }: { sx?: SxProps<Theme> }) => {
    const { activeAccount, authInstance } = usePlatformAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    if (!activeAccount || !authInstance) return null

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        handleMenuClose()
        const currentAccount = authInstance.getAccountByHomeId(activeAccount.homeAccountId)
        authInstance
            .logoutPopup({ account: currentAccount })
            .then(() => {
                enqueueSnackbar('Logout successful!', { variant: 'info' })
            })
            .catch(error => {
                console.warn({ error })
                if (error.errorCode === 'popup_window_error') {
                    enqueueSnackbar('Please disable popup blockers and try again.', { variant: 'error' })
                } else {
                    enqueueSnackbar('Logout failed. Please try again.', { variant: 'error' })
                }
            })
    }

    const getUserInitial = (name: string) => {
        return name.charAt(0).toUpperCase()
    }

    const userName = activeAccount.name || activeAccount.username
    const userEmail = activeAccount.username

    return (
        <>
            <Tooltip title={userName}>
                <IconButton onClick={handleMenuOpen} sx={sx} size="small">
                    <Avatar sx={{ width: 36, height: 36, fontSize: 20, fontWeight: 'bold' }}>
                        {getUserInitial(userName)}
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ px: 2, py: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        {userName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {userEmail}
                    </Typography>
                </Box>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <Logout fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body1">Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default PlatformUserMenu
