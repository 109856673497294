import { useContext, useEffect, useState, useMemo } from 'react'
import { Box, TextField, Typography, Tooltip } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import FileUpload from '../FileUpload'
import { TextEditorV2 } from 'genesis-suite/components'
import { baseUrls, platformService, uploadService } from 'src/lib/services'
import { SolutionAuthContext } from 'src/contexts/SolutionAuthContext'
import InputTags from 'src/components/InputTags'
import { Help } from '@mui/icons-material'
import { Cloud } from 'src/types'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'
import { FileHandlerConfig } from 'genesis-suite/components/text-editor-v2/fileHandler'

const StepDetails = ({
    activeCloud,
    mode = AnnouncementAppMode.Solution,
}: {
    activeCloud: Cloud | null
    mode?: AnnouncementAppMode
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const { user } = useContext(SolutionAuthContext)

    const selectedApplicationId = useWatch({
        control,
        name: 'applicationId',
    })

    const [selectedApplication, setSelectedApplication] = useState(
        () => activeCloud?.applications.find(app => app.id === selectedApplicationId) || null
    )

    useEffect(() => {
        if (activeCloud && selectedApplicationId) {
            setSelectedApplication(activeCloud.applications.find(app => app.id === selectedApplicationId) || null)
        }
    }, [activeCloud, selectedApplicationId])

    const fileHandlerConfig: FileHandlerConfig = useMemo(
        () => ({
            getTokenForFile: async (file: File): Promise<string> => {
                if (mode === AnnouncementAppMode.Platform) {
                    const fileData = new FormData()
                    fileData.append('file', file, file.name)
                    const response = await platformService.uploadFile(fileData, file.name, false)
                    return response.fileId
                } else {
                    if (!selectedApplication || !user) {
                        console.warn('Application or user not selected')
                        return ''
                    }
                    const fileData = new FormData()
                    fileData.append('file', file, file.name)
                    const response = await uploadService.uploadFile(
                        fileData,
                        file.name,
                        selectedApplication.name,
                        false
                    )
                    return response
                }
            },

            getSrcUrlFromToken: (token: string): string => {
                if (mode !== AnnouncementAppMode.Platform && !user) {
                    console.warn('User not authenticated')
                    return ''
                }
                return mode === AnnouncementAppMode.Platform
                    ? `${baseUrls.platform}/File/id/${token}`
                    : `${baseUrls.integration}/files?fileToken=${token}&accessKey=${user?.accessKey || ''}`
            },
        }),
        [mode, selectedApplication, user]
    )

    return (
        <Box>
            <Controller
                name="title"
                control={control}
                rules={{ required: 'Title is required' }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Title"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={!!errors.title}
                        helperText={errors.title ? 'Title is required' : ''}
                    />
                )}
            />

            <Box marginY={1}>
                <Controller
                    name="tags"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <InputTags initialValue={value} onChange={onChange} onBlur={onBlur} label="Tags" />
                    )}
                />
            </Box>

            <Box marginY={2}>
                <Typography variant="body1">Description</Typography>
                <Controller
                    name="description"
                    control={control}
                    rules={{ required: 'Description is required' }}
                    render={({ field }) => (
                        <Box marginY={1}>
                            <TextEditorV2
                                initialValue={field.value}
                                minHeight={400}
                                userAccessKey={user?.accessKey || null}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                helperText={errors.description ? 'Description is Requried' : ''}
                                fileHandlerConfig={fileHandlerConfig}
                            />
                        </Box>
                    )}
                />
            </Box>

            <Box marginY={2}>
                <Box display="flex">
                    <Typography variant="body1">Attachments</Typography>
                    <Tooltip title="These attachments will be available for users to download.">
                        <Help sx={{ marginLeft: 1 }} />
                    </Tooltip>
                </Box>
                <Box marginY={1}>
                    <FileUpload mode={mode} selectedApplication={selectedApplication} />
                </Box>
            </Box>
        </Box>
    )
}

export default StepDetails
