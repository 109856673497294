import tadaLogoLight from '../tadanow/tada-logo-light.svg'
import tadaLogoDark from '../tadanow/tada-logo-dark.svg'
import tadaLogoSmallLight from '../tadanow/tada-logo-small-light.svg'
import tadaLogoSmallDark from '../tadanow/tada-logo-small-dark.svg'

export default {
    light: tadaLogoLight,
    dark: tadaLogoDark,
    smallLight: tadaLogoSmallLight,
    smallDark: tadaLogoSmallDark,
}
