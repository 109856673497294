import React from 'react'
import { CheckCircleRounded, ErrorRounded, WarningRounded } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette }) => ({
    errorColor: { color: palette.status.error },
    successColor: { color: palette.status.success },
    warningColor: { color: palette.status.warning },
    listRoot: { display: 'flex', flexFlow: 'row wrap', '& > li': { flex: '0 0 50%' } },
}))

export default function PasswordDesc({ password = '' }) {
    const classes = useStyles()
    const conditions = [
        { description: 'At least 8 characters long', validator: () => password?.length > 7 },
        { description: 'At least 1 upper case letter', validator: () => /([A-Z])/.test(password) },
        { description: 'At least 1 lower case letter', validator: () => /([a-z])/.test(password) },
        { description: 'At least 1 number', validator: () => /([0-9])/.test(password) },
        {
            description: `At least 1 special character (except ', <, >, %, *, &, :, \\, /, ?)`,
            validator: () => /^(?!.*[\s:<>%*&:\\?]).*(\W)/.test(password),
        },
    ]
    return (
        <List className={classes.listRoot}>
            {conditions.map(({ description, validator }) => (
                <ListItem key={description}>
                    <ListItemIcon>
                        {validator() ? (
                            <CheckCircleRounded className={classes.successColor} />
                        ) : (
                            <ErrorRounded className={classes.errorColor} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={description} />
                </ListItem>
            ))}
            <ListItem>
                <ListItemIcon>
                    <WarningRounded className={classes.warningColor} />
                </ListItemIcon>
                <ListItemText primary="Password cannot be one of your last 3" />
            </ListItem>
        </List>
    )
}
