"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  createTransform: (projectId, workspaceId, data) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `transforms`,
    data,
    config: {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        projectId,
        workspaceId
      }
    }
  }),
  executeOperation: data => ({
    baseURL: orgWeb,
    method: 'put',
    url: `transforms`,
    data
  }),
  deleteOperation: data => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `transforms`,
    data,
    config: {
      params: {
        ...data
      }
    }
  }),
  createAssignments: data => ({
    baseURL: orgWeb,
    method: 'post',
    url: `transforms/directassign`,
    data
  }),
  getSchema: (projectId, workspaceId, sequenceName, operationOrder, tableName, dataSourceName) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `transforms/inputschema`,
    config: {
      params: {
        projectId,
        workspaceId,
        sequenceName,
        operationOrder,
        tableName,
        dataSourceName
      }
    }
  }),
  getAssignmentDetails: (projectId, workspaceId) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sequences`,
    config: {
      params: {
        projectId,
        workspaceId
      }
    },
    then: res => res ? res.map(({
      details
    }) => details[details.length - 1]) : []
  }),
  getSequenceDetails: (sequenceName, projectId, workspaceId, dataSourceName) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sequences/${sequenceName}`,
    config: {
      params: {
        projectId,
        workspaceId,
        dataSourceName
      }
    }
  }),
  renameSequence: (sequenceName, projectId, workspaceId, newName) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `sequences/${sequenceName}/rename`,
    config: {
      params: {
        projectId,
        workspaceId,
        newName
      }
    }
  }),
  updateSequence: (sequenceName, projectId, workSpaceId) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sequences`,
    config: {
      params: {
        sequenceName,
        projectId,
        workSpaceId
      }
    }
  }),
  deleteSequence: (projectId, workspaceId, sequenceName) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `sequences`,
    config: {
      params: {
        projectId,
        workspaceId,
        sequenceName
      }
    }
  }),
  getSequenceDataSource: (projectId, workspaceId) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `transforms/sequencesource/${projectId}/${workspaceId}`
  }),
  getValidations: (projectId, workspaceId, jobId) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `transforms/validations`,
    config: {
      params: {
        projectId,
        workspaceId,
        jobId
      }
    }
  })
});
exports.default = _default;