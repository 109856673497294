import React, { useState } from 'react'
import { Box, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Stepper from 'src/components/stepper/Stepper'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import BackButton from 'src/components/BackButton'
import { SolutionAnnouncement } from 'genesis-suite/types/announcementTypes'
import { announcementService } from 'src/lib/services'
import { Cloud } from 'src/types'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

const useStyles = makeStyles(({ custom, palette }) => {
    const { maxWidth, paddingBig } = custom.announcementsApp || {}
    return {
        container: {
            height: '100%',
            backgroundColor: palette.background.default,
        },
        maxWidthAndPadding: {
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
        },
        content: {
            height: '100%',
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
            display: 'flex',
            flexDirection: 'column',
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

export interface FormData {
    id?: string
    scope: string
    cloudId: string
    applicationId: string | null
    portalId: string | null
    type: string
    title: string
    description: string
    attachments: File[]
    startDate: Date
    endDate: Date
    whitelistedUserIds: string | null
}

const CreateAnnouncement: React.FC<{ activeCloud?: Cloud | null }> = ({ activeCloud = null }) => {
    const { applicationName } = useParams<{ applicationName: string }>()
    const classes = useStyles()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const [isCreating, setIsCreating] = useState<boolean>(false)

    const activeApplication = activeCloud?.applications?.find(a => a.name === applicationName) || null

    const handleDraft = async (newAnnouncement: SolutionAnnouncement) => {
        setIsCreating(true)
        try {
            const response: SolutionAnnouncement = await announcementService.createAnnouncement(newAnnouncement)
            if (response) {
                navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(activeCloud?.name || '' , applicationName || ''))
                enqueueSnackbar('Draft created successfully!', { variant: 'success' })
            }
        } catch (error) {
            console.error('Error creating draft:', error)
            enqueueSnackbar('Failed to create draft. Please try again.', { variant: 'error' })
        } finally {
            setIsCreating(false)
        }
    }

    const handleCreate = async (newAnnouncement: SolutionAnnouncement) => {
        setIsCreating(true)
        try {
            const response: SolutionAnnouncement = await announcementService.createAnnouncement(newAnnouncement)
            navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENT_DETAILS(activeCloud?.name || '' , response.id || ''))
            enqueueSnackbar('Announcement created successfully!', { variant: 'success' })
        } catch (error) {
            console.error('Error creating announcement:', error)
            enqueueSnackbar('Failed to create announcement. Please try again.', { variant: 'error' })
        } finally {
            setIsCreating(false)
        }
    }

    if (!activeCloud) {
        return (
            <Box className={classes.container}>
                <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
                    No cloud selected. Please select a cloud to create an announcement.
                </Typography>
            </Box>
        )
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.maxWidthAndPadding}>
                <Toolbar className={classes.toolbar}>
                    <BackButton cloudName={activeCloud.name} applicationName={applicationName} />
                </Toolbar>
            </Box>
            <Box className={classes.content}>
                <Box>
                    <Typography variant="h4" sx={{ marginTop: 4 }}>
                        Create an Announcement
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                        Use this page to create maintenance or feature announcements. Please provide clear and detailed
                        information.
                    </Typography>
                    <Stepper
                        activeCloud={activeCloud}
                        activeApplication={activeApplication}
                        sx={{ marginTop: 8, marginBottom: 4 }}
                        onDraft={handleDraft}
                        onFinish={handleCreate}
                        isDisabled={isCreating}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CreateAnnouncement
