import SSOCallback from '../views/SSOCallback'
import LoginView from '../views/LoginView'
import PasswordReset from '../views/password_reset/PasswordReset'
import PasswordSuccess from '../views/password_reset/PasswordSuccess'
import { matchPath } from 'react-router-dom'
import AnnouncementList from '../views/AnnouncementList'
import AnnouncementDetails from '../views/AnnouncementDetails'
import CloudSelection from 'src/views/CloudSelection'
import CreateAnnouncement from 'src/views/CreateAnnouncement'
import UpdateAnnouncement from 'src/views/UpdateAnnouncement'
import { SOLUTION_BASE, SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

export const solutionRoutes = [
    {
        path: SOLUTION_ROUTE_PATHS.LOGIN,
        secured: false,
        useCloudGuard: false,
        adminOnly: false,
        superUserOnly: false,
        component: LoginView,
    },
    {
        path: SOLUTION_ROUTE_PATHS.CALLBACK,
        secured: false,
        useCloudGuard: false,
        adminOnly: false,
        superUserOnly: false,
        component: SSOCallback,
    },
    {
        path: SOLUTION_ROUTE_PATHS.PASSWORD_RESET,
        secured: false,
        useCloudGuard: false,
        adminOnly: false,
        superUserOnly: false,
        component: PasswordReset,
    },
    {
        path: SOLUTION_ROUTE_PATHS.PASSWORD_RESET_SUCCESS,
        secured: false,
        useCloudGuard: false,
        adminOnly: false,
        superUserOnly: false,
        component: PasswordSuccess,
    },
    {
        path: SOLUTION_ROUTE_PATHS.SELECT,
        secured: true,
        useCloudGuard: false,
        adminOnly: false,
        superUserOnly: false,
        component: CloudSelection,
    },
    {
        path: `${SOLUTION_BASE}/:cloudName`,
        secured: true,
        component: AnnouncementList,
        useCloudGuard: true,
        adminOnly: false,
        superUserOnly: false,
    },
    {
        path: `${SOLUTION_BASE}/:cloudName/create`,
        secured: true,
        component: CreateAnnouncement,
        useCloudGuard: true,
        adminOnly: true,
        superUserOnly: false,
    },
    {
        path: `${SOLUTION_BASE}/:cloudName/:applicationName`,
        secured: true,
        component: AnnouncementList,
        useCloudGuard: true,
        adminOnly: false,
        superUserOnly: false,
    },
    {
        path: `${SOLUTION_BASE}/:cloudName/:applicationName/create`,
        secured: true,
        component: CreateAnnouncement,
        useCloudGuard: true,
        adminOnly: true,
        superUserOnly: false,
    },
    {
        path: `${SOLUTION_BASE}/:cloudName/:applicationName/update`,
        secured: true,
        component: UpdateAnnouncement,
        useCloudGuard: true,
        adminOnly: true,
        superUserOnly: false,
    },
    {
        path: `${SOLUTION_BASE}/:cloudName/announcement/:announcementId`,
        secured: true,
        component: AnnouncementDetails,
        useCloudGuard: true,
        adminOnly: false,
        superUserOnly: false,
    },
]

export function requiresAuthentication(pathname: string) {
    return Boolean(solutionRoutes.filter(r => r.secured).find(path => matchPath(path, pathname)))
}
