"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getRawDataTables: (datasourceId, projectId, workspaceId) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `rawDataManager`,
    data: {
      datasourceId,
      projectId,
      workspaceId
    },
    config: {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }),
  getTableData: (url, projectId, sessionKey, sourceId, pageIndex, pageSize) => ({
    baseURL: orgWeb,
    method: 'get',
    url: url,
    config: {
      params: {
        projectId,
        sourceId,
        pageIndex,
        pageSize,
        accessKey: sessionKey
      }
    }
  }),
  getArchitectureData: (type, name, cloudId, modelId, modelPartId, pageIndex, pageSize) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `${type}/${name}/data`,
    config: {
      params: {
        cloudId,
        modelId,
        modelPartId,
        pageIndex,
        pageSize
      }
    }
  }),
  getOpData: (projectId, workspaceId, sequenceName, operationOrder, pageIndex, pageSize, sessionKey) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `transforms/data`,
    config: {
      params: {
        projectId,
        workspaceId,
        sequenceName,
        operationOrder,
        pageIndex,
        pageSize,
        accessKey: sessionKey
      }
    }
  })
});
exports.default = _default;