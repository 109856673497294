"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _helpers = require("../../utils/helpers");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = (generator, {
  forms,
  integration
}) => generator({
  getExcelColumns: (fileToken, modelName) => ({
    baseURL: forms,
    method: 'get',
    url: 'api/templates',
    config: {
      params: {
        fileToken,
        modelName
      }
    },
    then: body => {
      const {
        Data,
        TotalRecords
      } = body;
      const totalRecords = TotalRecords;
      let fields = [];
      let tableName = '';
      if (Data) {
        const firstTable = Data[Object.keys(Data)[0]][0];
        tableName = Object.keys(Data)[0];
        fields = Object.keys(firstTable).map(f => ({
          name: f,
          firstRecord: firstTable[f]
        }));
      }
      return {
        fields,
        totalRecords,
        tableName
      };
    }
  }),
  mapExcelToFormColumns: (modelName, formId, fileToken, data) => ({
    baseURL: forms,
    method: 'put',
    url: 'api/templates',
    config: {
      params: {
        allData: true,
        modelName,
        formId,
        fileToken
      }
    },
    data
  }),
  exportExcel: (formId, modelName, data) => ({
    baseURL: forms,
    method: 'post',
    url: 'api/exports',
    data,
    config: {
      params: {
        configId: formId,
        modelName
      },
      responseType: 'blob'
    },
    then: response => {
      const {
        data,
        headers
      } = response;
      if (!data || !data.size) return;
      const {
        ['content-disposition']: dispString
      } = headers;
      const fileName = dispString.slice(dispString.indexOf('"') + 1, dispString.lastIndexOf('"'));
      _fileSaver.default.saveAs(data, fileName);
    },
    returnBody: false
  }),
  getForms: (modelName, backupId) => ({
    baseURL: integration,
    method: 'get',
    url: 'dataforms',
    config: {
      params: (0, _helpers.configParams)({
        modelName: modelName
      }, backupId)
    },
    then: res => {
      return res ? JSON.parse(res) : [];
    }
  }),
  getAttachmentMeta: (id, modelName) => ({
    baseURL: integration,
    method: 'get',
    url: `attachments`,
    config: {
      params: {
        id,
        modelName,
        loadOnlyInfo: true
      }
    }
  }),
  getAttachment: (id, modelName) => ({
    baseURL: integration,
    method: 'get',
    url: `attachments`,
    config: {
      params: {
        id,
        modelName
      },
      responseType: 'blob'
    },
    then: response => {
      const {
        data,
        headers
      } = response;
      if (!data || !data.size) return;
      const {
        ['attachmentinfo']: info
      } = headers;
      const parsed = JSON.parse(info);
      _fileSaver.default.saveAs(data, parsed.FullName);
    },
    returnBody: false
  }),
  getAttachmentBlob: (id, modelName) => ({
    baseURL: integration,
    method: 'get',
    url: `attachments`,
    config: {
      params: {
        id,
        modelName
      },
      responseType: 'blob'
    }
  }),
  saveAttachment: (data, fileName, modelName, propertyId, id) => ({
    baseURL: integration,
    method: 'post',
    url: `attachments`,
    data,
    config: {
      params: {
        fileName,
        modelName,
        propertyId,
        id
      }
    }
  })
});
exports.default = _default;