import React from 'react'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Logout as LogoutIcon } from 'genesis-suite/icons'

export default function Logout() {
    return (
        <React.Fragment>
            <ListItemIcon>
                <LogoutIcon color="primary" />
            </ListItemIcon>
            <Typography display="inline" variant="subtitle1">
                {'Logout'}
            </Typography>
        </React.Fragment>
    )
}
