"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
/**
 * A wrapper for the standard useEffect() hook from react that is not called on the initial render/component mount
 * @param {*} effect
 * @param {*} dependencies
 */
var _default = (effect, dependencies) => {
  const componentDidMount = (0, _react.useRef)(false);
  (0, _react.useEffect)(() => {
    if (!componentDidMount.current) {
      componentDidMount.current = true;
      return;
    }
    effect();
  }, [...dependencies]);
};
exports.default = _default;