"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformJsonForAPI = exports.oldtTransformJsonForAPI = exports.oldTransformJsonForEditor = exports.handleInitialValue = void 0;
const URL_KEYS = ['src', 'href'];

/**
 * Updates JSON for API usage by replacing file URLs with their corresponding tokens and adding attachment metadata.
 *
 * @param jsonString - The JSON string to be transformed.
 * @param attachments - An array of attachments containing tokens and source URLs for replacement.
 *
 * This function replaces each attachment's src URL with a placeholder token (e.g., '${TOKEN}'),
 * ensuring that the actual file URLs are not exposed. It also adds an attachments array containing
 * the token metadata to the transformed JSON.
 */
const transformJsonForAPI = (jsonString, attachments) => {
  let transformedJson = jsonString;
  for (const attachment of attachments) {
    transformedJson = transformedJson.replace(attachment.srcUrl, '${' + attachment.token + '}');
  }
  try {
    const jsonObj = JSON.parse(transformedJson);
    jsonObj.attachments = attachments.map(a => ({
      token: a.token
    }));
    return oldtTransformJsonForAPI(JSON.stringify(jsonObj));
  } catch (error) {
    console.warn('Error parsing transformed JSON:', error);
    return null;
  }
};

/**
 * Transforms JSON string for API usage by replacing all accessKey values with a placeholder.
 */
exports.transformJsonForAPI = transformJsonForAPI;
const oldtTransformJsonForAPI = jsonString => {
  try {
    const jsonObj = JSON.parse(jsonString);
    replaceAccessKeyPlaceholder(jsonObj);
    return JSON.stringify(jsonObj);
  } catch (error) {
    console.warn('Error parsing json string for API:', error);
    return null;
  }
};

/**
 * Replaces the 'accessKey' query parameter with 'USER_ACCESS_KEY' in 'src' and 'href' URLs.
 */
exports.oldtTransformJsonForAPI = oldtTransformJsonForAPI;
function replaceAccessKeyPlaceholder(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      replaceAccessKeyPlaceholder(value);
    } else if (URL_KEYS.includes(key) && typeof value === 'string') {
      // Check if the value starts with '${', indicating a placeholder token
      if (value.startsWith('${')) {
        // Skip processing this value
        continue;
      }
      try {
        const url = new URL(value);
        if (url.searchParams.has('accessKey')) {
          url.searchParams.set('accessKey', 'USER_ACCESS_KEY');
          obj[key] = url.toString();
        }
      } catch (error) {
        console.warn(`Invalid URL format for key '${key}':`, value, error);
      }
    }
  }
}

/**
 * Processes the initial value for the text editor, replacing tokens with actual URLs and transforming content for editor usage.
 *
 * @param initialValue - The initial value in JSON string format.
 * @param getSrcUrlFromToken - A function to get the source URL from a file token.
 * @param userAccessKey - The user's access key used for content transformation.
 * @returns An object containing the processed initial content and an array of initial attachments.
 *
 * This function parses the initial value, replaces tokens in the content with actual URLs,
 * filters attachments, and prepares the content for usage in the editor.
 */
const handleInitialValue = (initialValue, getSrcUrlFromToken, userAccessKey) => {
  if (!initialValue) return {
    initialContent: null,
    initialAttachments: []
  };
  try {
    const intialValueObj = JSON.parse(initialValue);
    let initialAttachments = intialValueObj.attachments || [];
    delete intialValueObj.attachments;
    let initialContentJson = JSON.stringify(intialValueObj);
    if (getSrcUrlFromToken) {
      for (const attachment of initialAttachments) {
        const srcUrl = getSrcUrlFromToken(attachment.token);
        initialContentJson = initialContentJson.replace('${' + attachment.token + '}', srcUrl);
        attachment.srcUrl = srcUrl;
      }
    }
    initialAttachments = initialAttachments.filter(a => initialContentJson.includes(a.token));
    initialContentJson = oldTransformJsonForEditor(initialContentJson, userAccessKey);
    return {
      initialContent: JSON.parse(initialContentJson),
      initialAttachments
    };
  } catch (error) {
    console.warn('Error handling initial value for editor:', error);
    return {
      initialContent: null,
      initialAttachments: []
    };
  }
};

/**
 * Transforms JSON string for editor usage by replacing the placeholder '${USER_ACCESS_KEY}'
 * with the actual user access key.
 */
exports.handleInitialValue = handleInitialValue;
const oldTransformJsonForEditor = (jsonString, userAccessKey) => {
  if (!jsonString || !userAccessKey) return jsonString;
  try {
    const jsonObj = JSON.parse(jsonString);
    replaceUserAccessKey(jsonObj, userAccessKey);
    return JSON.stringify(jsonObj);
  } catch (error) {
    console.warn('Error parsing json string for editor:', error);
    return jsonString;
  }
};

/**
 * Replaces 'USER_ACCESS_KEY' with the actual userAccessKey in 'src' and 'href' URLs.
 */
exports.oldTransformJsonForEditor = oldTransformJsonForEditor;
function replaceUserAccessKey(obj, userAccessKey) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceUserAccessKey(obj[key], userAccessKey);
    } else if (URL_KEYS.includes(key)) {
      obj[key] = obj[key].replace('USER_ACCESS_KEY', userAccessKey);
    }
  }
}