"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _AxisType = require("./AxisType");
Object.keys(_AxisType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _AxisType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _AxisType[key];
    }
  });
});
var _baseWidgetTypes = require("./baseWidgetTypes");
Object.keys(_baseWidgetTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _baseWidgetTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _baseWidgetTypes[key];
    }
  });
});
var _DataRequestType = require("./DataRequestType");
Object.keys(_DataRequestType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DataRequestType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DataRequestType[key];
    }
  });
});
var _DataResponseType = require("./DataResponseType");
Object.keys(_DataResponseType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DataResponseType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DataResponseType[key];
    }
  });
});
var _DataSourceTypes = require("./DataSourceTypes");
Object.keys(_DataSourceTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DataSourceTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DataSourceTypes[key];
    }
  });
});
var _EditEnums = require("./EditEnums");
Object.keys(_EditEnums).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _EditEnums[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _EditEnums[key];
    }
  });
});
var _Flowchart = require("./Flowchart");
Object.keys(_Flowchart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Flowchart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Flowchart[key];
    }
  });
});
var _FormatTypes = require("./FormatTypes");
Object.keys(_FormatTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FormatTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FormatTypes[key];
    }
  });
});
var _FormTypes = require("./FormTypes");
Object.keys(_FormTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FormTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FormTypes[key];
    }
  });
});
var _inlineWidgetTypes = require("./inlineWidgetTypes");
Object.keys(_inlineWidgetTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _inlineWidgetTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _inlineWidgetTypes[key];
    }
  });
});
var _Justification = require("./Justification");
Object.keys(_Justification).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Justification[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Justification[key];
    }
  });
});
var _ReportType = require("./ReportType");
Object.keys(_ReportType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ReportType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ReportType[key];
    }
  });
});
var _SeriesType = require("./SeriesType");
Object.keys(_SeriesType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _SeriesType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SeriesType[key];
    }
  });
});
var _WidgetsRequestFilter = require("./WidgetsRequestFilter");
Object.keys(_WidgetsRequestFilter).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _WidgetsRequestFilter[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _WidgetsRequestFilter[key];
    }
  });
});