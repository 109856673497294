import { Routes, Route, Navigate } from 'react-router-dom'
import PlatformApp from './PlatformApp'
import SolutionApp from './SolutionApp'
import { PLATFORM_BASE, SOLUTION_BASE } from './lib/routePath'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { useAppMode } from './contexts/AppModeContext'

LicenseInfo.setLicenseKey(
    'f12314dd03314d4d4d3dd1f709938dd3T1JERVI6Mzc0ODYsRVhQSVJZPTE2NzU5NjE4ODEwMDAsS0VZVkVSU0lPTj0x'
)

const App = () => {
    const { isPlatformEnv } = useAppMode()

    if (isPlatformEnv) {
        return (
            <Routes>
                <Route path={`${PLATFORM_BASE}/*`} element={<PlatformApp />} />
                <Route path="*" element={<Navigate to={PLATFORM_BASE} replace />} />
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route path={`${PLATFORM_BASE}/*`} element={<PlatformApp />} />
                <Route path={`${SOLUTION_BASE}/*`} element={<SolutionApp />} />
                <Route path="*" element={<Navigate to={SOLUTION_BASE} replace />} />
            </Routes>
        )
    }
}

export default App
