import tadanowLogo from '../assets/logos/tadanow/logo'

export function getAppLogo() {
    const environment = window.TADA_APIS.ENVIRONMENT

    switch (environment) {
        default: {
            return tadanowLogo
        }
    }
}
