"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTextEditorStyles = void 0;
const getTextEditorStyles = (editable, minHeight, isChat) => {
  const commonStyles = {
    '& h1': {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      margin: '1.5rem 0'
    },
    '& h2': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '1.25rem 0'
    },
    '& h3': {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      margin: '1rem 0'
    },
    '& img.editor-image': {
      display: 'block',
      height: 'auto',
      margin: '1.5rem 0',
      maxWidth: '100%'
    },
    '& video.editor-video': {
      display: 'block',
      maxWidth: '100%',
      margin: '1.5rem 0'
    }
  };
  const chatStyle = {
    height: '50px',
    overflow: 'scroll',
    padding: 0.5,
    overflowX: 'hidden',
    '& .tiptap p': {
      margin: '4px 0'
    }
  };
  if (!editable) {
    return {
      ...commonStyles,
      ...(isChat ? chatStyle : {}),
      border: 'none',
      '& .tiptap': {
        outline: 'none',
        minHeight: 'auto'
      }
    };
  }
  return {
    ...commonStyles,
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: 1,
    padding: 1,
    ...(isChat ? chatStyle : {}),
    '&:focus-within': {
      borderColor: 'primary.main'
    },
    '& .tiptap': {
      outline: 'none',
      minHeight: minHeight
    },
    '& .mention': {
      backgroundColor: 'secondary.main',
      borderRadius: '0.4rem',
      boxDecorationBreak: 'clone',
      color: 'secondary.contrastText',
      padding: '0.1rem 0.3rem'
    },
    '& .ProseMirror-focused': {
      '& .ProseMirror-selectednode': {
        outline: '3px solid',
        outlineColor: 'primary.main'
      }
    }
  };
};
exports.getTextEditorStyles = getTextEditorStyles;