"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = require("../../utils/helpers");
var _default = (generator, {
  integration
}) => generator({
  getReport: (modelName, reportId, showDiagnostics, backupId) => ({
    baseURL: integration,
    method: 'get',
    url: `reports`,
    config: {
      params: (0, _helpers.configParams)({
        modelName,
        id: reportId
      }, backupId),
      headers: {
        'X-Fetch-TadaMetrics': showDiagnostics
      }
    }
  }),
  getLayouts: (reportId, modelName, loadDetails = false) => ({
    baseURL: integration,
    method: 'get',
    url: `reportlayouts`,
    config: {
      params: {
        reportId,
        modelName,
        loadDetails
      }
    }
  }),
  getLayoutById: id => ({
    baseURL: integration,
    method: 'get',
    url: `reportlayouts`,
    config: {
      params: {
        id
      }
    }
  }),
  getLayoutData: (modelName, data) => ({
    baseURL: integration,
    method: 'post',
    url: `reportlayouts/data`,
    config: {
      params: {
        modelName
      }
    },
    data
  }),
  createLayout: (layout, reportId, modelName) => ({
    baseURL: integration,
    method: 'post',
    url: `reportlayouts`,
    data: layout,
    config: {
      params: {
        reportId,
        modelName
      }
    }
  }),
  deleteLayout: id => ({
    baseURL: integration,
    method: 'delete',
    url: `reportlayouts`,
    config: {
      params: {
        id
      }
    }
  })
});
exports.default = _default;