import { SolutionAnnouncement, SolutionAnnouncementScope, SolutionAnnouncementType } from 'genesis-suite/types/announcementTypes'

const ANNOUNCEMENTS: SolutionAnnouncement[] = [
    {
        type: SolutionAnnouncementType.Maintenance,
        scope: SolutionAnnouncementScope.Application,
        cloudId: 'cd8686eb-ca15-41dc-aadc-5273907ec3e1',
        applicationId: '5a09c886-42ab-471c-8801-0aff2c9614f8',
        portalId: null,
        whitelistedUserIds: ['Dev Test', 'Akshee Thakur', 'Karthik'],
        title: 'Navistar Control Tower Maintenance',
        description:
            '{"type":"doc","content":[{"type":"heading","attrs":{"textAlign":"center","level":3},"content":[{"type":"text","text":"Please be aware that the Control Tower App will not be available"}]},{"type":"heading","attrs":{"textAlign":"center","level":2},"content":[{"type":"text","text":"From "},{"type":"text","marks":[{"type":"highlight"}],"text":"September 11, 2024, 5pm CST"},{"type":"text","text":" to "},{"type":"text","marks":[{"type":"highlight"}],"text":"September 11, 2024, 7pm CST"}]},{"type":"heading","attrs":{"textAlign":"center","level":3},"content":[{"type":"text","text":"as the IT team will be releasing backend platform improvements."}]},{"type":"heading","attrs":{"textAlign":"center","level":3},"content":[{"type":"text","text":"This maintenance is necessary to improve user experience."},{"type":"hardBreak"},{"type":"text","text":"We appolagize for any inconvenience."}]}]}',
        attachments: [],
        startDate: '2024-08-27T07:17:43.879Z',
        id: 'tC8HwUsIgNqQ',
        tags: [],
        bannerMedia: {
            name: 'scheduled-maintenance-message-examples-and-inspiration0a@3x.png.png',
            type: 'image/png',
            token: 'gIk2qy20n4uZMMBov8-Qr3zorXp8Kfxtr9vCwsPd_SMlqaNK9cBdvDOogXRQG8jv2BY61d4f_BGaJXvJfqtAf2IG60Kq3G32fcydLmjY3keOZc81A6RcS2laDbnf57ZSUAQh6_a2BQdGZev2j3TjDkdJb-HyN7uflSMbmwOn4mcqYwgwqUrFhZyeMTSKK-ErgBvXYOaaaHs_-kYztrB6ai2DyLk6NwIfzY02HE8xFVemPUA-jl8r_gpWZxVM54k8rdHwXOHM5cVmb-NNQiZ_MjuqTh0zXn9f8f0nuAAE5ao19XuCa1pV3TA9Mh4A8sC_kr8s0tElRRDCAolCU1kfdlpnMkH0PBpTVO6QYkDM_nYB5jAG5AWsey5ZvDqB6rFG',
        },
    },
    {
        type: SolutionAnnouncementType.Maintenance,
        scope: SolutionAnnouncementScope.Application,
        cloudId: 'cd8686eb-ca15-41dc-aadc-5273907ec3e1',
        applicationId: '5a09c886-42ab-471c-8801-0aff2c9614f8',
        portalId: null,
        whitelistedUserIds: ['Dev Test', 'Akshee Thakur', 'Karthik', 'Preethi Bojanapu'],
        title: 'SolutionAnnouncement',
        description:
            '{"type":"doc","content":[{"type":"heading","attrs":{"level":2},"content":[{"type":"text","text":"Scheduled Maintenance for NaviStar ControlTower Cloud"}]},{"type":"paragraph","content":[{"type":"text","text":"We are performing scheduled "},{"type":"text","marks":[{"type":"highlight"}],"text":"maintenance"},{"type":"text","text":" on the NaviStar ControlTower Cloud."}]}]}',
        attachments: [
            {
                name: 'Tada Now Supply Chain Trends.pdf',
                type: 'application/pdf',
                token: 'Nj0QCkRJfzeLZj1QFvrty3Sb3VjOqFwuuKXm7RameNmYi0NaJpfXpovbNaTFnUhwy2nsJII6mmeU-mS0OGSyRJg-QELtv_arSQ8YUzShkvC1873ASRt55apEW2RhPGUeA0LftxAaFoNW4ZhF28LEwX99OSTb1ZtjbZmuPuYU1jaUsMGuRJqMPQLVkQyxJut6ChOH_wGQR7J3hA2YJK3k4mFx3blZQM8xV1d5Poel5HYOqcFQnTwJobcjZck9B75jPq2twqFZkiC7b8eSs0iTdbJShLTriL4r2d4nVp-tdbCuSDlMYLcv9fB2_l9Lf8RYg2DirMvs134s-7y8Mvzr2-wL3hyUEiROgI1fPfhrmzM=',
            },
            {
                name: 'Sample Pdf File.pdf',
                type: 'application/pdf',
                token: 'WGr5IF9SiaXu6kej-wbBgGSFN2NGiBijVxI1Qw7GWHBifQZaFBXqvsOUYojhJe3VHDr-KtcwVLPcVnRsKygMf0rOl3QwYrz5difzINmBehvsVLVHwez6trDwtbw7-f2xl_Z8Qq6JYrw7BHWCJyJNIfwR5YO6l4p1FS0Yif1J92eu046UScViUkTQ70fWLvuXAHof2Ff8h5-IaE9Q0doL7EMedxbvT7uLczWYRKJTJmsu_TBz3OBDfCGYml4i6-bZomG1tc1eCwSV_lgL8WzmfOB38dTSkNDk3NVR3bsoZigjy0O-XmARyEn3MZz6stvYqpCiInBa7DHEqqw4grEMXw==',
            },
            {
                name: 'TLMS_20211029_1200x628-1-1.png',
                type: 'image/png',
                token: 'SPWG-D8eRebLwwvQ--m3cao2wd70vaHWLXGhSxqwKN6jFjJvJqV7HY9BbBGx_2r6Saglk_pIs9IoZOiI_PJaNdn5YEV6VmHXFVNOeB_AaYOHxzeX8sXdwowG0kDlcu8VNSf2pi3X-2c05zok8e9Aq9XrdQc1SqGvTYWW7CRNd2cruag9awzR4U1et2Nby1CP6WPgm6dqAheoNu5cKImMYn_wtYtgtTdn7Dh3tSeeK9nFFlCRcR7PfsdDBUtbOH3unYKZfJT1APRa5RPrHTJhPs_hQ6tVRw1rZciTT5QsF3d38vJfEVKTno1A01m1WfoaFJ7aV7Le9AqF_PubnhImYw==',
            },
        ],
        startDate: '2024-08-21T06:22:48.205Z',
        id: 'GuH4gCWb43Vy',
        tags: [],

        bannerMedia: {
            name: 'scheduled-maintenance-message-examples-and-inspiration0a@3x.png.png',
            type: 'image/png',
            token: 'gIk2qy20n4uZMMBov8-Qr3zorXp8Kfxtr9vCwsPd_SMlqaNK9cBdvDOogXRQG8jv2BY61d4f_BGaJXvJfqtAf2IG60Kq3G32fcydLmjY3keOZc81A6RcS2laDbnf57ZSUAQh6_a2BQdGZev2j3TjDkdJb-HyN7uflSMbmwOn4mfWox0AH47vJUegeE7UBY79CkkW50XBEhyCUjYhOLLZ4sdnApmc6UNuP03-70eIeFY9atcGVAQ1NYpnOd-LPvjv8T5vqQ_vfeNQSPGaneTlxu-iAWKNkqvWHExYxy1sBMHoa5EdRAHpOlPSsBE-iYL2dUTsNVmJBJexJzNm_YiXzy80IipCUJN21jnMU2pDn8PB9DUEe344p5c8N05QipZJ',
        },
    },
]

export default ANNOUNCEMENTS
