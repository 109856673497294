"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Takes an Object or an Array of Objects
 * and returns an Array of Objects with the shape
 * { label: "", value: ""}
 * Giving no data will result in an empty Array
 * @param data Object or Array of Objects
 * @param valueKey Optional, key of the value of the object(s)
 * @param labelKey Required if valueKey is provided, key of the label of the object(s)
 * @param sort Default true, will return the options Array in alphabetical order
 */
var _default = (data, valueKey, labelKey, sort = true) => {
  if (!data) return [];
  let result;
  if (Array.isArray(data)) {
    result = valueKey ? data.map(item => {
      return {
        value: item[valueKey],
        label: item[labelKey]
      };
    }) : data.map(item => ({
      value: item,
      label: item
    }));
  } else {
    result = Object.keys(data).map(key => {
      const value = valueKey ? data[key][valueKey] : key;
      const label = valueKey ? data[key][labelKey] : data[key];
      return {
        value,
        label
      };
    });
  }
  if (sort) {
    return result.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }
  return result;
};
exports.default = _default;