import Oidc, { UserManager, WebStorageStateStore } from 'oidc-client'
import cleanDirectory from 'genesis-suite/utils/cleanDirectory'
import { SOLUTION_BASE, SOLUTION_ROUTE_PATHS } from './routePath'

const {
    location: { protocol, hostname, port },
} = window

const url = `${protocol}//${hostname}${port ? `:${port}` : ''}${cleanDirectory(process.env.PUBLIC_URL)}`

if (process.env.NODE_ENV !== 'production') Oidc.Log.logger = console

const instance = new UserManager({
    client_id: 'websuite',
    redirect_uri: `${url}${SOLUTION_ROUTE_PATHS.CALLBACK}`,
    post_logout_redirect_uri: `${url}${SOLUTION_BASE}`,
    response_type: 'code',
    scope: 'openid profile email',
    authority: window.TADA_APIS.SSO,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: false,
    userStore: new WebStorageStateStore({ store: sessionStorage }),
})
Object.freeze(instance)

export function loginWithRedirect(extraQueryParams) {
    const { pathname, search } = window.location
    const path = `${pathname}${search}`
    instance.signinRedirect({ state: { path }, ...(extraQueryParams && { extraQueryParams }) })
}

export default instance
