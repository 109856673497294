import { Routes, Route, Navigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import PlatformProviders from './providers/PlatformProviders'
import PlatformTopNav from './components/platform/PlatformTopNav'
import { platformRoutes } from './routes/PlatformRoutes'
import { PLATFORM_BASE, PLATFORM_ROUTE_PATHS } from './lib/routePath'
import { useAppMode } from './contexts/AppModeContext'
import { usePlatformAuth } from './contexts/PlatformAuthContext'

const useStyles = makeStyles(() => ({
    main: { flexGrow: 1 },
}))

const PlatformAppContent = () => {
    const { isPlatformEnv } = useAppMode()
    const { activeAccount } = usePlatformAuth()
    const classes = useStyles()

    return (
        <>
            <PlatformTopNav />

            <main className={classes.main}>
                <Routes>
                    {platformRoutes
                        .filter(({ isAdminOnly }) => {
                            if (!isAdminOnly) return true
                            if (!isPlatformEnv) return false
                            if (!activeAccount) return false
                            return true
                        })
                        .map(({ path, component: ElementComponent }) => {
                            return (
                                <Route
                                    key={path}
                                    path={path.replace(`${PLATFORM_BASE}`, '')}
                                    element={<ElementComponent />}
                                />
                            )
                        })}
                    <Route path="*" element={<Navigate to={PLATFORM_ROUTE_PATHS.HOME} />} />
                </Routes>
            </main>
        </>
    )
}

const PlatformApp = () => {
    return (
        <PlatformProviders>
            <PlatformAppContent />
        </PlatformProviders>
    )
}

export default PlatformApp
