import React, { useState } from 'react'
import { TextField, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material'

const useStyles = makeStyles(({ spacing }) => ({
    passwordInputs: {
        '& > div': { marginRight: spacing(1) },
    },
}))

interface NewPWProps {
    password: string
    verifyPw: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function NewPassword({ password, verifyPw, onChange }: NewPWProps) {
    const classes = useStyles()
    const [showPass, setShowPass] = useState({
        password: false,
        verifyPw: false,
    })
    const toggleShowPass = (name: 'password' | 'verifyPw') => setShowPass({ ...showPass, [name]: !showPass[name] })

    return (
        <div className={classes.passwordInputs}>
            <TextField
                name="password"
                value={password}
                variant="outlined"
                onChange={onChange}
                label="New Password"
                placeholder="Enter password"
                type={showPass['password'] ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleShowPass('password')}
                            size="large">
                            {showPass['password'] ? <VisibilityRounded /> : <VisibilityOffRounded />}
                        </IconButton>
                    ),
                }}
            />
            <TextField
                name="verifyPw"
                value={verifyPw}
                variant="outlined"
                onChange={onChange}
                label="Verify Password"
                placeholder="Enter the same password as above"
                type={showPass['verifyPw'] ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleShowPass('verifyPw')}
                            size="large">
                            {showPass['verifyPw'] ? <VisibilityRounded /> : <VisibilityOffRounded />}
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}
