import services from 'genesis-suite/services'

import userManager from './userManager'
import { BaseUrls } from 'genesis-suite/services/types'
import { getPlatformAuthToken, logOutPlatformUser } from './platformAuthConfig'
import { pca } from 'src/providers/PlatformProviders'

export const baseUrls: BaseUrls = {
    integration: window.TADA_APIS.INTEGRATION,
    orgWeb: window.TADA_APIS.ORG_WEB,
    platform: window.TADA_APIS.PLATFORM,
}
const getAccessKey = () => userManager.getUser().then(user => user?.profile.TADAAccessKey)
const onUnauthorized = () => userManager.signoutRedirect()
const {
    announcementService,
    architectureService,
    applicationService,
    authService,
    databaseService,
    deploymentService,
    filterService,
    insightService,
    modelService,
    moduleService,
    projectService,
    sessionService,
    uploadService,
    userService,
    workspaceService,
} = services({ baseUrls, getAccessKey, onUnauthorized, shouldUseToCamel: true })

export const platformBaseUrls: BaseUrls = {
    platform: window.TADA_APIS.PLATFORM,
}
const { platformService } = services({
    baseUrls: platformBaseUrls,
    getAccessToken: () => getPlatformAuthToken(pca),
    onUnauthorized: () => logOutPlatformUser(pca),
    shouldUseToCamel: true,
})

export {
    announcementService,
    architectureService,
    applicationService,
    authService,
    databaseService,
    deploymentService,
    filterService,
    insightService,
    modelService,
    moduleService,
    platformService,
    projectService,
    sessionService,
    uploadService,
    userService,
    workspaceService,
}
