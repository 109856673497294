import { IconButton, Tooltip } from '@mui/material'
import { SwapHorizontalCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'
import { PLATFORM_BASE, SOLUTION_BASE } from 'src/lib/routePath'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(({ palette }) => ({
    icon: {
        color: palette.text.primary,
    },
}))

interface SwitchAnnouncementModeButtonProps {
    mode: AnnouncementAppMode
}

const SwitchAnnouncementModeButton: React.FC<SwitchAnnouncementModeButtonProps> = ({ mode }) => {
    const classes = useStyles()
    const navigate = useNavigate()

    const handleClick = () => {
        if (mode === AnnouncementAppMode.Platform) {
            navigate(SOLUTION_BASE)
        } else {
            navigate(PLATFORM_BASE)
        }
    }

    const tooltipText =
        mode === AnnouncementAppMode.Platform ? 'Switch to Solution Announcements' : 'Switch to Platform Announcements'

    return (
        <Tooltip title={tooltipText}>
            <IconButton sx={{ marginRight: 1 }} className={classes.icon} onClick={handleClick}>
                <SwapHorizontalCircle />
            </IconButton>
        </Tooltip>
    )
}

export default SwitchAnnouncementModeButton
