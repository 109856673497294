"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Modify directory removing trailing slash and returning '' if root
 * @param directory to clean
 */
var _default = directory => {
  if (directory && directory !== '/') return `/${directory.replace(/^\/|\/$/g, '')}`;
  return '';
};
exports.default = _default;