import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            minHeight: '300px',
        },
    },
}))

const ListBanner: React.FC<{ cloudName?: string; className?: string }> = ({ cloudName, className }) => {
    const classes = useStyles()
    return (
        <Box className={`${classes.container} ${className}`}>
            <Typography
                variant="h2"
                align="center"
                fontWeight="bold"
                sx={{
                    lineHeight: { xs: '2rem', sm: '4rem' },
                    fontSize: { xs: '1.75rem', sm: '3rem' },
                }}
            >
                {`${cloudName || 'Tada Now'}'s Announcements`}
            </Typography>
            <Typography
                variant="h5"
                align="center"
                sx={{
                    marginTop: { xs: '1rem', sm: '2rem' },
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                }}
            >
                {`New updates and improvements to ${cloudName || 'Tada Now'}`}
            </Typography>
        </Box>
    )
}

export default ListBanner
