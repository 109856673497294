import React, { useState, Fragment, useContext } from 'react'
import Avatar from '@mui/material/Avatar'
import withStyles from '@mui/styles/withStyles'
import Popover from '@mui/material/Popover'
import UserMenu from './UserMenu'
import { SolutionAuthContext } from '../../contexts/SolutionAuthContext'
import { Tooltip } from '@mui/material'

const styles = theme => ({
    avatar: {
        width: 27,
        height: 27,
        backgroundColor: theme.palette.secondary.main,
        fontSize: 15,
        marginLeft: 'auto',
        marginRight: 0,
        '&:hover': {
            cursor: 'pointer',
            opacity: '.75',
        },
    },
})

const UserProfile = ({ classes }) => {
    const { user } = useContext(SolutionAuthContext)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const id = Boolean(anchorEl) === true ? 'user-menu' : undefined

    return (
        <Fragment>
            <Tooltip title="Open settings">
                <Avatar className={`${classes.avatar} float`} onClick={handleClick} aria-owns={id} aria-haspopup="true">
                    {user.userId[0].toUpperCase()}
                </Avatar>
            </Tooltip>
            <Popover
                id={id}
                open={Boolean(id)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <UserMenu user={user} />
            </Popover>
        </Fragment>
    )
}

export default withStyles(styles)(UserProfile)
