import React, { useEffect, useState } from 'react'
// TODO: update react-router-dom to v6
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
    Box,
    Step,
    Button,
    Stepper,
    StepLabel,
    TextField,
    Typography,
    StepContent,
    CircularProgress,
} from '@mui/material'

import { ITadaError } from '../../lib/types'
import { authService } from '../../lib/services'
import NewPassword from '../../components/password_reset/NewPassword'
import PasswordDesc from '../../components/password_reset/PasswordDesc'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../assets/logo.png')

const otpMaxLength = 8

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        top: '50%',
        left: '50%',
        width: '50vw',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
    },
    stepper: { maxHeight: '80vh', overflow: 'auto' },
    errorColor: { color: palette.status.error },
    activeStep: { fontWeight: '600 !important' },
    footer: { marginTop: spacing(1) },
    logo: {
        width: '25%',
        height: '25%',
        display: 'block',
        margin: '10px auto',
    },
    stepIcon: { '& .MuiStepIcon-completed': { color: palette.status.success } },
}))

export default function PasswordReset() {
    const classes = useStyles()
    const [step, setStep] = useState(0)
    const [token, setToken] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        username: '',
        otp: '',
        password: '',
        verifyPw: '',
    })
    const { username, otp, password, verifyPw } = data

    const navigate = useNavigate()

    useEffect(() => {
        if (verifyPw && password !== verifyPw) setError('Passwords do not match.')
        else setError('')
    }, [password, verifyPw])

    const handleUsername = async () => {
        try {
            setLoading(true)
            const res = await authService.verifyUsername(username)
            const { token: resToken } = res
            if (token !== resToken) setToken(resToken)
            handleNext()
        } catch (err) {
            const { message } = err as ITadaError
            setError(message)
        } finally {
            setLoading(false)
        }
    }

    const handleOtp = async () => {
        try {
            setLoading(true)
            const res = await authService.verifyOTP(username, token, otp)
            const { token: resToken } = res
            if (token !== resToken) setToken(resToken)
            handleNext()
        } catch (err) {
            const { message } = err as ITadaError
            setError(message)
        } finally {
            setLoading(false)
        }
    }

    const handlePassword = async () => {
        try {
            setLoading(true)
            const res = await authService.verifyNewPassword(username, token, password)
            if (res) {
                await authService.savePassword(token, { UserId: username, Password: password })
                navigate(SOLUTION_ROUTE_PATHS.PASSWORD_RESET_SUCCESS, { state: { username } })
            }
        } catch (err) {
            const { Message } = err as ITadaError
            setError(Message)
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = e
        setData({ ...data, [name]: value })
    }

    const handleNext = () => setStep(prevStep => prevStep + 1)

    //eslint-disable-next-line
    const pwRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*[\s|<|>|%|'|\*|&|\:|\\|\?|/]).{8,}/g

    const steps = [
        {
            label: 'Enter and verify your username',
            description:
                'Enter your username and click Next. You will receive an email with a OTP (one-time password) to verify your account.',
            component: (
                <TextField
                    name="username"
                    value={username}
                    label="Username"
                    variant="outlined"
                    onChange={handleChange}
                    placeholder="Enter username"
                />
            ),
            isValid: !!username,
            onSubmit: handleUsername,
        },
        {
            label: 'Enter and confirm OTP (one-time password) sent to your email',
            description: 'Enter the OTP (one-time password) found in your email.',
            component: (
                <TextField
                    name="otp"
                    value={otp}
                    label="OTP"
                    variant="outlined"
                    placeholder="Enter OTP"
                    onChange={handleChange}
                />
            ),
            isValid: !!otp && otp.length === otpMaxLength,
            onSubmit: handleOtp,
        },
        {
            label: 'Save your new password',
            description: <PasswordDesc password={password} />,
            component: <NewPassword password={password} verifyPw={verifyPw} onChange={handleChange} />,
            isValid: password === verifyPw && pwRegex.test(password),
            onSubmit: handlePassword,
        },
    ]

    return (
        <div className={classes.root}>
            <div>
                <img src={logo} alt="Logo" className={classes.logo} />
            </div>
            <Stepper className={classes.stepper} orientation="vertical" activeStep={step}>
                {steps.map(({ label, description, component, isValid, onSubmit }) => {
                    const isLastStep = step === steps.length - 1
                    return (
                        <Step key={label}>
                            <StepLabel classes={{ active: classes.activeStep, iconContainer: classes.stepIcon }}>
                                {label}
                            </StepLabel>
                            <StepContent>
                                {description}
                                <Box mt={1}>{component}</Box>
                                <div className={classes.footer}>
                                    {error && <Typography className={classes.errorColor}>{error}</Typography>}
                                    <Button
                                        onClick={onSubmit}
                                        disabled={!isValid || loading}
                                        variant={isLastStep ? 'contained' : 'outlined'}
                                    >
                                        {loading ? (
                                            <CircularProgress size={20} color="primary" />
                                        ) : isLastStep ? (
                                            'Save'
                                        ) : (
                                            'Next'
                                        )}
                                    </Button>
                                </div>
                            </StepContent>
                        </Step>
                    )
                })}
            </Stepper>
        </div>
    )
}
