import { createContext, ReactNode, FC, useContext } from 'react'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'

interface PlatformAuthContextProps {
    activeAccount: AccountInfo | null
    authInstance: IPublicClientApplication | null
}

interface AuthProviderProps {
    children: ReactNode
}

const PlatformAuthContext = createContext<PlatformAuthContextProps | undefined>(undefined)

const PlatformAuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const { accounts, instance } = useMsal()
    const activeAccount = useAccount(accounts[0] || {})
    instance.setActiveAccount(activeAccount)

    return (
        <PlatformAuthContext.Provider value={{ activeAccount, authInstance: instance }}>
            {children}
        </PlatformAuthContext.Provider>
    )
}

export { PlatformAuthContext, PlatformAuthProvider }

export const usePlatformAuth = (): PlatformAuthContextProps => {
    const context = useContext(PlatformAuthContext)
    if (!context) {
        return { activeAccount: null, authInstance: null }
    }
    return context
}
