import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import EnvironmentMultiSelect from 'src/components/EnvironmentMultiSelect'
import { usePlatformEnvironments } from 'src/contexts/PlatformEnvironmentsContext'

const StepPlatformScope = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const { environments, isLoading } = usePlatformEnvironments()

    return (
        <Box>
            <Controller
                name="scope"
                control={control}
                rules={{ required: 'Please select at least one environment for the announcement.' }}
                render={({ field }) => (
                    <EnvironmentMultiSelect
                        value={field.value}
                        onChange={field.onChange}
                        environments={environments}
                        isLoading={isLoading}
                        error={!!errors.scope}
                        helperText={'Please select at least one environment for the announcement.'}
                        placeholder="Select Environment(s) for Announcement"
                    />
                )}
            />

            <Controller
                name="isGenerallyAvailable"
                control={control}
                render={({ field }) => (
                    <FormControl margin="normal">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} color="primary" />}
                                label="Make this announcement generally available"
                            />
                        </FormGroup>
                        <Typography variant="body2" color="textSecondary">
                            When checked, this announcement will be visible on the platform announcements page.
                        </Typography>
                    </FormControl>
                )}
            />
        </Box>
    )
}

export default StepPlatformScope
