"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useInterval;
var _react = require("react");
function useInterval(callback, delay) {
  const savedCallback = (0, _react.useRef)(null);
  const savedIntervalId = (0, _react.useRef)(null);
  let intervalId = null;
  const clearSetInterval = () => {
    clearInterval(savedIntervalId.current);
  };
  (0, _react.useEffect)(() => {
    savedCallback.current = callback;
  }, [callback]);
  (0, _react.useEffect)(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      intervalId = setInterval(tick, delay);
      savedIntervalId.current = intervalId;
      return () => clearInterval(intervalId);
    }
  }, [delay]);
  return clearSetInterval;
}