"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getWorkspaces: (baseURL, projectId) => ({
    baseURL,
    method: 'get',
    url: `workspaces`,
    config: {
      params: {
        projectId
      }
    }
  }),
  getWorkspaceById: (projectId, id) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `workspaces/${id}`,
    config: {
      params: {
        projectId
      }
    }
  }),
  createWorkspace: (projectId, name) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `workspaces`,
    data: {
      name
    },
    config: {
      params: {
        projectId
      }
    }
  }),
  changeDefaultWorkspace: (projectId, workspaceId) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `workspaces`,
    config: {
      params: {
        projectId,
        workspaceId
      }
    }
  }),
  deleteWorkspace: (projectId, id) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `workspaces/${id}`,
    config: {
      params: {
        projectId
      }
    }
  }),
  mapSourceToWorkspace: (projectId, id, data) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `workspaces/${id}/dataSources`,
    data: data,
    config: {
      params: {
        projectId
      }
    }
  }),
  getDataContract: (projectId, workspaceId) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `datacontract`,
    config: {
      params: {
        projectId,
        workspaceId
      }
    }
  }),
  executeContract: (projectId, workspaceId, jobId) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `datacontract`,
    data: {},
    config: {
      params: {
        jobId,
        projectId,
        workspaceId
      }
    }
  }),
  addToFromContract: (projectId, id, data) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `workspaces/${id}/dataSources`,
    data: data,
    config: {
      params: {
        projectId
      }
    }
  })
});
exports.default = _default;