import { SolutionAnnouncement } from 'genesis-suite/types/announcementTypes'
import { ReactNode, useState, createContext, useContext } from 'react'
import ANNOUNCEMENTS from 'src/lib/newDb'

interface AnnouncementsContextProps {
    announcements: SolutionAnnouncement[]
    setAnnouncements: (announcements: SolutionAnnouncement[]) => void
    deleteAnnouncement: (id: string) => void
}

const AnnouncementsContext = createContext<AnnouncementsContextProps>({
    announcements: [],
    setAnnouncements: () => void {},
    deleteAnnouncement: () => void {},
})

interface AnnouncementsProviderProps {
    children: ReactNode
}

const AnnouncementsProvider = ({ children }: AnnouncementsProviderProps) => {
    const [announcements, setAnnouncements] = useState<SolutionAnnouncement[]>(ANNOUNCEMENTS)

    const deleteAnnouncement = (id: string) => {
        setAnnouncements(announcements.filter(announcement => announcement.id !== id))
    }

    return (
        <AnnouncementsContext.Provider
            value={{
                announcements,
                setAnnouncements,
                deleteAnnouncement,
            }}
        >
            {children}
        </AnnouncementsContext.Provider>
    )
}

const useAnnouncements = () => useContext(AnnouncementsContext)

export { AnnouncementsContext, AnnouncementsProvider, useAnnouncements }
