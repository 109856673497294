import { muiTheme } from 'genesis-suite/theme'
import { createTheme } from '@mui/material/styles'

const defaultTheme = muiTheme()

const customProperties = {
    custom: {
        ...defaultTheme.custom,
        fieldWidth: 200,
        announcementsApp: {
            maxWidth: 1440,
            paddingBig: 40,
        },
        appMaxWidth: 1440,
    },
}

export const lightTheme = createTheme({
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        mode: 'light',
        primary: {
            main: '#c089df',
        },
        background: {
            default: '#ffffff',
            paper: '#f5f5f5',
        },
        text: {
            primary: '#000000',
        },
    },
    ...customProperties,
})

const darkBackground = '#121212'

export const darkTheme = createTheme({
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        mode: 'dark',
        primary: {
            main: '#c089df',
        },
        background: {
            default: darkBackground,
            paper: '#1d1d1d',
        },
        text: {
            primary: '#ffffff',
        },
    },
    ...customProperties,
})
