"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BackupJobStatus = void 0;
exports.capitalizeFirstLetter = capitalizeFirstLetter;
exports.capitalizeKeys = capitalizeKeys;
exports.configDataParams = configDataParams;
exports.configParams = configParams;
exports.pathToPublicFile = pathToPublicFile;
const baseUrl = process.env.PUBLIC_URL || '/';
function pathToPublicFile(file) {
  return `${baseUrl}/common/${file}`;
}
const BackupJobStatus = exports.BackupJobStatus = {
  0: 'Initialized',
  1: 'InProgress',
  2: 'Completed',
  3: 'Failed'
};
function configParams(obj, backupId) {
  if (backupId) {
    obj.backupVersionId = backupId;
  }
  return obj;
}
function configDataParams(obj, backupId, appId) {
  if (backupId) {
    obj.backupVersionId = backupId;
  }
  if (appId) {
    obj.modelserviceId = appId;
  }
  return obj;
}
function capitalizeFirstLetter(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function capitalizeKeys(obj) {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[capitalizedKey] = obj[key];
    }
  }
  return result;
}