"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  createRawSource: (projectId, datasourceData, workSpaceId, sessionKey) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `rawdatasources`,
    data: datasourceData,
    config: {
      params: {
        projectId,
        workSpaceId,
        accessKey: sessionKey
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }),
  updateRawSource: (projectId, data, name, sessionKey) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `rawdatasources/${name}`,
    data,
    config: {
      params: {
        projectId,
        accessKey: sessionKey
      }
    }
  }),
  getRawDataSources: (projectId, sessionKey) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `rawdatasources`,
    config: {
      params: {
        accessKey: sessionKey,
        projectId
      }
    }
  }),
  deleteRawDataSource: (id, projectId, jobId) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `rawdatasources`,
    config: {
      params: {
        id,
        projectId,
        jobId
      }
    }
  }),
  getRawDataSourceTables: (projectId, datasourcename, sessionKey) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `rawsourcetables`,
    config: {
      params: {
        accessKey: sessionKey,
        rawSourceName: datasourcename,
        projectId
      }
    }
  }),
  getSourceInterfaces: (projectId, sessionKey) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sourceInterfaces`,
    config: {
      params: {
        projectId,
        accessKey: sessionKey
      }
    }
  }),
  getSourceInterfaceByName: (projectId, name, sessionKey) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sourceInterfaces/${name}`,
    config: {
      params: {
        projectId,
        accessKey: sessionKey
      }
    }
  }),
  createSourceInterface: (projectId, workSpaceId, data, sessionKey) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `sourceInterfaces`,
    data,
    config: {
      params: {
        projectId,
        workSpaceId,
        accessKey: sessionKey
      }
    }
  }),
  deleteSourceInterface: (projectId, dataSourceName) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `sourceInterfaces/${dataSourceName}`,
    config: {
      params: {
        projectId
      }
    }
  }),
  getDependencies: (projectId, sourceInterfaceName) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `sourceInterfaces`,
    config: {
      params: {
        projectId,
        sourceInterfaceName
      }
    }
  }),
  getDataSourceById: (projectId, dataSourceName) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `rawdatasources/${dataSourceName}`,
    config: {
      params: {
        projectId
      }
    }
  }),
  importSourceTables: (projectId, dataSourceName, datasourceImport) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `rawdatasources/${dataSourceName}/import`,
    data: datasourceImport,
    config: {
      params: {
        projectId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }),
  mapDataSourceToWorkSpace: (projectId, dataSourceName, jobId, workspaceId) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `rawdatasources/${dataSourceName}/maptoworkspace`,
    data: {
      workspaceId,
      jobId
    },
    config: {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        projectId
      }
    }
  }),
  addExistingTableToDataSource: (projectId, dataSourceName, {
    workspaceId,
    jobId
  }) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `rawdatasources/${dataSourceName}/saveas`,
    data: {
      projectId,
      workspaceId,
      jobId
    },
    config: {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }),
  getSharepointContent: (connectionInfo, folderName) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `rawdatasources/sharepoint/contents`,
    data: connectionInfo,
    config: {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        folderName
      }
    }
  }),
  testConnection: data => ({
    baseURL: orgWeb,
    method: 'post',
    url: `rawdatasources/test/`,
    data: data
  })
});
exports.default = _default;