import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { Palette } from '@mui/icons-material';

const tagColors = ['default', 'primary', 'info', 'success'];

export interface TagsData {
  text: string;
  color: string;
}

interface InputTagsProps {
  initialValue?: TagsData[];
  onChange?: (chips: TagsData[]) => void;
  onBlur?: () => void;
  label?: string;
  maxTags?: number;
}

const InputTags: React.FC<InputTagsProps> = ({ initialValue = [], onChange, onBlur, label, maxTags = 5 }) => {
  const [tags, setTags] = useState<TagsData[]>(initialValue);
  const [textInput, setTextInput] = useState('');
  const [colorInput, setColorInput] = useState<string>('default');
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    onChange?.(tags);
  }, [tags, onChange]);

  const handleAddChip = () => {
    if (textInput && colorInput) {
      if (tags.length >= maxTags) {
        setError(`Not more than ${maxTags} tags are allowed`);
      } else {
        const newChip = { text: textInput, color: colorInput };
        setTags(prevTags => [...prevTags, newChip]);
        setError('');
      }
      setTextInput('');
    }
  };

  const handleDeleteChip = (chipToDelete: TagsData) => {
    setTags(prevTags => prevTags.filter(tag => tag !== chipToDelete));
    setError('');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  const handleColorToggle = () => {
    const currentIndex = tagColors.indexOf(colorInput);
    const nextIndex = (currentIndex + 1) % tagColors.length;
    setColorInput(tagColors[nextIndex]);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'y') {
      event.preventDefault();
      handleColorToggle();
    }

    // Enter to add a chip
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddChip();
    }
  };

  const isMaxTagsReached = tags.length >= maxTags;

  return (
    <Box>
      {label && (
        <Typography variant="body1" gutterBottom>
          {label}
        </Typography>
      )}
      <Box
        padding={2}
        border={isFocused ? '2px solid' : '1px solid'}
        borderColor={isFocused ? 'primary.main' : 'grey.400'}
        borderRadius={2}
        role="group"
        aria-label="Tag input area"
      >
        <Box display="flex" alignItems="center" flexWrap="wrap" aria-live="polite">
          {tags.length === 0 && textInput === '' && (
            <Typography variant="body1" color="textSecondary">
              No tags added yet.
            </Typography>
          )}
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag.text}
              color={tag.color as 'default' | 'primary' | 'info' | 'success'}
              onDelete={() => handleDeleteChip(tag)}
              sx={{ marginRight: 2 }}
              aria-label={`Tag: ${tag.text}, color: ${tag.color}. Press delete to remove.`}
            />
          ))}
          {textInput && !isMaxTagsReached && (
            <Chip
              label={textInput}
              color={colorInput as 'default' | 'primary' | 'info' | 'success' || 'default'}
              sx={{ marginRight: 1, opacity: 0.5 }}
              aria-label={`Preview of new tag: ${textInput}, color: ${colorInput}`}
            />
          )}
        </Box>
        <Box display="flex" gap={1} alignItems="center" marginY={1}>
          <TextField
            placeholder={isMaxTagsReached ? `Not more than ${maxTags} tags are allowed` : "Enter a tag..."}
            value={isMaxTagsReached ? '' : textInput}
            onChange={e => setTextInput(e.target.value)}
            variant="outlined"
            sx={{ flexGrow: 1 }}
            inputProps={{ maxLength: 20, 'aria-label': 'Tag input' }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown} 
            disabled={isMaxTagsReached}
          />
          <IconButton
            size="large"
            disabled={!textInput || isMaxTagsReached}
            onClick={handleColorToggle}
            aria-label="Toggle tag color"
          >
            <Tooltip title="Toggle tag color (Ctrl + Y)">
              <Palette sx={{ color: colorInput === 'default' ? 'grey' : `${colorInput}.main` }}/>
            </Tooltip>
          </IconButton>
          <Button
            sx={{ height: '54px', width: '100px', fontWeight: 'bold' }}
            variant="contained"
            onClick={handleAddChip}
            disabled={!textInput || !colorInput || isMaxTagsReached}
            aria-label="Add tag"
          >
            Add
          </Button>
        </Box>
        {error && (
          <Box color="error.main" marginY={1} role="alert" aria-live="assertive">
            {error}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InputTags;
