import { Box, Step as MuiStep, StepLabel, Stepper as MuiStepper, SxProps, Theme } from '@mui/material'
import React, { useState } from 'react'
import Step from './Step'
import { FormProvider, useForm } from 'react-hook-form'
import StepTypeAndScope from './StepTypeAndScope'
import StepDetails from './StepDetails'
import StepPublishDateAndTime from './StepPublishDateAndTime'
import { useSnackbar } from 'notistack'
import { TagsData } from 'src/components/InputTags'
import {
    SolutionAnnouncement,
    AnnouncementAttachment,
    SolutionAnnouncementScope,
    SolutionAnnouncementType,
} from 'genesis-suite/types/announcementTypes'
import { App, Cloud } from 'src/types'

interface CreateAnnouncementStepperProps {
    activeCloud: Cloud | null
    activeApplication: App | null
    children?: React.ReactNode
    sx?: SxProps<Theme>
    isDisabled?: boolean
    initialData?: SolutionAnnouncement | null
    onDraft?: (_a: SolutionAnnouncement) => void
    onFinish?: (_a: SolutionAnnouncement) => void
}

export interface AnnouncementFormData {
    type: string
    scope: string
    cloudId: string
    applicationId: string
    portalId: string
    whitelistedUserIds: string[]
    title: string
    description: string
    attachments: AnnouncementAttachment[]
    startDate: Date
    endDate: Date | null
    bannerMedia: AnnouncementAttachment | null
    tags: TagsData[]
}

const Stepper: React.FC<CreateAnnouncementStepperProps> = ({
    activeCloud = null,
    activeApplication = null,
    sx,
    onDraft = (_a: SolutionAnnouncement) => void {},
    onFinish = (_a: SolutionAnnouncement) => void {},
    isDisabled = false,
    initialData = null,
}) => {
    const [activeStep, setActiveStep] = useState(0)
    const methods = useForm<AnnouncementFormData>({
        defaultValues: {
            type: initialData?.type || 'feature',
            scope: initialData?.scope || 'application',
            cloudId: initialData?.cloudId || activeCloud?.id || '',
            applicationId: initialData?.applicationId || activeApplication?.id || '',
            portalId: initialData?.portalId || '',
            whitelistedUserIds: initialData?.whitelistedUserIds || [],
            title: initialData?.title || '',
            description: initialData?.description || '',
            attachments: initialData?.attachments || [],
            startDate: initialData ? new Date(initialData.startDate) : new Date(),
            endDate: initialData ? new Date(initialData.endDate || '') : null,
            bannerMedia: initialData?.bannerMedia || null,
            tags: initialData?.tags || [],
        },
    })
    const steps = ['Type & Scope', 'Details', 'Publish Date & Time']
    const { enqueueSnackbar } = useSnackbar()

    const attachments = methods.watch('attachments')
    const bannerMedia = methods.watch('bannerMedia')
    const isUploading = () => {
        return attachments?.some(a => a.uploading) || bannerMedia?.uploading
    }
    const onNext = methods.handleSubmit(() => {
        if (activeStep === 1 && isUploading()) {
            enqueueSnackbar('Please, wait for the file upload to finish.', { variant: 'warning' })
            return
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    })
    const onBack = () => {
        if (activeStep === 1 && isUploading()) {
            enqueueSnackbar('Please, wait for the file upload to finish.', { variant: 'warning' })
            return
        }
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const onHandleDraft = methods.handleSubmit(data => {
        const newAnnouncement = getAnnouncementFromFormData(data, initialData)
        newAnnouncement.isDraft = true
        onDraft(newAnnouncement)
    })

    const onSubmit = methods.handleSubmit(data => {
        const newAnnouncement = getAnnouncementFromFormData(data, initialData)
        newAnnouncement.isDraft = false
        onFinish(newAnnouncement)
    })

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <StepTypeAndScope activeCloud={activeCloud} isUpdating={!!(initialData && !initialData.isDraft)} />
                )
            case 1:
                return <StepDetails activeCloud={activeCloud} />
            case 2:
                return <StepPublishDateAndTime />
            default:
                return null
        }
    }

    return (
        <FormProvider {...methods}>
            <Box sx={sx}>
                <MuiStepper activeStep={activeStep}>
                    {steps.map(label => (
                        <MuiStep key={label}>
                            <StepLabel>{label}</StepLabel>
                        </MuiStep>
                    ))}
                </MuiStepper>

                {steps.map((step, i) =>
                    activeStep === i ? (
                        <Step
                            key={step}
                            step={activeStep}
                            isFinalStep={i === steps.length - 1}
                            isUpdating={!!initialData}
                            isDraft={initialData?.isDraft}
                            handleNext={onNext}
                            handleBack={onBack}
                            handleDraft={onHandleDraft}
                            handleSubmit={onSubmit}
                            disabled={isDisabled}
                        >
                            {renderStepContent(i)}
                        </Step>
                    ) : null
                )}
            </Box>
        </FormProvider>
    )
}

export default Stepper

export const getAnnouncementFromFormData = (
    formData: AnnouncementFormData,
    initialData?: SolutionAnnouncement | null
): SolutionAnnouncement => {
    const newAnnouncement: SolutionAnnouncement = {
        type: formData.type as SolutionAnnouncementType,
        scope: formData.scope as SolutionAnnouncementScope,
        cloudId: formData.cloudId,
        applicationId: formData.applicationId,
        portalId: formData.portalId,
        whitelistedUserIds: formData.whitelistedUserIds,
        title: formData.title,
        description: formData.description,
        attachments: formData.attachments.map(a => ({ name: a.name, type: a.type, token: a.token })),
        startDate:
            formData.startDate === null || isNaN(new Date(formData.startDate).getTime())
                ? new Date().toISOString()
                : formData.startDate.toISOString(),
        endDate:
            formData.endDate === null || isNaN(new Date(formData.endDate).getTime())
                ? null
                : formData.endDate?.toISOString(),
        tags: formData.tags,
        bannerMedia: formData.bannerMedia
            ? {
                  name: formData.bannerMedia.name,
                  type: formData.bannerMedia.type,
                  token: formData.bannerMedia.token,
              }
            : null,
        isDraft: false,
    }
    if (initialData && initialData.id) newAnnouncement.id = initialData.id
    return newAnnouncement
}
