"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  createPerspectiveURL: (moduleId, appName, viewType, viewId, viewName, modelId, cloudId, cloudName, contextFilters, isPublic, targetURL, codeType) => ({
    baseURL: integration,
    method: 'post',
    url: `accesscodes`,
    data: {
      cloudId,
      cloudName,
      contextFilters,
      modelId,
      modelName: appName,
      name: viewName,
      sourceId: viewId,
      sourceParentId: moduleId,
      viewSourceType: viewType,
      isPublic,
      targetURL,
      codeType
    }
  }),
  getPerspectiveURLById: id => ({
    baseURL: integration,
    method: 'get',
    url: `accesscodes/${id}`
  }),
  getAll: (application, basic, allDetails) => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/visors`,
    config: {
      params: {
        modelServiceName: application,
        basic,
        allDetails
      }
    }
  }),
  getLogo: (application, visorName) => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/visorlogo`,
    config: {
      params: {
        modelServiceName: application,
        visorName
      },
      responseType: 'blob'
    }
  }),
  getByName: (application, visorName, basic) => ({
    baseURL: integration,
    method: 'get',
    url: `visors/${visorName}`,
    config: {
      params: {
        modelServiceName: application,
        basic
      }
    }
  }),
  update: (application, moduleId, data) => ({
    baseURL: integration,
    method: 'patch',
    url: `visors`,
    config: {
      params: {
        modelServiceName: application,
        visorId: moduleId
      }
    },
    data
  }),
  saveTheme: (application, moduleId, themeObject) => ({
    baseURL: integration,
    method: 'put',
    url: `visors/theme`,
    data: themeObject,
    config: {
      params: {
        modelServiceName: application,
        visorId: moduleId
      }
    }
  }),
  getFiles: fileToken => ({
    baseURL: integration,
    method: 'get',
    url: `files`,
    config: {
      params: {
        fileToken
      },
      responseType: 'blob'
    }
  }),
  getAttachment: fileToken => ({
    baseURL: integration,
    method: 'get',
    url: `files`,
    config: {
      params: {
        fileToken
      },
      responseType: 'blob'
    },
    returnBody: false
  }),
  updateAppOptions: data => ({
    baseURL: integration,
    method: 'post',
    url: 'visors/appOptions',
    data
  })
});
exports.default = _default;