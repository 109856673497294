"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  orgWeb
}) => generator({
  getTableInterfaces: (projectId, sourceInterfaceName) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `tableInterfaces`,
    config: {
      params: {
        projectId,
        sourceInterfaceName
      }
    }
  }),
  createTableInterface: (projectId, sourceInterfaceName, data, jobId) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `tableInterfaces`,
    data,
    config: {
      params: {
        projectId,
        sourceInterfaceName,
        jobId
      }
    }
  }),
  deleteTableInterface: (projectId, sourceInterfaceName, tableName) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `tableInterfaces/${tableName}`,
    config: {
      params: {
        projectId,
        sourceInterfaceName
      }
    }
  }),
  updateTableInterface: (projectId, sourceInterfaceName, tableInterfaceName, data, jobId) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `tableInterfaces/${tableInterfaceName}`,
    data,
    config: {
      params: {
        projectId,
        sourceInterfaceName,
        jobId
      }
    }
  }),
  validateTableInterface: data => ({
    baseURL: orgWeb,
    method: 'post',
    url: `tableInterfaces/validate`,
    data
  }),
  refreshTableInterfaces: (sourceInterfaceName, tableInterfaces, jobId, projectId) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `tableInterfaces/data`,
    data: [{
      jobId,
      sourceInterfaceName,
      tableInterfaces
    }],
    config: {
      params: {
        projectId
      }
    }
  }),
  getDependencies: (projectId, tableInterfaceName, sourceInterfaceName) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `tableInterfaces/dependencies`,
    config: {
      params: {
        projectId,
        tableInterfaceName,
        sourceInterfaceName
      }
    }
  }),
  getData: (projectId, tableInterfaceName, sourceId, pageIndex, pageSize) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `tableInterfaces/${tableInterfaceName}/data`,
    config: {
      params: {
        projectId,
        sourceId,
        pageIndex,
        pageSize
      }
    }
  })
});
exports.default = _default;