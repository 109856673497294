import React, { useContext, useEffect } from 'react'
import { Container, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { CloudsContext } from 'src/contexts/CloudsContext'
import { useSnackbar } from 'notistack'
import Loading from 'src/components/Loading'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

const CloudsGuard = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
    const { cloudName = null } = useParams()
    const { clouds, isLoadingClouds, isInitialised: isCloudsInitialised } = useContext(CloudsContext)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const activeCloud = clouds?.find(c => c.name === cloudName) || null

    useEffect(() => {
        if (!isCloudsInitialised || isLoadingClouds) return
        if (!clouds || clouds.length === 0) return
        if (!activeCloud) {
            enqueueSnackbar('Invalid cloud name detected in the URL!', { variant: 'error' })
            navigate(SOLUTION_ROUTE_PATHS.SELECT)
        }
    }, [isCloudsInitialised, isLoadingClouds, clouds, activeCloud])

    if (!isCloudsInitialised || isLoadingClouds) return <Loading message="Getting things ready for you..." />
    if (!clouds || clouds.length === 0) {
        return (
            <Container
                sx={{
                    height: '100%',
                    my: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6">
                    You do not have access to any clouds at the moment. Please contact your administrator to request
                    access.
                </Typography>
            </Container>
        )
    }
    if (!activeCloud) {
        return (
            <Container
                sx={{
                    height: '100%',
                    my: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6">Invalid cloud name detected in the URL!</Typography>
            </Container>
        )
    }

    return React.cloneElement(children, { activeCloud })
}

export default CloudsGuard
