"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _announcementUtils = require("../../utils/announcementUtils");
// Adjust path as necessary
var _default = (generator, {
  integration
}) => {
  return generator({
    getAnnouncements: data => ({
      baseURL: integration,
      method: 'post',
      url: 'Announcement/Fetch',
      data,
      then: res => {
        const announcements = res['Announcements'].map(_announcementUtils.transformAnnouncementResponse);
        const portalInfo = res['Portals'] || null;
        const portalInfoArray = portalInfo ? Object.keys(portalInfo).map(id => ({
          portalId: id,
          announcementsCount: portalInfo[id]
        })) : [];
        return {
          announcements: announcements,
          portalInfo: portalInfoArray
        };
      }
    }),
    getAnnouncementById: data => ({
      baseURL: integration,
      method: 'get',
      url: 'Announcement/FetchById',
      config: {
        params: data
      },
      then: res => {
        const announcement = (0, _announcementUtils.transformAnnouncementResponse)(res);
        return announcement;
      }
    }),
    getActiveAnnouncements: data => ({
      baseURL: integration,
      method: 'get',
      url: 'Announcement/FetchTodaysMaintenanceAnnouncements',
      config: {
        params: data
      },
      then: res => {
        const announcements = res.map(_announcementUtils.transformAnnouncementResponse);
        return announcements;
      }
    }),
    getDrafts: data => ({
      baseURL: integration,
      method: 'post',
      url: 'Announcement/Fetch',
      data: {
        ...data,
        LoadDraft: true
      },
      then: res => {
        const announcements = res['Announcements'].map(_announcementUtils.transformAnnouncementResponse);
        return announcements;
      }
    }),
    createAnnouncement: data => {
      const apiData = (0, _announcementUtils.transformAnnouncementToApiData)(data);
      return {
        baseURL: integration,
        method: 'post',
        url: 'Announcement/Create',
        data: apiData,
        then: res => {
          const announcement = (0, _announcementUtils.transformAnnouncementResponse)(res);
          return announcement;
        }
      };
    },
    updateAnnouncement: data => {
      const apiData = (0, _announcementUtils.transformAnnouncementToApiData)(data);
      return {
        baseURL: integration,
        method: 'put',
        url: 'Announcement/Update',
        data: apiData,
        then: res => {
          return true;
        }
      };
    },
    deleteAnnouncement: (id, cloudId) => ({
      baseURL: integration,
      method: 'delete',
      url: 'Announcement/Delete',
      config: {
        params: {
          announcementId: id,
          cloudId
        }
      }
    })
  });
};
exports.default = _default;