import React, { useState } from 'react'
import {
    Card,
    CardHeader,
    CardContent,
    Collapse,
    List,
    ListItem,
    Typography,
    IconButton,
    CircularProgress,
    Box,
} from '@mui/material'
import { AnnouncementTile } from 'genesis-suite/components'
import { AnnouncementAppMode, PlatformAnnouncement, SolutionAnnouncement } from 'genesis-suite/types/announcementTypes'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DraftsIcon from '@mui/icons-material/Drafts'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { PLATFORM_ROUTE_PATHS, SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

interface DraftAnnouncementsProps {
    drafts: SolutionAnnouncement[] | PlatformAnnouncement[]
    isLoading?: boolean
}

const ExpandMore = styled((props: { expand: boolean } & React.ComponentProps<typeof IconButton>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

const DraftAnnouncements: React.FC<DraftAnnouncementsProps> = ({ drafts, isLoading = false }) => {
    const [expanded, setExpanded] = useState(false)
    const { cloudName, applicationName } = useParams()
    const navigate = useNavigate()

    if (drafts.length === 0) return null

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const onDraftClick = (announcement: SolutionAnnouncement | PlatformAnnouncement) => {
        if (announcement.mode === AnnouncementAppMode.Platform) {
            navigate(PLATFORM_ROUTE_PATHS.UPDATE, {
                state: { announcement },
            })
        } else {
            navigate(SOLUTION_ROUTE_PATHS.UPDATE_ANNOUNCEMENT_APP(cloudName || '', applicationName || ''), {
                state: { announcement },
            })
        }
    }
    return (
        <Card sx={{ mt: 4 }}>
            <CardHeader
                avatar={<DraftsIcon />}
                title={
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Drafts
                    </Typography>
                }
                onClick={handleExpandClick}
                sx={{ cursor: 'pointer' }}
                action={
                    <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
                        <ExpandMoreIcon />
                    </ExpandMore>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {/* Show loading indicator if isLoading is true */}
                    {isLoading ? (
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <List sx={{ width: '100%' }}>
                            {drafts.map(draft => (
                                <ListItem key={draft.id} sx={{ padding: '8px 0', width: '100%' }}>
                                    <AnnouncementTile
                                        announcement={draft}
                                        actionButtonText="Edit"
                                        onActionClick={() => onDraftClick(draft)}
                                        sx={{ width: '100%', mt: 0 }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default DraftAnnouncements
