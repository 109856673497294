import { Box, Step as MuiStep, StepLabel, Stepper as MuiStepper, SxProps, Theme } from '@mui/material'
import React, { useMemo, useState } from 'react'
import Step from './Step'
import { FormProvider, useForm } from 'react-hook-form'
import StepDetails from './StepDetails'
import StepPublishDateAndTime from './StepPublishDateAndTime'
import { useSnackbar } from 'notistack'
import { TagsData } from 'src/components/InputTags'
import {
    PlatformAnnouncement,
    AnnouncementAttachment,
    AnnouncementAppMode,
} from 'genesis-suite/types/announcementTypes'
import StepPlatformScope from './StepPlatformScope'

interface PlatformAnnouncementStepperProps {
    children?: React.ReactNode
    sx?: SxProps<Theme>
    isDisabled?: boolean
    initialData?: PlatformAnnouncement | null
    onDraft?: (_a: PlatformAnnouncement) => void
    onFinish?: (_a: PlatformAnnouncement) => void
}

export interface PlatformAnnouncementFormData {
    scope: string[]
    isGenerallyAvailable: boolean
    title: string
    description: string
    attachments: AnnouncementAttachment[]
    startDate: Date
    tags: TagsData[]
}

const PlatformStepper: React.FC<PlatformAnnouncementStepperProps> = ({
    sx,
    onDraft = (_a: PlatformAnnouncement) => void {},
    onFinish = (_a: PlatformAnnouncement) => void {},
    isDisabled = false,
    initialData = null,
}) => {
    const [activeStep, setActiveStep] = useState(0)
    const methods = useForm<PlatformAnnouncementFormData>({
        defaultValues: {
            scope: initialData?.scope || [],
            isGenerallyAvailable: initialData?.isGenerallyAvailable || false,
            title: initialData?.title || '',
            description: initialData?.description || '',
            attachments: initialData?.attachments || [],
            startDate: initialData ? new Date(initialData.startDate) : new Date(),
            tags: initialData?.tags || [],
        },
    })

    const steps = useMemo(() => {
        if (initialData === null) return ['Scope', 'Details', 'Publish Date & Time']
        const startDate = new Date(initialData.startDate)
        const now = new Date()
        const hasAnnouncementStarted = startDate <= now
        return hasAnnouncementStarted ? ['Scope'] : ['Scope', 'Details', 'Publish Date & Time']
    }, [initialData])
    const { enqueueSnackbar } = useSnackbar()

    const attachments = methods.watch('attachments')
    const isUploading = () => {
        return attachments?.some(a => a.uploading)
    }
    const onNext = methods.handleSubmit(() => {
        if (activeStep === 1 && isUploading()) {
            enqueueSnackbar('Please wait for the file upload to finish.', { variant: 'warning' })
            return
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    })
    const onBack = () => {
        if (activeStep === 1 && isUploading()) {
            enqueueSnackbar('Please wait for the file upload to finish.', { variant: 'warning' })
            return
        }
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const onHandleDraft = methods.handleSubmit(data => {
        const newAnnouncement = getPlatformAnnouncementFromFormData(data, initialData)
        newAnnouncement.isDraft = true
        onDraft(newAnnouncement)
    })

    const onSubmit = methods.handleSubmit(data => {
        const newAnnouncement = getPlatformAnnouncementFromFormData(data, initialData)
        newAnnouncement.isDraft = false
        onFinish(newAnnouncement)
    })

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <StepPlatformScope />
            case 1:
                return <StepDetails mode={AnnouncementAppMode.Platform} activeCloud={null} />
            case 2:
                return <StepPublishDateAndTime />
            default:
                return null
        }
    }

    return (
        <FormProvider {...methods}>
            <Box sx={sx}>
                <MuiStepper activeStep={activeStep}>
                    {steps.map(label => (
                        <MuiStep key={label}>
                            <StepLabel>{label}</StepLabel>
                        </MuiStep>
                    ))}
                </MuiStepper>

                {steps.map((step, i) =>
                    activeStep === i ? (
                        <Step
                            key={step}
                            step={activeStep}
                            isFinalStep={i === steps.length - 1}
                            isUpdating={!!initialData}
                            isDraft={initialData?.isDraft}
                            handleNext={onNext}
                            handleBack={onBack}
                            handleDraft={onHandleDraft}
                            handleSubmit={onSubmit}
                            disabled={isDisabled}
                        >
                            {renderStepContent(i)}
                        </Step>
                    ) : null
                )}
            </Box>
        </FormProvider>
    )
}

export default PlatformStepper

export const getPlatformAnnouncementFromFormData = (
    formData: PlatformAnnouncementFormData,
    initialData?: PlatformAnnouncement | null
): PlatformAnnouncement => {
    const newAnnouncement: PlatformAnnouncement = {
        scope: formData.scope,
        isGenerallyAvailable: formData.isGenerallyAvailable,
        title: formData.title,
        description: formData.description,
        attachments: formData.attachments.map(a => ({ name: a.name, type: a.type, token: a.token })),
        startDate:
            formData.startDate === null || isNaN(new Date(formData.startDate).getTime())
                ? new Date().toISOString()
                : formData.startDate.toISOString(),
        tags: formData.tags,
        isDraft: false,
        mode: AnnouncementAppMode.Platform,
    }
    if (initialData && initialData.id) newAnnouncement.id = initialData.id
    return newAnnouncement
}
