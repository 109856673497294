"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = makeServiceGenerator;
var _axios = _interopRequireDefault(require("axios"));
var _toCamel = _interopRequireDefault(require("../utils/toCamel"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function makeServiceGenerator(instance, shouldUseToCamel) {
  return function serviceGenerator(service) {
    return Object.keys(service).reduce((acc, cur) => {
      const endpoint = service[cur];
      //@ts-ignore
      return {
        ...acc,
        [cur]: makeEndpoint(instance, endpoint, shouldUseToCamel)
      };
    }, {});
  };
}
const makeEndpoint = (instance, endpoint, shouldUseToCamel) => (...args) => {
  const {
    baseURL,
    method,
    url,
    data,
    config,
    then,
    returnBody = true,
    noAccessToken = false
  } = endpoint(...args);
  if (!baseURL) return Promise.reject(new Error('Missing api url'));
  const rootConfig = {
    baseURL,
    noAccessToken
  };
  if (args && typeof args[args.length - 1] === 'function') {
    const callback = args.pop();
    rootConfig.cancelToken = new _axios.default.CancelToken(cancel => callback(cancel));
  }
  const combinedConfig = {
    ...rootConfig,
    ...config
  };
  switch (method) {
    case 'get':
    case 'head':
      return instance[method](url, combinedConfig).then(res => returnBody ? res.data : res).then(then).then(res => shouldUseToCamel ? (0, _toCamel.default)(res) : res);
    case 'post':
    case 'put':
    case 'patch':
      return instance[method](url, data, combinedConfig).then(res => returnBody ? res.data : res).then(then).then(res => shouldUseToCamel ? (0, _toCamel.default)(res) : res);
    //https://github.com/axios/axios/issues/3131
    case 'delete':
      return instance[method](url, {
        ...combinedConfig,
        data
      }).then(res => returnBody ? res.data : res).then(then).then(res => shouldUseToCamel ? (0, _toCamel.default)(res) : res);
  }
};