"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  message
}) => generator({
  sendMessage: data => ({
    baseURL: message,
    method: 'post',
    url: `messages/send`,
    data: data
  }),
  getChats: ({
    domainId,
    userId,
    page,
    pageSize
  }) => ({
    baseURL: message,
    method: 'get',
    url: `messages/chats`,
    config: {
      params: {
        domainId,
        userId,
        page,
        pageSize
      }
    }
  }),
  getChatMessages: ({
    domainId,
    receiver,
    sender,
    page,
    pageSize,
    loadOnlyUnread
  }) => ({
    baseURL: message,
    method: 'get',
    url: `messages`,
    config: {
      params: {
        domainId,
        receiver,
        sender,
        page,
        pageSize,
        loadOnlyUnread
      }
    }
  })
});
exports.default = _default;