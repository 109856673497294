"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration,
  orgWeb
}) => generator({
  uploadFile: (data, fileName, modelName, setExpiration) => ({
    baseURL: integration,
    method: 'post',
    url: 'files',
    data,
    config: {
      params: {
        modelName,
        fileName,
        setExpiration
      }
    }
  }),
  file: (data, sessionKey) => ({
    baseURL: orgWeb,
    method: 'post',
    url: `uploads`,
    data,
    config: {
      params: {
        accessKey: sessionKey
      }
    }
  })
});
exports.default = _default;