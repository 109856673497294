/**
 * Determine if current user is an admin in current context
 * @param {user object} user
 * @param {string} context current cloud/context
 */
export const isAdmin = (user, context) => {
    if (!user || !context) return false

    const userRole = user.permissions[context]
    return userRole === 5 || userRole === 6
}

export default {
    1: 'User',
    4: 'Super User',
    5: 'Admin',
    6: 'Owner',
}
