import { CloudsProvider } from '../contexts/CloudsContext'
import { AnnouncementsProvider } from '../contexts/AnnouncementsContext'
import { CssBaseline } from '@mui/material'
import { SolutionAuthProvider } from 'src/contexts/SolutionAuthContext'

const SolutionProviders = ({ children }) => {
    return (
        <SolutionAuthProvider>
            <CloudsProvider>
                <AnnouncementsProvider>
                    <CssBaseline />
                    {children}
                </AnnouncementsProvider>
            </CloudsProvider>
        </SolutionAuthProvider>
    )
}

export default SolutionProviders
