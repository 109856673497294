"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  domainregistryapi
}) => generator({
  getCompanies: () => ({
    baseURL: domainregistryapi,
    method: 'get',
    url: `companies`
  }),
  getDomains: () => ({
    baseURL: domainregistryapi,
    method: 'get',
    url: `domains`
  }),
  getDomainsByCompanyId: companyId => ({
    baseURL: domainregistryapi,
    method: 'get',
    url: `domains`,
    config: {
      params: {
        companyId
      }
    }
  }),
  saveCompany: company => ({
    baseURL: domainregistryapi,
    method: 'post',
    url: `companies`,
    data: company
  }),
  updateCompany: company => ({
    baseURL: domainregistryapi,
    method: 'put',
    url: `companies/${company?.id}`,
    data: company
  }),
  deleteCompany: companyId => ({
    baseURL: domainregistryapi,
    method: 'delete',
    url: `companies/${companyId}`
  })
});
exports.default = _default;