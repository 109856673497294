"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usePreventClose;
var _react = require("react");
/** Prevent user from refreshing/closing browser tab */
function usePreventClose(enabled) {
  (0, _react.useEffect)(() => {
    if (enabled) window.addEventListener('beforeunload', callback);else window.removeEventListener('beforeunload', callback);
    return () => window.removeEventListener('beforeunload', callback);
  }, [enabled]);
}
function callback(e) {
  e.preventDefault();
  return e.returnValue = "You didn't save. Are you sure you want to leave?";
}