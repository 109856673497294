"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _announcementTypes = require("../../types/announcementTypes");
var _announcementUtils = require("../../utils/announcementUtils");
var _default = (generator, {
  platform
}) => {
  return generator({
    getPlatformAttachment: fileToken => ({
      baseURL: platform,
      method: 'get',
      url: `File/id/${fileToken}`,
      config: {
        responseType: 'blob'
      },
      returnBody: false,
      noAccessToken: true
    }),
    uploadFile: (data, fileName, setExpiration) => ({
      baseURL: platform,
      method: 'post',
      url: 'File',
      data,
      config: {
        params: {
          fileName,
          setExpiration
        }
      }
    }),
    getAnnouncements: data => ({
      baseURL: platform,
      method: 'post',
      url: '/Announcement/Fetch',
      data: data || {},
      then: res => {
        const announcements = res['announcements'].map(announcement => (0, _announcementUtils.transformAnnouncementResponse)(announcement, _announcementTypes.AnnouncementAppMode.Platform));
        return {
          announcements: announcements
        };
      },
      noAccessToken: true
    }),
    getDrafts: () => ({
      baseURL: platform,
      method: 'post',
      url: '/Announcement/FetchDraft',
      then: res => {
        const announcements = res['announcements'].map(announcement => (0, _announcementUtils.transformAnnouncementResponse)(announcement, _announcementTypes.AnnouncementAppMode.Platform));
        return announcements;
      }
    }),
    getAnnouncementById: data => ({
      baseURL: platform,
      method: 'get',
      url: '/Announcement/FetchById',
      config: {
        params: data
      },
      then: res => {
        const announcement = (0, _announcementUtils.transformAnnouncementResponse)(res, _announcementTypes.AnnouncementAppMode.Platform);
        return announcement;
      },
      noAccessToken: true
    }),
    getEnvironments: () => ({
      baseURL: platform,
      method: 'get',
      url: '/Environment'
    }),
    createAnnouncement: data => {
      const apiData = (0, _announcementUtils.transformAnnouncementToApiData)(data);
      delete apiData.id;
      return {
        baseURL: platform,
        method: 'post',
        url: 'Announcement/Create',
        data: apiData,
        then: res => {
          const announcement = (0, _announcementUtils.transformAnnouncementResponse)(res, _announcementTypes.AnnouncementAppMode.Platform);
          return announcement;
        }
      };
    },
    updateAnnouncement: data => {
      const apiData = (0, _announcementUtils.transformAnnouncementToApiData)(data);
      return {
        baseURL: platform,
        method: 'put',
        url: 'Announcement/Update',
        data: apiData,
        then: res => {
          return true;
        }
      };
    },
    deleteAnnouncement: id => ({
      baseURL: platform,
      method: 'delete',
      url: 'Announcement/Delete',
      config: {
        params: {
          announcementId: id
        }
      }
    })
  });
};
exports.default = _default;