import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Toolbar,
    Chip,
    Tooltip,
} from '@mui/material'
import BackButton from 'src/components/BackButton'
import { AnnouncementAppMode, PlatformAnnouncement } from 'genesis-suite/types/announcementTypes'
import { AnnouncementRender } from 'genesis-suite/components'
import { baseUrls, moduleService, platformService } from 'src/lib/services'
import { Delete, Edit, CheckCircleOutline, Schedule } from '@mui/icons-material'
import { PLATFORM_ROUTE_PATHS } from 'src/lib/routePath'
import { useSnackbar } from 'notistack'
import { PlatformAnnouncementByIdFetchData } from 'genesis-suite/types/visualTypes'
import { useAppMode } from 'src/contexts/AppModeContext'
import { usePlatformAuth } from 'src/contexts/PlatformAuthContext'
import { usePlatformEnvironments } from 'src/contexts/PlatformEnvironmentsContext'

const useStyles = makeStyles(({ custom }) => {
    const { maxWidth, paddingBig } = custom.announcementsApp || {}
    return {
        content: {
            height: '100%',
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
            display: 'flex',
            flexDirection: 'column',
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

const PlatformAnnouncementDetailsPage: React.FC = () => {
    const { isPlatformEnv } = useAppMode()
    const classes = useStyles()
    const [isInitialised, setIsInitialised] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [announcement, setAnnouncement] = useState<PlatformAnnouncement | null>(null)
    const { id } = useParams()
    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false)
    const handleOpen = () => setIsDeleteConfirmDialogOpen(true)
    const handleClose = () => setIsDeleteConfirmDialogOpen(false)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { appMode } = useAppMode()
    const { activeAccount } = usePlatformAuth()
    const { environments } = usePlatformEnvironments()

    useEffect(() => {
        const loadAnnouncement = async (id: string) => {
            try {
                setLoading(true)
                const fetchData: PlatformAnnouncementByIdFetchData = {
                    AnnouncementId: id,
                }
                const fetchedAnnouncement: PlatformAnnouncement = await platformService.getAnnouncementById(fetchData)
                if (isPlatformEnv && !activeAccount) {
                    // Note: If announcement is not 'generally available' then redirect to home.
                    if (!fetchedAnnouncement.isGenerallyAvailable) {
                        navigate(PLATFORM_ROUTE_PATHS.HOME)
                        return
                    }
                }
                setAnnouncement(fetchedAnnouncement || null)
            } catch (error) {
                console.error('Failed to load announcement:', error)
                enqueueSnackbar('Failed to load the announcement.', { variant: 'error' })
                navigate(PLATFORM_ROUTE_PATHS.HOME)
            } finally {
                setLoading(false)
                setIsInitialised(true)
            }
        }

        if (!announcement && id) {
            loadAnnouncement(id)
        }
    }, [announcement, id, enqueueSnackbar, navigate])

    const getSrcUrlFromAttachementToken = useCallback(
        (token: string): string => `${baseUrls.platform}/File/id/${token}`,
        []
    )

    const shouldDisplayUpdateButton = (): boolean => {
        if (!announcement) return false
        if (!isPlatformEnv) return false
        if (!activeAccount) return false
        return true
    }

    const shouldDisplayDeleteButton = (): boolean => {
        if (!announcement) return false
        if (!isPlatformEnv) return false
        if (!activeAccount) return false
        return true
    }

    const handleDelete = async () => {
        if (!announcement || !announcement.id) return
        try {
            await platformService.deleteAnnouncement(announcement.id)
            enqueueSnackbar('Announcement deleted successfully!', { variant: 'success' })
            navigate(PLATFORM_ROUTE_PATHS.HOME)
        } catch (error) {
            console.error('Failed to delete announcement:', error)
            enqueueSnackbar('Failed to delete the announcement.', { variant: 'error' })
        } finally {
            handleClose()
        }
    }

    let statusIndicator = null
    const shouldDisplayStatusIndicator = isPlatformEnv && activeAccount

    if (announcement && shouldDisplayStatusIndicator) {
        if (announcement.isGenerallyAvailable) {
            statusIndicator = (
                <Chip
                    icon={<CheckCircleOutline />}
                    label="Generally Available"
                    color="success"
                    variant="outlined"
                    sx={{ mr: 1 }}
                />
            )
        } else {
            const scopedEnvironments = environments.filter(env => announcement.scope.includes(env.id))
            const scopedEnvironmentNames = scopedEnvironments.map(env => env.name)

            statusIndicator = (
                <Tooltip
                    title={
                        <div
                            style={{
                                maxHeight: '200px',
                                overflowY: 'auto',
                            }}
                        >
                            Available in:
                            <ul style={{ margin: '8px 0', paddingLeft: '16px' }}>
                                {scopedEnvironmentNames.map(name => (
                                    <li key={name}>{name}</li>
                                ))}
                            </ul>
                        </div>
                    }
                >
                    <Chip icon={<Schedule />} label="Scoped Announcement" variant="outlined" sx={{ mr: 1 }} />
                </Tooltip>
            )
        }
    }

    if (!isInitialised || isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box className={classes.content} sx={{ pb: 2 }}>
            <Toolbar className={classes.toolbar}>
                <BackButton mode={AnnouncementAppMode.Platform} />

                {statusIndicator}

                <Box display="flex" alignItems="center">
                    {shouldDisplayUpdateButton() && (
                        <Button
                            variant="contained"
                            sx={{ fontWeight: 'bold', marginLeft: 2 }}
                            startIcon={<Edit />}
                            color="primary"
                            onClick={() =>
                                navigate(PLATFORM_ROUTE_PATHS.UPDATE, {
                                    state: { announcement },
                                })
                            }
                        >
                            Update
                        </Button>
                    )}

                    {shouldDisplayDeleteButton() && (
                        <Button
                            variant="contained"
                            sx={{ fontWeight: 'bold', marginLeft: 2, color: 'black' }}
                            startIcon={<Delete />}
                            color="error"
                            onClick={handleOpen}
                        >
                            Delete
                        </Button>
                    )}
                </Box>
            </Toolbar>

            {announcement && (
                <Dialog
                    open={isDeleteConfirmDialogOpen}
                    onClose={handleClose}
                    aria-labelledby="delete-confirmation-dialog-title"
                    aria-describedby="delete-confirmation-dialog-description"
                >
                    <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-confirmation-dialog-description">
                            Are you sure you want to delete this announcement? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="error" variant="contained">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {announcement && (
                <AnnouncementRender
                    announcement={announcement}
                    getSrcUrlFromAttachmentToken={getSrcUrlFromAttachementToken}
                    getAttachment={
                        appMode === AnnouncementAppMode.Platform
                            ? platformService.getPlatformAttachment
                            : moduleService.getAttachment
                    }
                    sx={{ mt: 2 }}
                />
            )}
        </Box>
    )
}

export default PlatformAnnouncementDetailsPage
