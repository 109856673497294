import { useContext, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { SolutionAuthContext } from '../contexts/SolutionAuthContext'
import { CloudsContext } from 'src/contexts/CloudsContext'
import CloudsGuard from 'src/guards/CloudsGuard'
import { SOLUTION_ROUTE_PATHS } from './routePath'

export default ({ component: WrappedComponent, superUserOnly, useCloudGuard }) => {
    const Authed = props => {
        const { user, setRedirectTo } = useContext(SolutionAuthContext)
        const location = useLocation()
        const currentPath = location.pathname
        const isLoginRequired = !user || (superUserOnly && !user.isCoreSuperUser)
        const { clouds, isLoadingClouds } = useContext(CloudsContext)

        // Note: Remember the previous url if re-directing to login or cloud selection pages.
        useEffect(() => {
            if (isLoginRequired) {
                setRedirectTo(currentPath)
            }
        }, [])

        if (isLoginRequired) return <Navigate to={SOLUTION_ROUTE_PATHS.LOGIN} />

        // Wrap the component with CloudsGuard if useCloudGuard is true
        return useCloudGuard ? (
            <CloudsGuard>
                <WrappedComponent user={user} {...props} clouds={clouds} isLoadingClouds={isLoadingClouds} />
            </CloudsGuard>
        ) : (
            <WrappedComponent user={user} {...props} clouds={clouds} isLoadingClouds={isLoadingClouds} />
        )
    }

    return Authed
}
