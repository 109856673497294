"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isDatePicker = isDatePicker;
exports.isNumeric = isNumeric;
function isNumeric(property) {
  if (!property) return false;
  if (property.dataTypeClass === 'Number') return true;
  switch (property.semanticType?.baseDataType) {
    case 'Currency':
    case 'Decimal':
    case 'Integer':
    case 'Large Number':
    case 'Small Number':
      return true;
    default:
      return false;
  }
}
function isDatePicker(property) {
  if (property?.semanticType?.baseDataType === 'Date') return true;
  return false;
}