"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.debouncedGetNewOptions = void 0;
exports.getSelectStyles = getSelectStyles;
exports.getSelectTheme = getSelectTheme;
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getSelectTheme(muiTheme, customTheme) {
  const primary = muiTheme.palette.primary.main;
  const danger = muiTheme.palette.status.error;
  return base => ({
    borderRadius: customTheme && customTheme.borderRadius || 0,
    colors: {
      ...base.colors,
      primary,
      primary75: (0, _tinycolor.default)(primary).lighten(20).toString(),
      primary50: (0, _tinycolor.default)(primary).lighten(30).toString(),
      primary25: (0, _tinycolor.default)(primary).lighten(40).toString(),
      danger,
      dangerLight: (0, _tinycolor.default)(danger).lighten(20).toString(),
      ...(customTheme && customTheme.colors)
    },
    spacing: {
      ...base.spacing,
      ...(customTheme && customTheme.spacing)
    }
  });
}
function getSelectStyles(customStyle, labelPosition, maxFilterHeight) {
  const defaultStyles = {
    container: {
      flex: labelPosition !== 'top' && labelPosition !== 'bottom' && '1 0',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none'
    },
    control: {
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      borderRadius: 0,
      boxShadow: 'none'
    },
    indicatorSeparator: {
      display: 'none'
    },
    valueContainer: {
      whiteSpace: 'nowrap',
      padding: 0,
      overflowY: 'auto',
      maxHeight: `${maxFilterHeight}vh`
    },
    menu: {
      zIndex: 999
    },
    placeholder: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: 'italic',
      color: '#bdbdbd',
      width: '95%'
    },
    indicatorsContainer: {
      alignSelf: 'flex-end'
    }
  };
  const styles = {};
  const components = new Set([...Object.keys(defaultStyles)]);
  customStyle && Object.keys(customStyle).forEach(component => components.add(component));
  components.forEach(component => {
    styles[component] = base => ({
      ...base,
      ...defaultStyles[component],
      ...(customStyle && customStyle[component])
    });
  });
  return styles;
}
const debouncedGetNewOptions = exports.debouncedGetNewOptions = (0, _debounce.default)((value, debouncedSearchedText, moreOptionsAvailable, getNewOptions, setIsFetchingData) => {
  const alreadySearched = value === debouncedSearchedText.current;
  const noNeedToSearch = !moreOptionsAvailable && value.startsWith(debouncedSearchedText.current);
  if (alreadySearched || noNeedToSearch) return;
  debouncedSearchedText.current = value;
  getNewOptions(value);
  setIsFetchingData(true);
}, 500);