import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import userManager from '../lib/userManager'
import Loading from '../components/Loading'
import { SolutionAuthContext } from 'src/contexts/SolutionAuthContext'

export default function SSOCallback() {
    const { redirectTo } = useContext(SolutionAuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        async function go() {
            const user = await userManager.getUser()
            if (!user) {
                userManager
                    .signinRedirectCallback()
                    .then(user => {
                        const ssoRedirectTo = user?.state?.ssoRedirectTo || redirectTo
                        navigate(ssoRedirectTo)
                    })
                    .catch(err => console.error(err))
            } else {
                navigate(redirectTo)
            }
        }
        go()
    }, [])

    return <Loading title="Success!" message="Redirecting you to Announcements App" />
}
