import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { cleanDirectory } from 'genesis-suite/utils'

const CLIENT_APP_CLIENT_ID = '2815fad3-2e2e-47c2-8fd6-1a3496fa2698'
const TENANT_ID = 'ffcd3a86-cca0-47db-a58f-e054aadc8fa9'
const SERVICE_APP_CLIENT_ID = '6187d21f-e8ac-445d-a483-bbfdca402fdf'

const {
    location: { protocol, hostname, port },
} = window
const url = `${protocol}//${hostname}${port ? `:${port}` : ''}${cleanDirectory(process.env.PUBLIC_URL || '')}`

export const platformAuthConfig: Configuration = {
    auth: {
        clientId: CLIENT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        // Todo: Include 'PLATFORM_ROUTE_PATHS.HOME' in the redirectUri.
        redirectUri: `${url}`
    },
}

export const SCOPES = ['openid', 'profile', 'email', `api://${SERVICE_APP_CLIENT_ID}/.default`]

export const getPlatformAuthToken = async (msalInstance: PublicClientApplication) => {
    await msalInstance.initialize()
    const activeAccount = msalInstance.getActiveAccount()
    const accounts = msalInstance.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        console.warn('No active account or accounts found. Acquiring token failed.')
        return ''
    }
    const request = {
        scopes: SCOPES,
        account: activeAccount || accounts[0],
    }

    const authResult = await msalInstance.acquireTokenSilent(request)
    return authResult.accessToken
}

export const logOutPlatformUser = async (msalInstance: PublicClientApplication) => {
    const activeAccount = msalInstance.getActiveAccount()
    const accounts = msalInstance.getAllAccounts()
    if (activeAccount || accounts.length > 0) {
        await msalInstance.logoutRedirect({ account: activeAccount || accounts[0] })
    }
}
