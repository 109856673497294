"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformAnnouncementToApiData = exports.transformAnnouncementResponse = exports.mapType = exports.mapScope = exports.formatAnnouncementDates = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _announcementTypes = require("../types/announcementTypes");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// common/src/utils/announcementUtils.ts

const mapScope = scope => {
  switch (scope) {
    case 1:
      return _announcementTypes.SolutionAnnouncementScope.Cloud;
    case 2:
      return _announcementTypes.SolutionAnnouncementScope.Model;
    case 3:
      return _announcementTypes.SolutionAnnouncementScope.ModelPart;
    case 4:
      return _announcementTypes.SolutionAnnouncementScope.Application;
    case 5:
      return _announcementTypes.SolutionAnnouncementScope.Portal;
    default:
      throw new Error(`Unsupported scope: ${scope}`);
  }
};
exports.mapScope = mapScope;
const mapType = type => {
  switch (type) {
    case 1:
      return _announcementTypes.SolutionAnnouncementType.Maintenance;
    case 2:
      return _announcementTypes.SolutionAnnouncementType.Feature;
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
exports.mapType = mapType;
const mapAttachmentTypeToMime = type => {
  switch (type) {
    case _announcementTypes.AttachmentTypeEnum.Excel:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case _announcementTypes.AttachmentTypeEnum.PDF:
      return 'application/pdf';
    case _announcementTypes.AttachmentTypeEnum.JPEG:
      return 'image/jpeg';
    case _announcementTypes.AttachmentTypeEnum.PNG:
      return 'image/png';
    case _announcementTypes.AttachmentTypeEnum.SVG:
      return 'image/svg+xml';
    case _announcementTypes.AttachmentTypeEnum.MP4:
      return 'video/mp4';
    case _announcementTypes.AttachmentTypeEnum.WEBM:
      return 'video/webm';
    case _announcementTypes.AttachmentTypeEnum.POWERPOINT:
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case _announcementTypes.AttachmentTypeEnum.WORD:
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case _announcementTypes.AttachmentTypeEnum.Text:
      return 'text/plain';
    default:
      return 'unknown';
  }
};
const transformAnnouncementResponse = (response, mode) => {
  if (mode === _announcementTypes.AnnouncementAppMode.Platform) {
    return {
      id: response.id,
      scope: response.scope,
      isGenerallyAvailable: response.isGenerallyAvailable || false,
      title: response.title,
      description: response.description,
      startDate: response.startDate,
      endDate: response.endDate,
      attachments: response.attachments ? response.attachments.map(attachment => ({
        name: attachment.name,
        token: attachment.token,
        type: mapAttachmentTypeToMime(attachment.type)
      })) : [],
      tags: getParsedTags(response.tags),
      isDraft: response.isDraft,
      mode: _announcementTypes.AnnouncementAppMode.Platform
    };
  }
  return {
    id: response.Id,
    scope: mapScope(response.Scope),
    cloudId: response.CloudId,
    applicationId: response.ApplicationId,
    portalId: response.PortalId,
    type: mapType(response.Type),
    title: response.Title,
    description: response.Description,
    startDate: response.StartDate,
    endDate: response.EndDate,
    attachments: response.Attachments ? response.Attachments.map(attachment => ({
      name: attachment.Name,
      token: attachment.Token,
      type: mapAttachmentTypeToMime(attachment.Type)
    })) : [],
    whitelistedUserIds: response.WhitelistedUserIds,
    bannerMedia: null,
    tags: getParsedTags(response.Tags),
    isDraft: response.IsDraft,
    createdBy: response.CreatedBy,
    mode: _announcementTypes.AnnouncementAppMode.Solution
  };
};
exports.transformAnnouncementResponse = transformAnnouncementResponse;
const getParsedTags = tags => {
  if (!tags) return [];
  let tagsData = [];
  try {
    tagsData = JSON.parse(tags);
  } catch (error) {
    console.warn(error);
    tagsData = [];
  }
  return tagsData;
};
const mapScopeToApi = scope => {
  switch (scope) {
    case _announcementTypes.SolutionAnnouncementScope.Cloud:
      return 1;
    case _announcementTypes.SolutionAnnouncementScope.Model:
      return 2;
    case _announcementTypes.SolutionAnnouncementScope.ModelPart:
      return 3;
    case _announcementTypes.SolutionAnnouncementScope.Application:
      return 4;
    case _announcementTypes.SolutionAnnouncementScope.Portal:
      return 5;
    default:
      throw new Error(`Unsupported scope: ${scope}`);
  }
};
const mapTypeToApi = type => {
  switch (type) {
    case _announcementTypes.SolutionAnnouncementType.Maintenance:
      return 1;
    case _announcementTypes.SolutionAnnouncementType.Feature:
      return 2;
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
const transformAnnouncementToApiData = announcement => {
  if (announcement.mode === _announcementTypes.AnnouncementAppMode.Platform) {
    return {
      id: announcement.id,
      scope: announcement.scope,
      isGenerallyAvailable: announcement.isGenerallyAvailable || false,
      title: announcement.title,
      description: announcement.description,
      startDate: announcement.startDate,
      endDate: announcement.endDate,
      attachments: announcement.attachments.map(a => transformAttachmentToApiData(a)),
      tags: JSON.stringify(announcement.tags),
      isDraft: announcement.isDraft || false
    };
  }
  return {
    id: announcement.id,
    scope: mapScopeToApi(announcement.scope),
    cloudId: announcement.cloudId,
    applicationId: announcement.scope === _announcementTypes.SolutionAnnouncementScope.Cloud ? null : announcement.applicationId,
    portalId: announcement.scope === _announcementTypes.SolutionAnnouncementScope.Portal ? announcement.portalId : null,
    type: mapTypeToApi(announcement.type),
    title: announcement.title,
    description: announcement.description,
    startDate: announcement.startDate,
    endDate: announcement.endDate,
    attachments: announcement.attachments.map(a => transformAttachmentToApiData(a)),
    whitelistedUserIds: announcement.whitelistedUserIds,
    tags: JSON.stringify(announcement.tags),
    isDraft: announcement.isDraft || false
  };
};
exports.transformAnnouncementToApiData = transformAnnouncementToApiData;
const mimeTypeToEnumMap = {
  // Excel/Spreadsheet formats
  'application/vnd.ms-excel': _announcementTypes.AttachmentTypeEnum.Excel,
  // Legacy Excel format
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': _announcementTypes.AttachmentTypeEnum.Excel,
  // Modern Excel format (.xlsx)
  'application/vnd.ms-excel.sheet.macroEnabled.12': _announcementTypes.AttachmentTypeEnum.Excel,
  // Excel macro-enabled format (.xlsm)
  'application/vnd.oasis.opendocument.spreadsheet': _announcementTypes.AttachmentTypeEnum.Excel,
  // OpenDocument Spreadsheet (.ods)
  'application/vnd.google-apps.spreadsheet': _announcementTypes.AttachmentTypeEnum.Excel,
  // Google Sheets

  // Word formats
  'application/msword': _announcementTypes.AttachmentTypeEnum.WORD,
  // Legacy Word format (.doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': _announcementTypes.AttachmentTypeEnum.WORD,
  // Modern Word format (.docx)
  'application/vnd.ms-word.document.macroEnabled.12': _announcementTypes.AttachmentTypeEnum.WORD,
  // Word macro-enabled format (.docm)
  'application/vnd.oasis.opendocument.text': _announcementTypes.AttachmentTypeEnum.WORD,
  // OpenDocument Text (.odt)
  'application/vnd.google-apps.document': _announcementTypes.AttachmentTypeEnum.WORD,
  // Google Docs

  // PowerPoint formats
  'application/vnd.ms-powerpoint': _announcementTypes.AttachmentTypeEnum.POWERPOINT,
  // Legacy PowerPoint format (.ppt)
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': _announcementTypes.AttachmentTypeEnum.POWERPOINT,
  // Modern PowerPoint format (.pptx)
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': _announcementTypes.AttachmentTypeEnum.POWERPOINT,
  // PowerPoint macro-enabled format (.pptm)
  'application/vnd.oasis.opendocument.presentation': _announcementTypes.AttachmentTypeEnum.POWERPOINT,
  // OpenDocument Presentation (.odp)
  'application/vnd.google-apps.presentation': _announcementTypes.AttachmentTypeEnum.POWERPOINT,
  // Google Slides

  // PDF
  'application/pdf': _announcementTypes.AttachmentTypeEnum.PDF,
  // Images
  'image/jpeg': _announcementTypes.AttachmentTypeEnum.JPEG,
  'image/png': _announcementTypes.AttachmentTypeEnum.PNG,
  'image/svg+xml': _announcementTypes.AttachmentTypeEnum.SVG,
  // Video
  'video/mp4': _announcementTypes.AttachmentTypeEnum.MP4,
  'video/webm': _announcementTypes.AttachmentTypeEnum.WEBM,
  // Text
  'text/plain': _announcementTypes.AttachmentTypeEnum.Text
};
const transformAttachmentToApiData = attachment => {
  return {
    name: attachment.name,
    token: attachment.token,
    type: mimeTypeToEnumMap[attachment.type] || _announcementTypes.AttachmentTypeEnum.Unknown
  };
};
const formatAnnouncementDates = (startDate, endDate, type) => {
  const formattedStartDate = (0, _moment.default)(startDate).format('MMMM D, YYYY h:mm A');
  if (!endDate && type === _announcementTypes.SolutionAnnouncementType.Maintenance) {
    return `From ${formattedStartDate}`;
  }
  if (!endDate && type === _announcementTypes.SolutionAnnouncementType.Feature) {
    return `${formattedStartDate}`;
  }
  if (endDate) {
    const formattedEndDate = (0, _moment.default)(endDate).format('MMMM D, YYYY h:mm A');
    return `From ${formattedStartDate}     to ${formattedEndDate}`;
  }
  return `Start ${formattedStartDate}`;
};
exports.formatAnnouncementDates = formatAnnouncementDates;