import { useContext, useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Box,
    Button,
    Toolbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BackButton from 'src/components/BackButton'
import { useSnackbar } from 'notistack'
import { Delete, Edit } from '@mui/icons-material'
import { SolutionAuthContext } from 'src/contexts/SolutionAuthContext'
import { AnnouncementRender } from 'genesis-suite/components'
import { announcementService, baseUrls, moduleService } from 'src/lib/services'
import { SolutionAnnouncement, SolutionAnnouncementType } from 'genesis-suite/types/announcementTypes'
import { SolutionAnnouncementByIdFetchData } from 'genesis-suite/types/visualTypes'
import { Cloud } from 'src/types'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

const useStyles = makeStyles(({ custom }) => {
    const { maxWidth, paddingBig } = custom.announcementsApp || {}
    return {
        content: {
            height: '100%',
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
            display: 'flex',
            flexDirection: 'column',
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

const AnnouncementDetails = ({ activeCloud = null }: { activeCloud?: Cloud | null }) => {
    const [isInitialised, setIsInitialised] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [announcement, setAnnouncement] = useState<SolutionAnnouncement | null>(null)

    const activeApplication =
        activeCloud?.applications.find(a => a.id === announcement?.applicationId) ||
        activeCloud?.applications[0] ||
        null

    const navigate = useNavigate()
    const { cloudName, announcementId } = useParams()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { user } = useContext(SolutionAuthContext)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        const loadAnnouncement = async (announcementId: string, cloudId: string) => {
            try {
                setLoading(true)
                const fetchData: SolutionAnnouncementByIdFetchData = {
                    CloudId: cloudId,
                    AnnouncementId: announcementId,
                }
                const fetchedAnnouncement = await announcementService.getAnnouncementById(fetchData)
                setAnnouncement(fetchedAnnouncement || null)
            } catch (error) {
                console.error('Failed to load announcement:', error)
                enqueueSnackbar('Failed to load the announcement.', { variant: 'error' })
                navigate('/')
            } finally {
                setLoading(false)
                setIsInitialised(true)
            }
        }

        if (!announcement && announcementId && activeCloud?.id) {
            loadAnnouncement(announcementId, activeCloud?.id)
        }
    }, [announcement, activeCloud, announcementId])

    const getSrcUrlFromAttachementToken = useCallback(
        (token: string): string => {
            if (!user) {
                console.warn('User not authenticated')
                return ''
            }
            return `${baseUrls.integration}/files?fileToken=${token}&accessKey=${user?.accessKey || ''}`
        },
        [user]
    )

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleDelete = async () => {
        if (!announcement || !announcement.id || !announcement.cloudId || !activeCloud) return
        try {
            await announcementService.deleteAnnouncement(announcement.id, announcement.cloudId)
            enqueueSnackbar('Announcement deleted successfully!', { variant: 'success' })
            activeApplication
                ? navigate(
                      SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(activeApplication?.cloudName, activeApplication.name)
                  )
                : navigate(SOLUTION_ROUTE_PATHS.SELECT)
        } catch (error) {
            console.error('Failed to delete announcement:', error)
            enqueueSnackbar('Failed to delete the announcement.', { variant: 'error' })
        } finally {
            handleClose()
        }
    }

    if (!isInitialised || isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        )
    }
    const isAdminUser =
        user?.userId === announcement?.createdBy ||
        announcement?.whitelistedUserIds?.includes(user?.userId as string) ||
        false

    const shouldDisplayUpdateButton = (): boolean => {
        if (!announcement) return false
        const now = new Date().toISOString()
        // Maintenance Announcements can be updated if they are before the endDate
        if (
            announcement.type === SolutionAnnouncementType.Maintenance &&
            (!announcement.endDate || announcement.endDate > now)
        ) {
            return isAdminUser
        }
        // Feature Announcements can be updated only if they have not started yet
        if (announcement.type === SolutionAnnouncementType.Feature && announcement.startDate > now) {
            return isAdminUser
        }
        return false
    }

    const shouldDisplayDeleteButton = (): boolean => {
        if (!announcement) return false
        const now = new Date().toISOString()
        // Maintenance Announcements can be deleted if they are before the startDate.
        if (
            announcement.type === SolutionAnnouncementType.Maintenance &&
            announcement.startDate &&
            announcement.startDate > now
        ) {
            return isAdminUser
        }
        // Feature Announcements can be deleted at any time.
        if (announcement.type === SolutionAnnouncementType.Feature) {
            return isAdminUser
        }
        return false
    }
    return (
        <Box className={classes.content} sx={{ pb: 2 }}>
            <Toolbar className={classes.toolbar}>
                <BackButton cloudName={cloudName} applicationName={activeApplication?.name} />
                <Box sx={{ flexGrow: 1 }} />
                {shouldDisplayUpdateButton() && (
                    <Button
                        variant="contained"
                        sx={{ fontWeight: 'bold', marginLeft: 2 }}
                        startIcon={<Edit />}
                        color="primary"
                        onClick={() =>
                            navigate(
                                SOLUTION_ROUTE_PATHS.UPDATE_ANNOUNCEMENT_APP(
                                    cloudName || '',
                                    activeApplication?.name || ''
                                ),
                                {
                                    state: { announcement },
                                }
                            )
                        }
                    >
                        Update
                    </Button>
                )}
                {shouldDisplayDeleteButton() && (
                    <Button
                        variant="contained"
                        sx={{ fontWeight: 'bold', marginLeft: 2, color: 'black' }}
                        startIcon={<Delete />}
                        color="error"
                        onClick={handleOpen}
                    >
                        Delete
                    </Button>
                )}
            </Toolbar>
            {!isInitialised || isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {announcement && (
                        <AnnouncementRender
                            announcement={announcement}
                            getSrcUrlFromAttachmentToken={getSrcUrlFromAttachementToken}
                            getAttachment={moduleService.getAttachment}
                            userAccessKey={user?.accessKey || null}
                        />
                    )}
                    {announcement && (
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="delete-confirmation-dialog-title"
                            aria-describedby="delete-confirmation-dialog-description"
                        >
                            <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="delete-confirmation-dialog-description">
                                    Are you sure you want to delete this announcement? This action cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDelete} color="error" variant="contained">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {!announcement && (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Typography variant="h5" color="textSecondary">
                                Announcement not found
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default AnnouncementDetails
