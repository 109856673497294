"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultGroup = void 0;
var _uuid = require("uuid");
const defaultGroup = exports.defaultGroup = {
  key: (0, _uuid.v4)(),
  title: 'Group 1',
  rules: undefined,
  level: 1,
  levelName: 'Level1'
};