"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Converts Object or Array of Objects to Camel Case
 * Any string keys provided after the first argument will be ignored when camel casing
 */
const toCamel = (o, ...ignoreKeys) => {
  if (typeof o === 'string' || !o) return o;
  let newKey;
  if (o instanceof Array) {
    return o.map(value => {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  }
  let value;
  const blacklist = ['Permissions', 'SecurityFilters', 'PortalsPermissions'];
  return Object.keys(o).reduce((acc, origKey) => {
    if (o.hasOwnProperty(origKey)) {
      newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
      value = o[origKey];
      if (value instanceof Array || value != null && value.constructor === Object && !blacklist.includes(origKey) && !ignoreKeys.includes(origKey)) {
        value = toCamel(value);
      }
      acc[newKey] = value;
      return acc;
    }
    return acc;
  }, {});
};
var _default = exports.default = toCamel;