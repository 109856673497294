import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'
import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { PLATFORM_BASE, SOLUTION_BASE } from '../lib/routePath'

interface AppModeContextProps {
    appMode: AnnouncementAppMode
    isPlatformEnv: boolean
}

const AppModeContext = createContext<AppModeContextProps | undefined>(undefined)

interface AppModeProviderProps {
    children: ReactNode
}

const AppModeProvider = ({ children }: AppModeProviderProps) => {
    const location = useLocation()
    const isPlatformEnv =
        (window.TADA_APIS.ENVIRONMENT && window.TADA_APIS.ENVIRONMENT?.toUpperCase() === 'PLATFORM') || false

    // Note: Determining appMode based on the active route
    const appMode = useMemo(() => {
        if (isPlatformEnv) {
            return AnnouncementAppMode.Platform
        } else if (location.pathname.startsWith(PLATFORM_BASE)) {
            return AnnouncementAppMode.Platform
        } else if (location.pathname.startsWith(SOLUTION_BASE)) {
            return AnnouncementAppMode.Solution
        } else {
            return AnnouncementAppMode.Solution
        }
    }, [location.pathname, isPlatformEnv])

    // Note: Memoizing the context value to it change it only when appMode or isPlatformEnv change, not on every render.
    const contextValue = useMemo(() => ({ appMode, isPlatformEnv }), [appMode, isPlatformEnv])

    return <AppModeContext.Provider value={contextValue}>{children}</AppModeContext.Provider>
}

export { AppModeContext, AppModeProvider }

export const useAppMode = (): AppModeContextProps => {
    const context = useContext(AppModeContext)
    if (!context) {
        throw new Error('useAppMode must be used within an AppModeProvider')
    }
    return context
}
