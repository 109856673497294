"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  getTrees: application => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/trees`
  }),
  getMeta: (application, treeName) => ({
    baseURL: integration,
    method: 'get',
    url: `${application}/${treeName}/meta`
  }),
  getData: (application, treeName) => ({
    baseURL: integration,
    method: 'get',
    url: `${application}/${treeName}`
  }),
  getTreeData: (application, treeName, params) => ({
    baseURL: integration,
    method: 'get',
    url: `${application}/${treeName}`,
    config: {
      params: params
    }
  })
});
exports.default = _default;