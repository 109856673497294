"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Takes routes with dynamic url params (e.g. :perspectiveId) and replaces them
 * with the provided arguments in order.
 * @param path - Path name to change (e.g. /perspective/:perspectiveId)
 * @param args - Strings to replace URL params with
 */
var _default = (path, ...args) => {
  const url = args.reduce((acc, arg) => acc.replace(/(:.*?(?=(\/|$)))/, arg), path);
  return encodeURI(url);
};
exports.default = _default;