import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { CloudsContext } from 'src/contexts/CloudsContext'
import { Cloud, Portal, UserInfo } from 'src/types'
import { moduleService, userService } from 'src/lib/services'
import { useDebounce } from 'use-debounce'

const StepTypeAndScope = ({ activeCloud, isUpdating = false }: { activeCloud: Cloud | null; isUpdating?: boolean }) => {
    const {
        control,
        setValue,
        formState: { errors },
    } = useFormContext()
    const { isLoadingClouds } = useContext(CloudsContext)
    const cloudApplications = activeCloud?.applications || []

    const [isPortalsLoading, setIsPortalsLoading] = useState(false)
    const [applicationPortals, setApplicationPortals] = useState<Portal[]>([])
    const [isUsersLoading, setIsUsersLoading] = useState(false)
    const [applicationUsers, setApplicationUsers] = useState<UserInfo[]>([])
    const [searchText, setSearchText] = useState('')
    const [debouncedSearchText] = useDebounce(searchText, 300)
    const [selectedUsers, setSelectedUsers] = useState<UserInfo[]>([])

    const [scopeValue, typeValue, applicationId] = useWatch({
        control,
        name: ['scope', 'type', 'applicationId'],
    })

    const handleScopeValueChange = useCallback(() => {
        if (scopeValue !== 'portal') {
            setValue('portalId', '')
        }
    }, [scopeValue, setValue])

    useEffect(() => {
        handleScopeValueChange()
    }, [handleScopeValueChange])

    useEffect(() => {
        const application = cloudApplications.find(a => a.id === applicationId) || null
        if (!application) return

        fetchUsers(application, debouncedSearchText)
    }, [debouncedSearchText])

    const fetchUsers = async (application: any, searchText?: string) => {
        setIsUsersLoading(true)
        try {
            const usersFetchRequest = {
                ModelName: application.name,
                PageSize: 200,
                PageNumber: 1,
                loaddetails: true,
                superUsersOnly: false,
                userIdSearch: searchText,
            }
            const usersRes = await userService.getUsersV2(usersFetchRequest)
            setApplicationUsers(usersRes.users)
            application.users = usersRes.users
        } catch (error) {
            console.error(error)
            setApplicationUsers([])
        } finally {
            setIsUsersLoading(false)
        }
    }

    const handleApplication = useCallback(() => {
        const application = cloudApplications.find(a => a.id === applicationId) || null
        if (!application) return

        const fetchPortals = async () => {
            setIsPortalsLoading(true)
            try {
                const response = await moduleService.getAll(application.name, true)
                const portals = response.map((p: Portal) => {
                    p.cloudId = application.cloudId
                    p.applicationId = application.id
                    p.announcements = []
                    return p
                })
                setApplicationPortals(portals)
                application.portals = portals
            } catch (err) {
                console.error(err)
                setApplicationPortals([])
            } finally {
                setIsPortalsLoading(false)
            }
        }

        if (application?.portals) {
            setApplicationPortals(application.portals)
        } else {
            fetchPortals()
        }

        if (application?.users) {
            setApplicationUsers(application.users)
        } else {
            fetchUsers(application)
        }
    }, [applicationId, setValue])

    useEffect(() => {
        handleApplication()
    }, [handleApplication])

    useEffect(() => {
        if (typeValue !== 'maintenance') {
            setValue('whitelistedUserIds', [])
        }
    }, [typeValue, setValue])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onApplicationChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldOnChange: (...event: any[]) => void
    ) => {
        fieldOnChange(e)
        setValue('portalId', '')
        setValue('whitelistedUserIds', [])
    }

    return (
        <Box>
            <Controller
                name="type"
                control={control}
                rules={{ required: 'Type is required' }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        label="Type"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={!!errors.type}
                        helperText={errors.type ? 'Type is required' : ''}
                        disabled={isUpdating}
                    >
                        <MenuItem value="feature">Feature</MenuItem>
                        <MenuItem value="maintenance">Maintenance</MenuItem>
                    </TextField>
                )}
            />
            <Controller
                name="scope"
                control={control}
                rules={{ required: 'Scope is required' }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        label="Scope"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={!!errors.scope}
                        helperText={errors.scope ? 'Scope is required' : ''}
                        disabled={isUpdating}
                    >
                        <MenuItem value="cloud">Cloud</MenuItem>
                        <MenuItem value="application">Application</MenuItem>
                        <MenuItem value="portal">Portal</MenuItem>
                    </TextField>
                )}
            />
            {scopeValue && (
                <Controller
                    name="cloudId"
                    control={control}
                    rules={{ required: 'Cloud is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            label="Cloud"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            error={!!errors.cloudId}
                            helperText={errors.cloudId ? 'Cloud is required' : ''}
                            disabled={isUpdating}
                        >
                            {isLoadingClouds ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : activeCloud ? (
                                <MenuItem value={activeCloud.id}>{activeCloud.name}</MenuItem>
                            ) : (
                                <MenuItem disabled>No Cloud Present</MenuItem>
                            )}
                        </TextField>
                    )}
                />
            )}
            {(scopeValue === 'application' || scopeValue === 'portal') && (
                <Controller
                    name="applicationId"
                    control={control}
                    rules={{ required: 'Application is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            onChange={e => onApplicationChange(e, field.onChange)}
                            select
                            label="Application"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            error={!!errors.applicationId}
                            helperText={errors.applicationId ? 'Application is required' : ''}
                            disabled={isUpdating}
                        >
                            {isLoadingClouds ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : cloudApplications.length > 0 ? (
                                cloudApplications.map(app => (
                                    <MenuItem key={app.id} value={app.id}>
                                        {app.displayName || app.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No Applications Present</MenuItem>
                            )}
                        </TextField>
                    )}
                />
            )}
            {scopeValue === 'portal' && (
                <Controller
                    name="portalId"
                    control={control}
                    rules={{ required: 'Portal is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            label="Portal"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            error={!!errors.portalId}
                            helperText={errors.portalId ? 'Portal is required' : ''}
                            disabled={isUpdating}
                        >
                            {isPortalsLoading ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : applicationPortals && applicationPortals.length > 0 ? (
                                applicationPortals.map(portal => (
                                    <MenuItem key={portal.id} value={portal.id}>
                                        {portal.title}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No Portals Present</MenuItem>
                            )}
                        </TextField>
                    )}
                />
            )}
            {typeValue === 'maintenance' && (
                <Controller
                    name="whitelistedUserIds"
                    control={control}
                    rules={{ required: 'Whitelisted Users are required' }}
                    render={({ field }) => (
                        <FormControl margin="normal" fullWidth error={!!errors.whitelistedUserIds}>
                            <Autocomplete
                                {...field}
                                multiple
                                options={applicationUsers || []}
                                getOptionLabel={option => option.userInfo.screenAlias}
                                isOptionEqualToValue={(option, value) =>
                                    option.userInfo.userId === value.userInfo.userId
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Whitelisted Users"
                                        placeholder="Select Whitelisted Users"
                                        error={!!errors.whitelistedUserIds}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => {
                                    //@ts-ignore
                                    const { key, ...rest } = props
                                    return (
                                        <li key={option.userInfo.userId} {...rest}>
                                            <Checkbox checked={selected} style={{ marginRight: 8 }} />
                                            <ListItemText primary={option.userInfo.screenAlias} />
                                        </li>
                                    )
                                }}
                                value={selectedUsers}
                                onChange={(_event, newValue) => {
                                    setSelectedUsers(newValue)
                                    field.onChange(newValue.map(user => user.userInfo.userId))
                                }}
                                loading={isUsersLoading}
                                loadingText={<CircularProgress size={24} />}
                                noOptionsText="No Users Present"
                                disableCloseOnSelect
                                onInputChange={(_event, newInputValue, reason) => {
                                    if (reason === 'reset') return
                                    setSearchText(newInputValue)
                                }}
                                inputValue={searchText}
                                openOnFocus
                            />
                            {errors.whitelistedUserIds && (
                                <Box mt={1} color="error.main">
                                    {'Whitelisted Users are required'}
                                </Box>
                            )}
                        </FormControl>
                    )}
                />
            )}
        </Box>
    )
}

export default StepTypeAndScope
