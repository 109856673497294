import { useEffect, useRef, useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import SolutionProviders from './providers/SolutionProviders'
import SolutionTopNav from './components/solution/SolutionTopNav'
import { solutionRoutes } from './routes/SolutionRoutes'
import withAuth from './lib/withAuth'
import { SOLUTION_BASE, SOLUTION_ROUTE_PATHS } from './lib/routePath'
import { authService } from './lib/services'
import { SolutionAuthContext } from './contexts/SolutionAuthContext'

const useStyles = makeStyles(() => ({
    main: { flexGrow: 1 },
}))

const SolutionAppContent = () => {
    const classes = useStyles()
    const { user } = useContext(SolutionAuthContext)
    const intervalRef = useRef<number | null>(null)

    useEffect(() => {
        if (user) {
            intervalRef.current = window.setInterval(() => {
                authService.ping()
            }, 5 * 60 * 1000)
        }

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [user])

    return (
        <>
            <SolutionTopNav />

            <main className={classes.main}>
                <Routes>
                    {solutionRoutes.map(route => {
                        const { path, secured, superUserOnly, useCloudGuard, component } = route
                        const ElementComponent = secured
                            ? withAuth({
                                  component,
                                  superUserOnly,
                                  useCloudGuard,
                              })
                            : component
                        return (
                            <Route
                                key={path}
                                path={path.replace(`${SOLUTION_BASE}`, '')}
                                element={<ElementComponent />}
                            />
                        )
                    })}
                    <Route path="*" element={<Navigate to={SOLUTION_ROUTE_PATHS.SELECT} />} />
                </Routes>
            </main>
        </>
    )
}

const SolutionApp = () => {
    return (
        <SolutionProviders>
            <SolutionAppContent />
        </SolutionProviders>
    )
}

export default SolutionApp
