import React, { useState, createContext, ReactNode, FC } from 'react'
import { OUser, User } from '../types'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

interface AuthContextType {
    user: User | null
    redirectTo: string
    setAuthUser: (oUser: OUser | null) => void
    setRedirectTo: (url: string) => void
    resetRedirectTo: () => void
}

interface AuthProviderProps {
    children: ReactNode
}

const createUserObj = (oUser: OUser | null): User | null => {
    if (!oUser) return null
    const { profile } = oUser
    if (!profile) return null
    const accessKey = profile.TADAAccessKey
    const authorizedApps = profile.AuthorizedTADAApplications
    const userInfo = JSON.parse(profile.TADAUserDetails)
    const { FirstName, LastName, ScreenAlias, UserId, Email, Phone, Preferences, Permissions, IsCoreSuperUser } =
        userInfo

    return {
        accessKey,
        authorizedApplications: authorizedApps && authorizedApps.length ? authorizedApps.split(',').sort() : [],
        firstName: FirstName,
        lastName: LastName,
        username: ScreenAlias,
        isCoreSuperUser: IsCoreSuperUser,
        userId: UserId,
        email: Email || '',
        phone: Phone || '',
        preferences: Preferences,
        permissions: Permissions,
        userInfo,
    }
}

const SolutionAuthContext = createContext<AuthContextType>({
    user: null,
    redirectTo: SOLUTION_ROUTE_PATHS.SELECT,
    setAuthUser: _oUser => void {},
    setRedirectTo: _redirectTo => void {},
    resetRedirectTo: () => void {},
})

const SolutionAuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null)
    const [redirectTo, setRedirectTo] = useState<string>(SOLUTION_ROUTE_PATHS.SELECT)

    const setAuthUser = (oUser: OUser | null) => {
        setUser(createUserObj(oUser))
    }
    const resetRedirectTo = () => {
        setRedirectTo(SOLUTION_ROUTE_PATHS.SELECT)
    }

    return (
        <SolutionAuthContext.Provider value={{ user, redirectTo, setAuthUser, setRedirectTo, resetRedirectTo }}>
            {children}
        </SolutionAuthContext.Provider>
    )
}

export { SolutionAuthContext, SolutionAuthProvider }
