const isPlatformEnv =
    (window.TADA_APIS.ENVIRONMENT && window.TADA_APIS.ENVIRONMENT?.toUpperCase() === 'PLATFORM') || false
export const PLATFORM_BASE = isPlatformEnv ? '' : '/platform'

export const PLATFORM_ROUTE_PATHS = {
    HOME: `${PLATFORM_BASE}/`,
    LOGIN: `${PLATFORM_BASE}/login`,
    CREATE: `${PLATFORM_BASE}/create`,
    UPDATE: `${PLATFORM_BASE}/update`,
    ANNOUNCEMENT_DETAILS: (id: string | number) => `${PLATFORM_BASE}/announcement/${id}`,
}

export const SOLUTION_BASE = '/solution'

export const SOLUTION_ROUTE_PATHS = {
    LOGIN: `${SOLUTION_BASE}/login`,
    CALLBACK: `${SOLUTION_BASE}/callback`,
    PASSWORD_RESET: `${SOLUTION_BASE}/password_reset`,
    PASSWORD_RESET_SUCCESS: `${SOLUTION_BASE}/password_reset/success`,
    SELECT: `${SOLUTION_BASE}/select`,
    ANNOUNCEMENTS_LIST_CLOUD: (cloudName: string) => `${SOLUTION_BASE}/${cloudName}`,
    ANNOUNCEMENTS_LIST_APP: (cloudName: string, applicationName: string) =>
        `${SOLUTION_BASE}/${cloudName}/${applicationName}`,
    CREATE_ANNOUNCEMENT_CLOUD: (cloudName: string) => `${SOLUTION_BASE}/${cloudName}/create`,
    CREATE_ANNOUNCEMENT_APP: (cloudName: string, applicationName: string) =>
        `${SOLUTION_BASE}/${cloudName}/${applicationName}/create`,
    UPDATE_ANNOUNCEMENT_APP: (cloudName: string, applicationName: string) =>
        `${SOLUTION_BASE}/${cloudName}/${applicationName}/update`,
    ANNOUNCEMENT_DETAILS: (cloudName: string, announcementId: string) =>
        `${SOLUTION_BASE}/${cloudName}/announcement/${announcementId}`,
}
