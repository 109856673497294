"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useHover;
var _react = require("react");
/**
 * Returns a boolean representing whether or not the provided reference is currently being hovered on
 * @param {Object} ref - DOM element reference to detect hover by mouseenter/mouseleave events
 * @returns {boolean} - whether or not the ref is currently being hovered on
 */
function useHover(ref) {
  const [hover, setHover] = (0, _react.useState)(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseExit = () => setHover(false);
  (0, _react.useEffect)(() => {
    if (!ref.current) return;
    ref.current.addEventListener('mouseenter', handleMouseEnter);
    ref.current.addEventListener('mouseleave', handleMouseExit);
    return () => {
      if (!ref.current) return;
      ref.current.removeEventListener('mouseenter', handleMouseEnter);
      ref.current.removeEventListener('mouseleave', handleMouseExit);
    };
  }, [ref.current]);
  return hover;
}