"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insertVideo = exports.insertImage = exports.insertFileLink = exports.handleFile = void 0;
const insertImage = (editor, pos, src) => {
  editor.chain().focus().insertContentAt(pos, {
    type: 'image',
    attrs: {
      src: src
    },
    inline: true
  }).run();
};
exports.insertImage = insertImage;
const insertVideo = (editor, pos, src) => {
  editor.chain().focus().insertContentAt(pos, {
    type: 'video',
    attrs: {
      src: src,
      controls: true
    },
    inline: false
  }).run();
};
exports.insertVideo = insertVideo;
const insertFileLink = (editor, pos, fileName, fileUrl) => {
  editor.chain().focus().insertContentAt(pos, {
    type: 'heading',
    attrs: {
      level: 3
    },
    content: [{
      type: 'text',
      text: fileName
    }]
  }).setTextSelection({
    from: pos,
    to: pos + fileName.length + 1
  }).setLink({
    href: fileUrl
  }).run();
};
exports.insertFileLink = insertFileLink;
const handleFile = async (editor, files, pos, config, onError) => {
  const attachments = [];
  for (const file of files) {
    try {
      const token = await config.getTokenForFile(file);
      const src = config.getSrcUrlFromToken(token);
      if (file.type.startsWith('image/')) {
        insertImage(editor, pos, src);
      } else if (file.type.startsWith('video/')) {
        insertVideo(editor, pos, src);
      } else {
        insertFileLink(editor, pos, file.name, src);
      }
      attachments.push({
        token,
        srcUrl: src
      });
    } catch (error) {
      console.error('File handling failed:', error);
      onError('File handling failed.');
    }
  }
  return attachments;
};
exports.handleFile = handleFile;