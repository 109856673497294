"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration,
  orgWeb
}) => generator({
  getExpressionFunctions: () => ({
    baseURL: integration,
    method: 'get',
    url: `BuiltInFunctions`,
    then: data => data.map(f => ({
      categoryId: f.CategoryId,
      categoryName: f.CategoryName,
      id: f.FunctionId,
      name: f.Name,
      value: f.Notation,
      description: f.Description,
      example: f.Example
    }))
  }),
  getSqlTypes: sessionKey => ({
    baseURL: orgWeb,
    method: 'get',
    url: `tableInterfaces/sqltypes`,
    config: {
      params: {
        accessKey: sessionKey
      }
    }
  }),
  getSemDataTypes: () => ({
    baseURL: integration,
    method: 'get',
    url: `semantictypes`,
    then: data => data.map(({
      DataTypeObj: t
    }) => ({
      baseDataType: t.BaseDataType,
      categoryName: t.DatatypeCategoryName,
      defaultValue: t.DefaultValue,
      description: t.Description,
      fileTypes: t.FileTypes,
      hasValidationList: t.HasValidationList,
      id: t.DataTypeId,
      maxValue: t.MaxValue,
      minValue: t.MinValue,
      name: t.DatatypeName,
      stringLength: t.StringLength,
      type: t.Type,
      supportsMarkup: t.SupportsMarkup
    }))
  }),
  getThemes: () => ({
    baseURL: integration,
    method: 'get',
    url: `AppThemes`
  }),
  getThemeById: id => ({
    baseURL: integration,
    method: 'get',
    url: `AppThemes/${id}`
  })
});
exports.default = _default;