import React, { useEffect, useRef, useState, useCallback, useContext } from 'react'
import {
    Box,
    Toolbar,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { isAdmin } from 'src/lib/userRoles'
import { SolutionAuthContext } from 'src/contexts/SolutionAuthContext'
import { App } from 'src/types'
import { PLATFORM_ROUTE_PATHS, SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'
import { useAppMode } from 'src/contexts/AppModeContext'
import { usePlatformAuth } from 'src/contexts/PlatformAuthContext'
import EnvironmentMultiSelect from 'src/components/EnvironmentMultiSelect'
import { usePlatformEnvironments } from 'src/contexts/PlatformEnvironmentsContext'

interface ListToolBarProps {
    applications?: App[]
    selectedApplication?: App | null
    toolBarClassName?: string
    onSearch: (query: string) => void
    environmentValue?: string[]
    onEnvironmentChange?: (value: string[]) => void
}

const ListToolBar: React.FC<ListToolBarProps> = ({
    applications,
    selectedApplication,
    toolBarClassName = '',
    onSearch,
    environmentValue,
    onEnvironmentChange,
}) => {
    const { appMode, isPlatformEnv } = useAppMode()
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false)
    const navigate = useNavigate()
    const textFieldRef = useRef<HTMLInputElement>(null)
    const { activeAccount: activePlatformAccount } = usePlatformAuth()
    const { environments, isLoading } = usePlatformEnvironments()

    const handleSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value)
            onSearch(event.target.value)
        },
        [onSearch]
    )

    const toggleSearchVisibility = useCallback(() => {
        setIsSearchVisible(prevState => !prevState)
    }, [])

    const handleBlur = () => {
        if (!searchQuery) {
            setIsSearchVisible(false)
        }
    }

    const { user } = useContext(SolutionAuthContext)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleApplicationChange: any = (event: React.ChangeEvent<{ value: unknown }>) => {
        const app = applications?.find(app => app.id === event.target.value) || null
        app
            ? navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(app.cloudName, app.name))
            : navigate(SOLUTION_ROUTE_PATHS.SELECT)
    }
    useEffect(() => {
        if (isSearchVisible && textFieldRef.current) {
            textFieldRef.current.focus()
        }
    }, [isSearchVisible])

    return (
        <Box sx={{ backgroundColor: 'background.paper' }}>
            <Toolbar className={toolBarClassName}>
                {applications && applications.length > 0 && (
                    <FormControl variant="outlined" sx={{ minWidth: 240, marginRight: 2 }}>
                        <Select
                            variant="outlined"
                            displayEmpty
                            value={selectedApplication ? selectedApplication.id : ''}
                            onChange={handleApplicationChange}
                        >
                            {applications?.map(app => (
                                <MenuItem key={app.id} value={app.id}>
                                    {app.displayName || app.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                {isPlatformEnv && activePlatformAccount && environmentValue !== undefined && onEnvironmentChange && (
                    <EnvironmentMultiSelect
                        value={environmentValue}
                        onChange={onEnvironmentChange}
                        environments={environments}
                        isLoading={isLoading}
                        fullWidth={false}
                        sx={{ width: 240, marginRight: 2 }}
                        valueKey="name"
                    />
                )}

                <Box sx={{ flexGrow: 1 }} />

                {isSearchVisible ? (
                    <TextField
                        sx={{ minWidth: 480 }}
                        variant="outlined"
                        placeholder="Search announcements..."
                        value={searchQuery}
                        onChange={handleSearch}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleSearchVisibility}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        inputRef={textFieldRef}
                    />
                ) : (
                    <IconButton onClick={toggleSearchVisibility}>
                        <SearchIcon />
                    </IconButton>
                )}

                {appMode === AnnouncementAppMode.Platform && isPlatformEnv && activePlatformAccount && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontWeight: 'bold', marginLeft: 2 }}
                        startIcon={<AddIcon />}
                        onClick={() => navigate(PLATFORM_ROUTE_PATHS.CREATE)}
                    >
                        CREATE NEW
                    </Button>
                )}

                {appMode === AnnouncementAppMode.Solution &&
                    selectedApplication &&
                    isAdmin(user, selectedApplication.name) && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ fontWeight: 'bold', marginLeft: 2 }}
                            startIcon={<AddIcon />}
                            onClick={() =>
                                navigate(
                                    SOLUTION_ROUTE_PATHS.CREATE_ANNOUNCEMENT_APP(
                                        selectedApplication.cloudName,
                                        selectedApplication.name
                                    )
                                )
                            }
                        >
                            CREATE NEW
                        </Button>
                    )}
            </Toolbar>
        </Box>
    )
}

export default ListToolBar
