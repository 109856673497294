import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tooltip, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useContext } from 'react'
import { CloudsContext } from 'src/contexts/CloudsContext'
import { PLATFORM_ROUTE_PATHS, SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'

const BackButton = ({
    cloudName: cloudNameProp,
    applicationName: applicationNameProp,
    mode = AnnouncementAppMode.Solution,
}: {
    cloudName?: string
    applicationName?: string | null
    mode?: AnnouncementAppMode
}) => {
    const { cloudNameParam, applicationNameParam } = useParams()
    const { clouds } = useContext(CloudsContext)
    const cloudName = cloudNameProp || cloudNameParam
    const activeCloud = clouds?.find(c => c.name === cloudName) || null
    const applicationName = applicationNameProp || applicationNameParam || activeCloud?.applications[0].name || null
    const navigate = useNavigate()

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Tooltip title="Back">
                <IconButton
                    color="secondary"
                    onClick={
                        applicationName
                            ? () =>
                                  navigate(
                                      SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(
                                          cloudName || '',
                                          applicationName || ''
                                      )
                                  )
                            : mode === AnnouncementAppMode.Platform
                            ? () => navigate(PLATFORM_ROUTE_PATHS.HOME)
                            : () => navigate(SOLUTION_ROUTE_PATHS.SELECT)
                    }
                >
                    <ArrowBackIcon />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default BackButton
