import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'
import { lightTheme, darkTheme } from '../lib/theme'

interface ThemeContextType {
    themeMode: string
    toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

interface CustomThemeProviderProps {
    children: ReactNode
}

export const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({ children }) => {
    const [themeMode, setThemeMode] = useState<string>('dark')

    useEffect(() => {
        const savedTheme = localStorage.getItem('announcements_theme_mode') || 'dark'
        setThemeMode(savedTheme)
    }, [])

    useEffect(() => {
        localStorage.setItem('announcements_theme_mode', themeMode)
    }, [themeMode])

    const theme = createTheme(themeMode === 'dark' ? darkTheme : lightTheme)

    const toggleTheme = (): void => {
        setThemeMode(prevMode => (prevMode === 'dark' ? 'light' : 'dark'))
    }

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider')
    }
    return context
}
