"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputRegex = exports.Video = void 0;
var _core = require("@tiptap/core");
/**
 * Optional: Matches a video markdown syntax like ![video](src)
 */
const inputRegex = exports.inputRegex = /(?:^|\s)(!\[video]\((\S+)\))$/;

/**
 * This extension allows you to insert videos.
 */
const Video = exports.Video = _core.Node.create({
  name: 'video',
  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {}
    };
  },
  inline() {
    return this.options.inline;
  },
  group() {
    return this.options.inline ? 'inline' : 'block';
  },
  draggable: true,
  addAttributes() {
    return {
      src: {
        default: null
      },
      controls: {
        default: true,
        parseHTML: element => element.hasAttribute('controls'),
        renderHTML: attributes => {
          return attributes.controls ? {} : {
            controls: false
          };
        }
      },
      autoplay: {
        default: false,
        parseHTML: element => element.hasAttribute('autoplay'),
        renderHTML: attributes => {
          return attributes.autoplay ? {
            autoplay: true
          } : {};
        }
      },
      loop: {
        default: false,
        parseHTML: element => element.hasAttribute('loop'),
        renderHTML: attributes => {
          return attributes.loop ? {
            loop: true
          } : {};
        }
      },
      muted: {
        default: false,
        parseHTML: element => element.hasAttribute('muted'),
        renderHTML: attributes => {
          return attributes.muted ? {
            muted: true
          } : {};
        }
      },
      poster: {
        default: null
      },
      width: {
        default: null
      },
      height: {
        default: null
      }
    };
  },
  parseHTML() {
    return [{
      tag: this.options.allowBase64 ? 'video[src]' : 'video[src]:not([src^="data:"])'
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ['video', (0, _core.mergeAttributes)(this.options.HTMLAttributes, HTMLAttributes)];
  },
  addCommands() {
    return {
      setVideo: options => ({
        commands
      }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options
        });
      }
    };
  },
  addInputRules() {
    return [(0, _core.nodeInputRule)({
      find: inputRegex,
      type: this.type,
      getAttributes: match => {
        const [,, src] = match;
        return {
          src
        };
      }
    })];
  }
});