import PlatformHomePage from '../views/platform/PlatformAnnouncementListPage'
import PlatformCreateAnnouncementPage from '../views/platform/CreatePlatformAnnouncementPage'
import PlatformAnnouncementDetailsPage from '../views/platform/PlatformAnnouncementDetailsPage'
import { PLATFORM_BASE, PLATFORM_ROUTE_PATHS } from '../lib/routePath'
import UpdateAnnouncement from 'src/views/platform/UpdatePlatformAnnouncement'

export const platformRoutes = [
    {
        path: PLATFORM_ROUTE_PATHS.HOME,
        component: PlatformHomePage,
        isAdminOnly: false,
    },
    {
        path: PLATFORM_ROUTE_PATHS.CREATE,
        component: PlatformCreateAnnouncementPage,
        isAdminOnly: true,
    },
    {
        path: PLATFORM_ROUTE_PATHS.UPDATE,
        component: UpdateAnnouncement,
        isAdminOnly: true,
    },
    {
        path: `${PLATFORM_BASE}/announcement/:id`,
        component: PlatformAnnouncementDetailsPage,
        isAdminOnly: false,
    },
]
