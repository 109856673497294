"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  create: data => ({
    baseURL: integration,
    method: 'post',
    url: `logs`,
    data
  })
});
exports.default = _default;