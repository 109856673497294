import React from 'react'
import { Box, TextField } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { DateTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { JSX } from 'react/jsx-runtime'
import { PickersActionBar, PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'

const StepPublishDateAndTime: React.FC = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const typeValue = useWatch({
        control,
        name: 'type',
    })
    const startDateValue = useWatch({
        control,
        name: 'startDate',
    })

    const CustomActionBar = (props: JSX.IntrinsicAttributes & PickersActionBarProps) => (
        <PickersActionBar {...props} actions={['clear', 'cancel', 'accept']} />
    )

    return (
        <Box>
            <Controller
                name="startDate"
                control={control}
                rules={{ required: 'Start Date & Time is required' }}
                render={({ field }) => (
                    <DateTimePicker
                        {...field}
                        label="Start Date & Time"
                        renderInput={params => (
                            <TextField
                                {...params}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                error={!!errors.startDate}
                                helperText={errors.startDate ? 'Start Date & Time is required' : ''}
                            />
                        )}
                    />
                )}
            />

            {typeValue === 'maintenance' && (
                <Controller
                    name="endDate"
                    control={control}
                    rules={{
                        validate: value =>
                            !value ||
                            moment(value).isAfter(moment(startDateValue)) ||
                            'End Date & Time must be after Start Date & Time',
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <DateTimePicker
                            {...field}
                            label="End Date & Time"
                            minDateTime={startDateValue ? moment(startDateValue) : null}
                            disabled={!startDateValue || !moment(startDateValue).isValid()}
                            components={{
                                ActionBar: CustomActionBar, // Use 'components' instead of 'slots'
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    error={!!error}
                                    helperText={error ? error.message : ''}
                                />
                            )}
                        />
                    )}
                />
            )}
        </Box>
    )
}

export default StepPublishDateAndTime
