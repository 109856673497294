"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lowercaseFirstLetter = void 0;
exports.uniqueString = uniqueString;
exports.uppercaseFirstLetter = void 0;
const uppercaseFirstLetter = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};
exports.uppercaseFirstLetter = uppercaseFirstLetter;
const lowercaseFirstLetter = string => {
  return string ? string.charAt(0).toLowerCase() + string.slice(1) : string;
};

/**
 * Make a string unique matching against other usedStrings adding/incrementing an index at the end surrounded by adornments
 * @param input value to make unique
 * @param usedStrings array of used strings not to collide with
 * @param preAdorn (default "-") what to place before the increment index
 * @param postAdorn (optional) what to place after the increment index
 */
exports.lowercaseFirstLetter = lowercaseFirstLetter;
function uniqueString(input, usedStrings, preAdorn = '-', postAdorn = '') {
  // Remove index from input if it already exists (helpful for copying an already existing copy)
  let correctedInput = input;
  if (input.substring(input.length - postAdorn.length) === postAdorn) {
    const withoutPostAdorn = input.substring(0, input.length - postAdorn.length);
    const indexMatch = withoutPostAdorn.match(/\d+$/);
    if (indexMatch) {
      const indexString = indexMatch[0];
      const withoutIndex = withoutPostAdorn.substring(0, withoutPostAdorn.length - indexString.length);
      if (withoutIndex.substring(withoutIndex.length - preAdorn.length) === preAdorn) {
        correctedInput = withoutIndex.substring(0, withoutIndex.length - preAdorn.length);
      }
    }
  }

  // return the first input with index that is unique
  for (let i = 0; i < 100; i++) {
    const withIndex = `${correctedInput}${i ? `${preAdorn}${i}${postAdorn}` : ''}`;
    if (!usedStrings.some(n => n === withIndex)) return withIndex;
  }
}