"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  visuals
}) => generator({
  cloneDashboard: (appName, copyId, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/dashboards/${copyId}`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  cloneWidget: (appName, copyId, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/widgets/${copyId}`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createDashboard: (appName, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/dashboards`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createDashboardDraft: (appName, id, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/dashboards/${id}/drafts`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createModule: (appName, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/modules`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createModuleDraft: (appName, id, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/modules/${id}/drafts`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createWidget: (appName, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/widgets`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createWidgetDraft: (appName, id, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/widgets/${id}/drafts`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  createWidgetOnDashboard: (appName, dashboardId, data, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/dashboards/${dashboardId}/widgets`,
    data,
    config: {
      params: {
        view
      }
    }
  }),
  deleteModule: (appName, id, view) => ({
    baseURL: visuals,
    method: 'delete',
    url: `applications/${appName}/modules/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  deleteDashboard: (appName, id, view) => ({
    baseURL: visuals,
    method: 'delete',
    url: `applications/${appName}/dashboards/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  deleteWidget: (appName, id, view) => ({
    baseURL: visuals,
    method: 'delete',
    url: `applications/${appName}/widgets/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  getDashboardById: (appName, id, includeWidgets, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/dashboards/${id}`,
    config: {
      params: {
        includeWidgets,
        view
      }
    },
    then: body => {
      if (!includeWidgets) return body;
      // add props to match visor widget shape
      return {
        ...body,
        widgets: body.widgets.map(w => ({
          ...w,
          Id: w.id,
          Title: w.title
        }))
      };
    }
  }),
  getDashboardDraftsById: (appName, id, includeWidgets, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/dashboards/${id}/drafts`,
    config: {
      params: {
        includeWidgets,
        view
      }
    }
  }),
  getDashboardsWithWidgetId: (appName, widgetId, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/widgets/${widgetId}/dashboards`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getDraftCounts: (appName, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/drafts/counts`,
    config: {
      params: {
        view
      }
    }
  }),
  getAllDrafts: (appName, filter) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/drafts`,
    config: {
      params: filter
    }
  }),
  getMetaChanges: (appName, filter) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/changes`,
    config: {
      params: filter
    }
  }),
  getChangesById: (appName, id) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/changes/${id}`
  }),
  getDraftsByType: (appName, type, filter) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/${type}s/drafts`,
    config: {
      params: filter
    }
  }),
  getModuleById: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/modules/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  getModuleDraftsById: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/modules/${id}/drafts`,
    config: {
      params: {
        view
      }
    }
  }),
  getModulesByApp: (appName, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/modules`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getModulesWithDashboardId: (appName, id, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/dashboards/${id}/modules`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getModulesWithWidgetId: (appName, id, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/widgets/${id}/modules`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getMetaDashboards: (appName, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/dashboards`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getPublished: (appName, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/publish`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getPublishById: (appName, id) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/publish/id`
  }),
  getPublishDistinctList: (appName, property, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/publish/list/${property}`,
    config: {
      params: {
        view
      }
    }
  }),
  getDashboardDistinctList: (appName, type, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/dashboards/list/${type}`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getModuleByVisorId: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/visor-modules/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  getPerspectiveByVisorId: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/visor-perspectives/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  getWidgetById: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/widgets/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  getWidgetByVisorId: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/visor-widgets/${id}`,
    config: {
      params: {
        view
      }
    }
  }),
  getWidgetDraftsById: (appName, id, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/widgets/${id}/drafts`,
    config: {
      params: {
        view
      }
    }
  }),
  getWidgets: (appName, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/widgets`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  getWidgetsWithNavigationPerspectiveId: (appName, dashboardId, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/dashboards/${dashboardId}/widgets`,
    config: {
      params: {
        view
      }
    }
  }),
  getWidgetDistinctList: (appName, type, filter, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/widgets/list/${type}`,
    config: {
      params: {
        ...filter,
        view
      }
    }
  }),
  partialUpdate: (appName, visualType, id, path, value) => ({
    baseURL: visuals,
    method: 'patch',
    url: `applications/${appName}/${visualType}s/${id}`,
    data: {
      path,
      value
    }
  }),
  publish: (appName, view) => ({
    baseURL: visuals,
    method: 'post',
    url: `applications/${appName}/publish`,
    config: {
      params: {
        view
      }
    }
  }),
  publishErrorCheck: (appName, view) => ({
    baseURL: visuals,
    method: 'get',
    url: `applications/${appName}/publish/errors`,
    config: {
      params: {
        view
      }
    }
  }),
  updateModule: (appName, id, data) => ({
    baseURL: visuals,
    method: 'put',
    url: `applications/${appName}/modules/${id}`,
    data
  }),
  updateDashboard: (appName, id, data) => ({
    baseURL: visuals,
    method: 'put',
    url: `applications/${appName}/dashboards/${id}`,
    data
  }),
  updateWidget: (appName, id, data) => ({
    baseURL: visuals,
    method: 'put',
    url: `applications/${appName}/widgets/${id}`,
    data
  })
});
exports.default = _default;