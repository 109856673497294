import { Box, Container, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from 'src/components/Loading'
import { CloudsContext } from 'src/contexts/CloudsContext'
import CloudList from 'src/components/CloudList'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

const useStyles = makeStyles(({ custom }) => {
    const { maxWidth, paddingBig } = custom.announcementsApp || {}
    return {
        container: {
            height: '100%',
            maxWidth: maxWidth || 1400,
            margin: 'auto',
            paddingLeft: paddingBig || 40,
            paddingRight: paddingBig || 40,
            display: 'flex',
            flexDirection: 'column',
        },
        heading: {
            fontSize: '1.75rem',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        noCloudsContainer: {
            flex: 1,
            my: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        noCloudsText: {
            fontSize: '1.25rem',
            textAlign: 'center',
        },
    }
})

const CloudSelection = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { preventAutoRedirect: boolean }
    const { preventAutoRedirect = false } = state || {}
    const { clouds, isLoadingClouds } = useContext(CloudsContext)

    const handleSelect = (cloudName: string, applicationName: string) => {
        navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(cloudName, applicationName))
        sessionStorage.setItem('announcements_selected_app_name', applicationName)
        sessionStorage.setItem('announcements_selected_cloud_name', cloudName)
    }

    useEffect(() => {
        const savedCloudName = sessionStorage.getItem('announcements_selected_cloud_name')
        const savedAppName = sessionStorage.getItem('announcements_selected_app_name')
        if (savedCloudName && savedAppName && !preventAutoRedirect) {
            navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(savedCloudName, savedAppName), { replace: true })
        }
    }, [navigate, preventAutoRedirect])

    return (
        <Box className={classes.container}>
            {isLoadingClouds ? (
                <Loading message="Getting things ready for you..." />
            ) : clouds && clouds.length > 0 ? (
                <Container
                    maxWidth="sm"
                    sx={{ flex: 1, my: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <Typography className={classes.heading} variant="h5" gutterBottom>
                        Select Your Cloud!
                    </Typography>
                    <CloudList clouds={clouds} onSelect={handleSelect} />
                </Container>
            ) : (
                <Container className={classes.noCloudsContainer}>
                    <Typography className={classes.noCloudsText}>
                        You do not have access to any clouds at the moment. Please contact your administrator to request
                        access.
                    </Typography>
                </Container>
            )}
        </Box>
    )
}

export default CloudSelection
