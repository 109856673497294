"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useExpressionFunctionSuggestions;
var _react = require("react");
var _lodash = require("lodash");
let expressionFunctions = [];
function useExpressionFunctionSuggestions(getter, hideAggRows) {
  const [_, render] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    if (expressionFunctions.length) return;
    getter().then(data => {
      expressionFunctions = hideAggRows ? data.filter(f => f.id !== 41) : data;
      render(s => !s);
    });
  }, []);
  return {
    expressionFunctions,
    suggestions: createSuggestions(expressionFunctions, hideAggRows)
  };
}
function createSuggestions(functions, hideAggRows) {
  const sectionTitles = (0, _lodash.uniq)(functions.map(f => f.categoryName));
  return sectionTitles.map(label => ({
    type: 'section',
    label,
    suggestions: functions.filter(f => f.categoryName === label).map(f => ({
      label: f.name,
      value: f.value,
      description: f.description,
      example: f.example
    }))
  }));
}