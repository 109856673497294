import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Loading from '../components/Loading'
import userManager from '../lib/userManager'
import { SolutionAuthContext } from '../contexts/SolutionAuthContext'

export default function Login() {
    const navigate = useNavigate()
    const { redirectTo, setAuthUser } = useContext(SolutionAuthContext)

    useEffect(() => {
        async function go() {
            const user = await userManager.getUser()
            if (!user) {
                userManager.signinRedirect({
                    state: { ssoRedirectTo: redirectTo },
                    extraQueryParams: {
                        allowedProviders: 'microsoft',
                    },
                })
            } else {
                setAuthUser(user)
                navigate(redirectTo)
            }
        }
        go()
    }, [])

    return <Loading title="One Moment." message="Redirecting you..." />
}
