import { createRoot } from 'react-dom/client'
import App from './App'
import './lib/globalTypes'
import GlobalProviders from './providers/GlobalProviders'

createRoot(document.getElementById('root')).render(
    <GlobalProviders>
        <App />
    </GlobalProviders>
)
