"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = functionsFromString;
/** Return an array of function names from an expression checking for any text that ends with "(" */
function functionsFromString(expression) {
  const matches = expression.match(/([a-zA-Z]+)\(/g);
  if (!matches) return [];
  return matches.map(f => f.slice(0, -1));
}