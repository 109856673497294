"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arraysAreEqual = void 0;
const arraysAreEqual = (a, b) => {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] instanceof Array && b[i] instanceof Array) {
      // recursive
      if (!arraysAreEqual(a[i], b[i])) return false;
    } else if (a[i] !== b[i]) return false;
  }
  return true;
};
exports.arraysAreEqual = arraysAreEqual;