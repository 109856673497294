import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, ListItemText, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Cloud } from 'src/contexts/CloudsContext'

const CloudListItem: React.FC<{ cloud: Cloud; onSelect: (cloudName: string, applicationName: string) => void }> = ({
    cloud,
    onSelect,
}) => (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${cloud.name}-content`}
            id={`panel-${cloud.name}-header`}
        >
            <Typography variant="h6">{cloud.name}</Typography>
        </AccordionSummary>

        <AccordionDetails>
            {cloud.applications && cloud.applications.length > 0 ? (
                cloud.applications.map(application => (
                    <Button
                        key={application.id}
                        onClick={() => onSelect(cloud.name, application.name)}
                        sx={{ display: 'block', width: '100%', textAlign: 'left', padding: 1, marginBottom: 1 }}
                    >
                        <ListItemText primary={application.displayName || application.name} />
                    </Button>
                ))
            ) : (
                <Typography variant="body2" color="textSecondary">
                    No applications available.
                </Typography>
            )}
        </AccordionDetails>
    </Accordion>
)

export default CloudListItem
