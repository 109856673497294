"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useHotKey;
var _lodash = require("lodash");
var _react = require("react");
const isMac = navigator.userAgent.indexOf('Mac OS') > -1;

/** Listen to key and run callback */
function useHotKey(config, callback) {
  const {
    key,
    options
  } = config;
  const _options = (0, _react.useRef)(options || {});
  if (!(0, _lodash.isEqual)(_options.current, options)) _options.current = options;
  const {
    altKey,
    ctrlKey,
    shiftKey
  } = _options.current ?? {};
  (0, _react.useEffect)(() => {
    function listenForHotKeys(e) {
      const alt = isMac ? e.ctrlKey : e.altKey;
      const ctrl = isMac ? e.metaKey : e.ctrlKey;
      if (e.key.toLowerCase() !== key.toLowerCase()) return;
      if (altKey && !alt || !altKey && alt) return;
      if (ctrlKey && !ctrl || !ctrlKey && ctrl) return;
      if (shiftKey && !e.shiftKey || !shiftKey && e.shiftKey) return;
      e.preventDefault();
      callback(e);
    }
    document.addEventListener('keydown', listenForHotKeys);
    return () => {
      document.removeEventListener('keydown', listenForHotKeys);
    };
  }, [callback, key, _options.current]);
}