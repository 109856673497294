import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import PreviewButton from './PreviewButton'
import { useFormContext } from 'react-hook-form'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'

interface AnnouncementStepProps {
    step: number
    isUpdating?: boolean
    isDraft?: boolean
    isFinalStep?: boolean
    handleBack: () => void
    handleNext: () => void
    handleDraft: () => void
    handleSubmit: () => void
    children?: React.ReactNode
    disabled?: boolean
    mode?: AnnouncementAppMode
}

const AnnouncementStep: React.FC<AnnouncementStepProps> = ({
    step,
    isUpdating = false,
    isDraft = false,
    isFinalStep = false,
    handleBack,
    handleNext,
    handleSubmit,
    handleDraft,
    children,
    disabled = false,
    mode = AnnouncementAppMode.Solution,
}) => {
    const { getValues } = useFormContext()
    const { title, description, startDate } = getValues()

    return (
        <>
            <Card sx={{ marginTop: 3, marginBottom: 3 }}>
                <CardContent>{children ? children : <Typography>Step content here</Typography>}</CardContent>
            </Card>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button disabled={step === 0 || disabled} onClick={handleBack}>
                    Back
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    {isFinalStep && (
                        <PreviewButton mode={mode} title={title} description={description} startDate={startDate} />
                    )}
                    {isFinalStep && !isUpdating && (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleDraft}
                            disabled={disabled}
                            sx={{ marginRight: 2 }}
                        >
                            Save As Draft
                        </Button>
                    )}
                    {isFinalStep && isUpdating && isDraft && (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleDraft}
                            disabled={disabled}
                            sx={{ marginRight: 2 }}
                        >
                            Save Draft
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={isFinalStep ? handleSubmit : handleNext}
                        disabled={disabled}
                    >
                        {isFinalStep ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default AnnouncementStep
