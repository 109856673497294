"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = makeId;
/** make alpha-numeric id of given length (default 10) */
function makeId(length = 10) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}