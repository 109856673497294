import { BrowserRouter as Router } from 'react-router-dom'
import { cleanDirectory } from 'genesis-suite/utils'
import { StyledEngineProvider } from '@mui/material'
import { SwalProvider } from 'genesis-suite/components'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { CustomThemeProvider } from 'src/contexts/ThemeContext'
import { AppModeProvider } from 'src/contexts/AppModeContext'

const GlobalProviders = ({ children }) => {
    return (
        <Router basename={cleanDirectory(process.env.PUBLIC_URL)}>
            <AppModeProvider>
                <SwalProvider>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <StyledEngineProvider injectFirst>
                            <CustomThemeProvider>
                                <LocalizationProvider dateAdapter={AdapterMoment}>{children}</LocalizationProvider>
                            </CustomThemeProvider>
                        </StyledEngineProvider>
                    </SnackbarProvider>
                </SwalProvider>
            </AppModeProvider>
        </Router>
    )
}

export default GlobalProviders
