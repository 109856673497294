import React, { useEffect, useState } from 'react'
import { Box, IconButton, CircularProgress, Card, CardMedia, Typography, Badge } from '@mui/material'
import { DropZone, FILE_TYPES } from 'genesis-suite/components'
import { platformService, uploadService } from 'src/lib/services'
import { PictureAsPdf, Description, Close } from '@mui/icons-material'
import { useFormContext } from 'react-hook-form'
import { App } from 'src/types'
import { AnnouncementAppMode } from 'genesis-suite/types/announcementTypes'

interface Attachment {
    file: File
    name: string
    type: string
    token?: string
    uploading: boolean
}

const FileUpload = ({
    mode = AnnouncementAppMode.Solution,
    selectedApplication,
}: {
    mode?: AnnouncementAppMode
    selectedApplication: App | null
}) => {
    const { setValue, getValues } = useFormContext()
    const [attachments, setAttachments] = useState<Attachment[]>(getValues('attachments') || [])
    const [uploadError, setUploadError] = useState<string | null>(null)

    useEffect(() => {
        setValue('attachments', attachments)
    }, [attachments, setValue])

    const handleDrop = (acceptedFiles: File[]) => {
        const newAttachments = acceptedFiles.map(file => ({ file, name: file.name, type: file.type, uploading: true }))
        const updatedAttachments = [...attachments, ...newAttachments]
        setAttachments(updatedAttachments)
        setValue('attachments', updatedAttachments)

        acceptedFiles.forEach(async file => {
            if (mode === AnnouncementAppMode.Platform) {
                try {
                    const fileData = new FormData()
                    fileData.append('file', file)
                    const response = await platformService.uploadFile(fileData, file.name, false)
                    const token = response.fileId
                    const updatedAttachment = { file, name: file.name, type: file.type, token, uploading: false }
                    setAttachments(current => current.map(att => (att.file === file ? updatedAttachment : att)))
                    setValue('attachments', (current: Attachment[]) =>
                        current.map(att => (att.file === file ? updatedAttachment : att))
                    )
                } catch (error) {
                    setUploadError('File upload failed. Please try again.')
                    setAttachments(current => current.filter(att => att.file !== file))
                    setValue('attachments', (current: Attachment[]) => current.filter(att => att.file !== file))
                }
            } else {
                try {
                    if (!selectedApplication) return
                    const fileData = new FormData()
                    fileData.append('file', file)
                    const response = await uploadService.uploadFile(
                        fileData,
                        file.name,
                        selectedApplication.name,
                        false
                    )
                    const token = response
                    const updatedAttachment = { file, name: file.name, type: file.type, token, uploading: false }
                    setAttachments(current => current.map(att => (att.file === file ? updatedAttachment : att)))
                    setValue('attachments', (current: Attachment[]) =>
                        current.map(att => (att.file === file ? updatedAttachment : att))
                    )
                } catch (error) {
                    setUploadError('File upload failed. Please try again.')
                    setAttachments(current => current.filter(att => att.file !== file))
                    setValue('attachments', (current: Attachment[]) => current.filter(att => att.file !== file))
                }
            }
        })
    }

    const handleDelete = (fileName: string) => {
        setAttachments(current => current.filter(att => att.name !== fileName))
        setValue('attachments', (current: Attachment[]) => current.filter(att => att.name !== fileName))
    }

    return (
        <Box>
            <DropZone
                onDrop={handleDrop}
                accept={Object.values(FILE_TYPES).join(',')}
                message="Drag 'n' drop some files here, or click to select files"
            />
            {uploadError && (
                <Box mt={2} color="error.main">
                    {uploadError}
                </Box>
            )}
            <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                {attachments.map((attachment, index) => (
                    <Badge
                        key={index}
                        badgeContent={
                            !attachment.uploading && (
                                <IconButton
                                    sx={{
                                        color: 'white',
                                        bgcolor: 'error.main',
                                        '&:hover': { bgcolor: 'error.dark' },
                                    }}
                                    size="small"
                                    onClick={() => handleDelete(attachment.name)}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            )
                        }
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        overlap="circular"
                    >
                        <Card sx={{ width: 140, height: 140, position: 'relative' }}>
                            {attachment.uploading && (
                                <CircularProgress
                                    color="secondary"
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            )}
                            {attachment.type.startsWith('image/') && attachment.file ? (
                                <CardMedia
                                    component="img"
                                    image={URL.createObjectURL(attachment.file)}
                                    title={attachment.name}
                                    sx={{ height: 140 }}
                                />
                            ) : attachment.type.startsWith('video/') && attachment.file ? (
                                <CardMedia
                                    component="video"
                                    src={URL.createObjectURL(attachment.file)}
                                    title={attachment.name}
                                    sx={{ height: 140 }}
                                    controls
                                />
                            ) : attachment.type === 'application/pdf' ? (
                                <Typography variant="body2" fontWeight="bold" sx={{ padding: 2 }}>
                                    <PictureAsPdf /> {attachment.name}
                                </Typography>
                            ) : attachment.type ===
                              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                                <Typography variant="body2" fontWeight="bold" sx={{ padding: 2 }}>
                                    <Description /> {attachment.name}
                                </Typography>
                            ) : (
                                <Typography variant="body2" fontWeight="bold" sx={{ padding: 2 }}>
                                    <Description /> {attachment.name}
                                </Typography>
                            )}
                        </Card>
                    </Badge>
                ))}
            </Box>
        </Box>
    )
}

export default FileUpload
