import { Box, CircularProgress, Container, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { AnnouncementTile } from 'genesis-suite/components'
import ListBanner from 'src/components/ListBanner'
import ListToolBar from 'src/components/ListToolBar'
import PortalAnnouncements from 'src/components/PortalAnnouncements'
import { useNavigate, useParams } from 'react-router-dom'
import { announcementService, moduleService } from 'src/lib/services'
import { SolutionAnnouncement } from 'genesis-suite/types/announcementTypes'
import { Cloud, Portal } from 'src/types'
import DraftAnnouncements from 'src/components/DraftAnnouncements'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

const useStyles = makeStyles(({ custom }) => ({
    errorContainer: {
        height: '100%',
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    errorText: {
        fontSize: '1.25rem',
        textAlign: 'center',
    },
    maxWidthAndPadding: {
        maxWidth: custom.announcementsApp?.maxWidth,
        margin: 'auto',
        paddingLeft: custom.announcementsApp?.paddingBig,
        paddingRight: custom.announcementsApp?.paddingBig,
    },
}))

const AnnouncementList = ({ activeCloud = null }: { activeCloud?: Cloud | null }) => {
    const { applicationName = null } = useParams()
    const classes = useStyles()
    const activeApplication = activeCloud?.applications.find(a => a.name === applicationName) || null
    const navigate = useNavigate()

    const [isAnnouncementsInitialised, setIsAnnouncementsInitialised] = useState(false)
    const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false)
    const [announcements, setAnnouncements] = useState<SolutionAnnouncement[]>([])
    const [isPortalsIntialised, setIsPortalsInitialised] = useState(false)
    const [isPortalsLoading, setIsPortalsLoading] = useState(false)
    const [portals, setPortals] = useState<Portal[]>([])
    const [portalsWithAnnouncements, setPortalsWithAnnouncements] = useState<Portal[]>([])
    const [isDraftsInitialised, setIsDraftsInitialised] = useState(false)
    const [isDraftsLoading, setIsDraftsLoading] = useState(false)
    const [drafts, setDrafts] = useState<SolutionAnnouncement[]>([])

    useEffect(() => {
        if (!activeCloud) return
        if (!activeApplication) {
            const defaultApp = activeCloud?.applications[0]
            navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENTS_LIST_APP(activeCloud.name, defaultApp?.name))
        }
    }, [activeCloud, applicationName])

    useEffect(() => {
        if (!activeApplication) return
        const fetchPortals = async () => {
            setIsPortalsLoading(true)
            try {
                const response = await moduleService.getAll(activeApplication.name, true)
                const portals = response.map((p: Portal) => {
                    p.cloudId = activeApplication.cloudId
                    p.applicationId = activeApplication.id
                    p.cloudName = activeApplication.cloudName
                    p.applicationName = activeApplication.name
                    p.announcements = null
                    return p
                })
                setPortals(portals)
                activeApplication.portals = portals
            } catch (err) {
                console.error(err)
                setPortals([])
            } finally {
                setIsPortalsLoading(false)
                setIsPortalsInitialised(true)
            }
        }
        const fetchAnnouncements = async () => {
            setIsAnnouncementsLoading(true)
            try {
                const response = await announcementService.getAnnouncements({
                    CloudId: activeApplication.cloudId,
                    AppId: activeApplication.id,
                })

                const announcements = response.announcements
                setAnnouncements(announcements)
                activeApplication.announcements = announcements || []

                const portalIds: { portalId: string; announcementsCount: number }[] = response.portalInfo
                const allPortals = activeApplication.portals || []

                const filteredPortals = allPortals?.filter((portal: Portal) => {
                    const matchedPortalId = portalIds.find(portalIdObj => portalIdObj.portalId === portal.id)
                    if (matchedPortalId) {
                        portal.announcementsCount = matchedPortalId?.announcementsCount || 0
                        return portal
                    }
                })
                setPortalsWithAnnouncements(filteredPortals)
            } catch (err) {
                console.error(err)
                setAnnouncements([])
            } finally {
                setIsAnnouncementsLoading(false)
                setIsAnnouncementsInitialised(true)
            }
        }
        const fetchDrafts = async () => {
            setIsDraftsLoading(true)
            try {
                const drafts: SolutionAnnouncement[] = await announcementService.getDrafts({
                    CloudId: activeApplication.cloudId,
                })
                setDrafts(drafts)
            } catch (err) {
                console.error(err)
                setDrafts([])
            } finally {
                setIsDraftsLoading(false)
                setIsDraftsInitialised(true)
            }
        }
        fetchAnnouncements()
        fetchPortals()
        fetchDrafts()
    }, [activeApplication])
    const [searchTerm, setSearchTerm] = useState('')
    const handleSearch = (term: string) => {
        setSearchTerm(term.toLowerCase())
    }
    const filteredAnnouncements = announcements.filter(announcement =>
        announcement.title.toLowerCase().includes(searchTerm)
    )
    const navigateToDetailsPage = (announcement: SolutionAnnouncement) => {
        navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENT_DETAILS(activeCloud?.name || '', announcement.id || ''))
    }

    if (!activeCloud) {
        return (
            <Container className={classes.errorContainer}>
                <Typography className={classes.errorText}>
                    No cloud selected. Please select a cloud to view announcements.
                </Typography>
            </Container>
        )
    }

    return (
        <>
            <ListBanner className={classes.maxWidthAndPadding} cloudName={activeCloud.name || 'Tada Now'} />
            <ListToolBar
                applications={activeCloud.applications}
                selectedApplication={activeApplication}
                toolBarClassName={classes.maxWidthAndPadding}
                onSearch={handleSearch}
            />
            <Container className={classes.maxWidthAndPadding} sx={{ paddingBottom: 2 }}>
                {isDraftsInitialised && drafts.length > 0 && (
                    <DraftAnnouncements drafts={drafts} isLoading={isDraftsLoading} />
                )}
                {isAnnouncementsInitialised &&
                    !isAnnouncementsLoading &&
                    isPortalsIntialised &&
                    !isPortalsLoading &&
                    filteredAnnouncements.length === 0 &&
                    portals.length === 0 && (
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className={classes.errorText}>No announcements found.</Typography>
                        </Box>
                    )}
                {!isAnnouncementsInitialised || isAnnouncementsLoading ? (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    filteredAnnouncements.map(announcement => (
                        <AnnouncementTile
                            key={announcement.id}
                            announcement={announcement}
                            onActionClick={() => navigateToDetailsPage(announcement)}
                        />
                    ))
                )}
                {!(!isAnnouncementsInitialised || isAnnouncementsLoading) &&
                (!isPortalsIntialised || isPortalsLoading) ? (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    portalsWithAnnouncements?.map((portal, i) => (
                        <PortalAnnouncements
                            key={portal.id}
                            portal={portal}
                            searchTerm={searchTerm}
                            sx={{ marginBottom: 2, marginTop: i === 0 ? 2 : 0 }}
                        />
                    ))
                )}
            </Container>
        </>
    )
}

export default AnnouncementList
