"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useVoice;
var _react = require("react");
var _helpers = require("../utils/helpers");
const SUBSCRIPTION_KEY = 'e76cfe077740408aba8485243e6300fb';
let recognizer;
function useVoice() {
  const [text, setText] = (0, _react.useState)('');
  const [isListening, setIsListening] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    if (!recognizer) loadScript();
  }, []);
  const startListening = () => start(setText, setIsListening);
  return {
    isListening,
    text,
    startListening,
    stopListening
  };
}
function loadScript() {
  const script = document.createElement('script');
  script.async = true;
  script.id = 'bing-speech-to-text';
  script.src = (0, _helpers.pathToPublicFile)('speech.sdk.bundle.js');
  script.onload = () => {
    setup('Interactive', 'en-US', 'Simple Result', SUBSCRIPTION_KEY);
  };
  document.body.appendChild(script);
}
function setup(recognitionMode, language, format, subscriptionKey) {
  const {
    SDK
  } = window;
  switch (recognitionMode) {
    case 'Interactive':
      recognitionMode = SDK.RecognitionMode.Interactive;
      break;
    case 'Conversation':
      recognitionMode = SDK.RecognitionMode.Conversation;
      break;
    case 'Dictation':
      recognitionMode = SDK.RecognitionMode.Dictation;
      break;
    default:
      recognitionMode = SDK.RecognitionMode.Interactive;
  }
  const os = new SDK.OS(navigator.userAgent, 'Browser', null);
  const device = new SDK.Device('SpeechSample', 'SpeechSample', '1.0.00000');
  const context = new SDK.Context(os, device);
  const speechConfig = new SDK.SpeechConfig(context);
  const config = new SDK.RecognizerConfig(speechConfig, recognitionMode, language, SDK.SpeechResultFormat[format]);
  const authentication = new SDK.CognitiveSubscriptionKeyAuthentication(subscriptionKey);
  recognizer = SDK.CreateRecognizer(config, authentication);
}
function start(setText, onListening) {
  recognizer.Recognize(({
    Name,
    Result
  }) => {
    switch (Name) {
      case 'RecognitionStartedEvent':
        onListening(true);
        break;
      case 'SpeechHypothesisEvent':
        setText(Result.Text);
        break;
      case 'SpeechFragmentEvent':
        setText(s => `${s} ${Result.Text}`);
        break;
      case 'RecognitionEndedEvent':
        onListening(false);
        break;
    }
  });
}
const stopListening = () => recognizer?.AudioSource.TurnOff();