"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  collab
}) => generator({
  getTaskLists: moduleId => ({
    baseURL: collab,
    method: 'get',
    url: 'taskLists',
    config: {
      params: {
        moduleId
      }
    }
  }),
  createList: (moduleId, name) => ({
    baseURL: collab,
    method: 'post',
    url: 'taskLists',
    data: {
      moduleId,
      name
    }
  }),
  updateList: (moduleId, listId, name) => ({
    baseURL: collab,
    method: 'put',
    url: `taskLists/${listId}`,
    config: {
      params: {
        moduleId
      }
    },
    data: {
      name
    }
  }),
  deleteList: (moduleId, listId) => ({
    baseURL: collab,
    method: 'delete',
    config: {
      params: {
        moduleId
      }
    },
    url: `taskLists/${listId}`
  }),
  getTasks: (moduleId, listId) => ({
    baseURL: collab,
    method: 'get',
    url: `taskLists/${listId}/tasks`,
    config: {
      params: {
        moduleId
      }
    }
  }),
  createTask: (moduleId, listId, body) => ({
    baseURL: collab,
    method: 'post',
    url: `taskLists/${listId}/tasks`,
    config: {
      params: {
        moduleId
      }
    },
    data: {
      body
    }
  }),
  updateTask: (moduleId, listId, taskId, data) => ({
    baseURL: collab,
    method: 'put',
    url: `taskLists/${listId}/tasks/${taskId}`,
    config: {
      params: {
        moduleId
      }
    },
    data
  }),
  deleteTask: (moduleId, listId, taskId) => ({
    baseURL: collab,
    method: 'delete',
    config: {
      params: {
        moduleId
      }
    },
    url: `taskLists/${listId}/tasks/${taskId}`
  })
});
exports.default = _default;