import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { applicationService } from 'src/lib/services'
import { SolutionAuthContext } from './SolutionAuthContext'
import { SolutionAnnouncement } from 'genesis-suite/types/announcementTypes'

export interface App {
    cloudId: string
    cloudName: string
    id: string
    name: string
    displayName: string
    announcements?: SolutionAnnouncement[]
}

export interface Cloud {
    id: string
    name: string
    applications: App[]
}

interface CloudsContextProps {
    clouds: Cloud[] | null
    isLoadingClouds: boolean
    isInitialised: boolean
    selectedCloud: Cloud | null
    setSelectedCloud: (_c: Cloud | null) => void
}

const CloudsContext = createContext<CloudsContextProps>({
    clouds: null,
    isLoadingClouds: false,
    isInitialised: false,
    selectedCloud: null,
    setSelectedCloud: () => void {},
})

interface CloudsProviderProps {
    children: ReactNode
}

const CloudsProvider = ({ children }: CloudsProviderProps) => {
    const [clouds, setClouds] = useState<Cloud[] | null>(null)
    const [isLoadingClouds, setIsLoadingClouds] = useState<boolean>(false)
    const [isInitialised, setIsInitialised] = useState<boolean>(false)
    const [selectedCloud, setSelectedCloud] = useState<Cloud | null>(null)
    const navigate = useNavigate()
    const { user, redirectTo } = useContext(SolutionAuthContext)

    useEffect(() => {
        const fetchClouds = async () => {
            setIsLoadingClouds(true)
            try {
                const apps: unknown[] = await applicationService.getApps()
                const typeApps = apps as App[]
                const clouds: Cloud[] = []
                for (const app of typeApps) {
                    const cloudId = app.cloudId
                    const existingCloud = clouds.find(cloud => cloud.id === cloudId)

                    if (!existingCloud) {
                        clouds.push({ id: cloudId, name: app.cloudName, applications: [app] })
                    } else {
                        existingCloud.applications.push(app)
                    }
                }
                const sortedClouds = clouds.sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
                )
                handleNewClouds(sortedClouds)
            } catch (err) {
                console.error(err)
                handleNewClouds(null)
            } finally {
                setIsLoadingClouds(false)
                setIsInitialised(true)
            }
        }
        if (user) {
            fetchClouds()
        } else {
            handleNewClouds(null)
        }
    }, [user])

    const handleNewClouds = (newClouds: Cloud[] | null) => {
        if (!newClouds) {
            setClouds(null)
            handleeNewSelectedCloud(null)
            return
        }
        setClouds(newClouds)
        if (newClouds.length === 1) {
            handleeNewSelectedCloud(newClouds[0])
            return
        }
        const storedCloudId = localStorage.getItem('announcements_selected_cloud_id')
        const newCloud = newClouds.find(cloud => cloud.id === storedCloudId) || null
        handleeNewSelectedCloud(newCloud)
    }

    const handleeNewSelectedCloud = (selectedCloud: Cloud | null) => {
        if (!selectedCloud) {
            setSelectedCloud(null)
            return
        }
        setSelectedCloud(selectedCloud)
        navigate(redirectTo)
        localStorage.setItem('announcements_selected_cloud_id', selectedCloud.id)
    }

    const onSelectedCloudChange = (selectedCloud: Cloud | null) => {
        const cloud = clouds?.find(c => c.id === selectedCloud?.id) || null
        handleeNewSelectedCloud(cloud)
    }

    return (
        <CloudsContext.Provider
            value={{
                clouds,
                isLoadingClouds,
                isInitialised,
                selectedCloud,
                setSelectedCloud: onSelectedCloudChange,
            }}
        >
            {children}
        </CloudsContext.Provider>
    )
}

export { CloudsContext, CloudsProvider }
