import React from 'react'
import clsx from 'classnames'
import { Link, Dialog, Typography, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate, useLocation } from 'react-router-dom'
import { CheckRounded } from '@mui/icons-material'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

const useStyles = makeStyles(({ spacing }) => ({
    paperRoot: {
        width: 500,
        height: 350,
        borderRadius: 4,
        display: 'flex',
        background: '#fff',
        flexFlow: 'column',
        padding: spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    circleWrapper: {
        width: 100,
        margin: 10,
        height: 100,
        float: 'left',
        position: 'relative',
    },
    success: {
        backgroundColor: '#4BB543',
        border: '2.5px dashed #4BB543',
    },
    circle: {
        padding: 2.5,
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: '50%',
        backgroundClip: 'content-box',
        animation: '$spin 10s linear infinite',
    },
    icon: {
        top: '50%',
        height: 60,
        left: '50%',
        color: '#fff',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        '& > svg': {
            width: 55,
            height: 55,
        },
    },
    '@keyframes spin': {
        '100%': {
            transform: 'rotateZ(360deg)',
        },
    },
}))

export default function PasswordSuccess() {
    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state = location.state as any
    const appLink = window.TADA_APIS.TADA_APP

    if (!state?.username) {
        navigate(SOLUTION_ROUTE_PATHS.PASSWORD_RESET)
        return null
    } else
        return (
            <Dialog open={true}>
                <Paper elevation={0} className={classes.paperRoot}>
                    <SuccessCircle />
                    <Typography variant="h6">{state?.username}, your password has successfully been set.</Typography>
                    <Typography>
                        Click <Link href={appLink}>here</Link> to redirect to the Tada App.
                    </Typography>
                </Paper>
            </Dialog>
        )
}

function SuccessCircle() {
    const classes = useStyles()
    return (
        <div className={classes.circleWrapper}>
            <div className={clsx(classes.success, classes.circle)} />
            <div className={classes.icon}>
                <CheckRounded />
            </div>
        </div>
    )
}
