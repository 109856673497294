"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  tadaai
}) => generator({
  explain: data => ({
    baseURL: tadaai,
    method: 'post',
    url: `explain`,
    data: data
  }),
  createSession: data => ({
    baseURL: tadaai,
    method: 'post',
    url: `createSession`,
    data: data
  }),
  uploadFile: (fileData, sessionId) => ({
    baseURL: tadaai,
    method: 'post',
    url: `uploadFile`,
    data: fileData,
    config: {
      params: {
        sessionId
      }
    }
  }),
  getSummary: data => ({
    baseURL: tadaai,
    method: 'post',
    url: `summarize`,
    data: data
  })
});
exports.default = _default;