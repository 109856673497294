import React from 'react'
import Typography from '@mui/material/Typography'

const WelcomeDisplay = props => {
    const { username } = props
    return (
        <Typography variant="subtitle1">
            {`Welcome ${username}!`}
        </Typography>
    )
}

export default WelcomeDisplay
