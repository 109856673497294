import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import ListBanner from 'src/components/ListBanner'
import ListToolBar from 'src/components/ListToolBar'
import { PlatformAnnouncement, PlatformEnvironment } from 'genesis-suite/types/announcementTypes'
import { AnnouncementTile } from 'genesis-suite/components'
import { PLATFORM_ROUTE_PATHS } from 'src/lib/routePath'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Container, Typography } from '@mui/material'
import DraftAnnouncements from 'src/components/DraftAnnouncements'
import { platformService } from 'src/lib/services'
import { useAppMode } from 'src/contexts/AppModeContext'
import { usePlatformAuth } from 'src/contexts/PlatformAuthContext'
import { PlatformAnnouncementFetchData } from 'genesis-suite/types/visualTypes'
import { usePlatformEnvironments } from 'src/contexts/PlatformEnvironmentsContext'

const useStyles = makeStyles(({ custom }) => ({
    errorContainer: {
        height: '100%',
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    errorText: {
        fontSize: '1.25rem',
        textAlign: 'center',
    },
    maxWidthAndPadding: {
        maxWidth: custom.announcementsApp?.maxWidth,
        margin: 'auto',
        paddingLeft: custom.announcementsApp?.paddingBig,
        paddingRight: custom.announcementsApp?.paddingBig,
    },
}))

const PlatformAnnouncementListPage: React.FC = () => {
    const { isPlatformEnv } = useAppMode()
    const { activeAccount } = usePlatformAuth()
    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState('')
    const [isAnnouncementsInitialised, setIsAnnouncementsInitialised] = useState(false)
    const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false)
    const [announcements, setAnnouncements] = useState<PlatformAnnouncement[]>([])
    const navigate = useNavigate()
    const [drafts, setDrafts] = useState<PlatformAnnouncement[]>([])
    const [isDraftsInitialised, setIsDraftsInitialised] = useState(false)
    const [isDraftsLoading, setIsDraftsLoading] = useState(false)
    const { environments, selectedEnvironments, setSelectedEnvironments } = usePlatformEnvironments()
    const [environmentValue, setEnvironmentValue] = useState<string[]>(selectedEnvironments.map(env => env.name))

    useEffect(() => {
        const activeEnvName = window.TADA_APIS.ENVIRONMENT
        const fetchAnnouncements = async (data: PlatformAnnouncementFetchData) => {
            setIsAnnouncementsLoading(true)

            try {
                const response = await platformService.getAnnouncements(data)
                const announcements = response.announcements
                setAnnouncements(announcements)
            } catch (err) {
                console.error(err)
                setAnnouncements([])
            } finally {
                setIsAnnouncementsLoading(false)
                setIsAnnouncementsInitialised(true)
            }
        }

        if (isPlatformEnv) {
            if (activeAccount) {
                fetchAnnouncements({ scopes: environmentValue })
            } else {
                fetchAnnouncements({})
            }
        }
        if (!isPlatformEnv && activeEnvName) fetchAnnouncements({ scopes: [activeEnvName.toUpperCase()] })
    }, [isPlatformEnv, activeAccount, environmentValue])

    useEffect(() => {
        const fetchDrafts = async () => {
            setIsDraftsLoading(true)
            try {
                const drafts: PlatformAnnouncement[] = await platformService.getDrafts()
                setDrafts(drafts)
            } catch (err) {
                console.error(err)
                setDrafts([])
            } finally {
                setIsDraftsLoading(false)
                setIsDraftsInitialised(true)
            }
        }
        if (isPlatformEnv && activeAccount) fetchDrafts()
    }, [isPlatformEnv, activeAccount])

    const handleSearch = (term: string) => {
        setSearchTerm(term.toLowerCase())
    }
    const filteredAnnouncements = announcements.filter((announcement: PlatformAnnouncement) =>
        announcement.title.toLowerCase().includes(searchTerm)
    )
    const navigateToDetailsPage = (announcement: PlatformAnnouncement) => {
        navigate(PLATFORM_ROUTE_PATHS.ANNOUNCEMENT_DETAILS(announcement.id || ''))
    }
    const handleEnvironmentChange = (value: string[]) => {
        setEnvironmentValue(value)
        setSelectedEnvironments(
            value.map(envName => environments.find(env => env.name === envName) as PlatformEnvironment)
        )
    }

    return (
        <>
            <ListBanner className={classes.maxWidthAndPadding} cloudName={'Tada Now'} />
            <ListToolBar
                selectedApplication={null}
                toolBarClassName={classes.maxWidthAndPadding}
                environmentValue={environmentValue}
                onEnvironmentChange={handleEnvironmentChange}
                onSearch={handleSearch}
            />
            <Container className={classes.maxWidthAndPadding} sx={{ paddingBottom: 2 }}>
                {isPlatformEnv && activeAccount && isDraftsInitialised && drafts.length > 0 && (
                    <DraftAnnouncements drafts={drafts} isLoading={isDraftsLoading} />
                )}
                {isAnnouncementsInitialised && filteredAnnouncements.length === 0 && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography className={classes.errorText}>No announcements found.</Typography>
                    </Box>
                )}
                {!isAnnouncementsInitialised || isAnnouncementsLoading ? (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    filteredAnnouncements.map(announcement => (
                        <AnnouncementTile
                            key={announcement.id}
                            announcement={announcement}
                            isPlatformAdmin={!!activeAccount}
                            platformEnvironments={environments}
                            onActionClick={() => navigateToDetailsPage(announcement)}
                        />
                    ))
                )}
            </Container>
        </>
    )
}

export default PlatformAnnouncementListPage
