"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TadaServices;
var _axios = _interopRequireDefault(require("axios"));
var _createServices = _interopRequireDefault(require("./createServices"));
var _services = _interopRequireDefault(require("./services"));
var _toCamel = _interopRequireDefault(require("../utils/toCamel"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/** Services prototype that handles all service calls to Tada backend using axios library */
function TadaServices({
  baseUrls,
  getAccessKey,
  onUnauthorized,
  shouldUseToCamel,
  getAccessToken
}) {
  const instance = _axios.default.create();
  instance.interceptors.request.use(async config => {
    // only get accessKey if not provided (i.e. admin-portal provides other user access keys)
    if (!config.params?.accessKey && getAccessKey && addAccessKey(config.baseURL, baseUrls)) {
      config.params = {
        ...config.params,
        accessKey: await getAccessKey()
      };
    }
    if (getAccessToken && addAccessToken(config.noAccessToken, config.baseURL, baseUrls)) {
      config.headers.Authorization = `Bearer ${await getAccessToken()}`;
    }
    if (getAccessToken && config.url === 'security/saveusers') {
      config.headers.Authorization = `Bearer ${await getAccessToken()}`;
    }
    return config;
  }, error => Promise.reject(error));
  instance.interceptors.response.use(response => response, error => errorResponse(error));
  function errorResponse(error) {
    if (_axios.default.isCancel(error)) return Promise.reject(error);
    const {
      response
    } = error;
    if (response) {
      if (response.status === 401 && onUnauthorized) onUnauthorized();else if (response.data) {
        // eslint-disable-next-line prefer-const
        let {
          status,
          data
        } = response;
        if (typeof data !== 'object') data = {
          Message: data
        };
        const body = {
          ...data,
          status
        };
        return Promise.reject(shouldUseToCamel ? (0, _toCamel.default)(body) : body);
      }
    } else {
      return Promise.reject({
        message: 'An unexpected error occurred.'
      });
    }
    return Promise.reject(response);
  }
  const serviceGenerator = (0, _createServices.default)(instance, shouldUseToCamel);
  return (0, _services.default)(serviceGenerator, baseUrls);
}
function addAccessKey(baseUrl, baseUrls) {
  const urlKey = getUrlKeyFromUrl(baseUrl, baseUrls);
  switch (urlKey) {
    case 'collab':
    case 'visuals':
    case 'spatial':
    case 'domainregistryapi':
    case 'message':
      return false;
    default:
      return true;
  }
}
function addAccessToken(noAccessToken, baseUrl, baseUrls) {
  if (noAccessToken) return false;
  const urlKey = getUrlKeyFromUrl(baseUrl, baseUrls);
  switch (urlKey) {
    case 'collab':
    case 'visuals':
    case 'spatial':
    case 'domainregistryapi':
    case 'message':
    case 'platform':
      return true;
    default:
      return false;
  }
}
const getUrlKeyFromUrl = (baseUrl, baseUrls) => Object.entries(baseUrls).find(([key, url]) => url === baseUrl)[0];