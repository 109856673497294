import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    SxProps,
    Theme,
    CircularProgress,
    Box,
    Chip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AnnouncementTile } from 'genesis-suite/components'
import { announcementService } from 'src/lib/services'
import { SolutionAnnouncement, SolutionAnnouncementScope } from 'genesis-suite/types/announcementTypes'
import { Portal } from 'src/types'
import { useNavigate, useParams } from 'react-router-dom'
import { SOLUTION_ROUTE_PATHS } from 'src/lib/routePath'

interface PortalAnnouncementsProps {
    portal: Portal
    searchTerm: string
    sx?: SxProps<Theme>
}

const PortalAnnouncements: React.FC<PortalAnnouncementsProps> = ({ portal, searchTerm, sx = {} }) => {
    const [loading, setLoading] = useState(false)
    const [announcements, setAnnouncements] = useState<SolutionAnnouncement[] | null>(portal.announcements || null)
    const [announcementsCount, setAnnouncementsCount] = useState<number | null>(portal.announcementsCount || 0)
    const { cloudName } = useParams()
    const navigate = useNavigate()

    // Fetch announcements and portal info
    const loadAnnouncements = async () => {
        setLoading(true)
        try {
            const response = await announcementService.getAnnouncements({
                CloudId: portal.cloudId,
                AppId: portal.applicationId,
                PortalId: portal.id,
            })

            const fetchedAnnouncements: SolutionAnnouncement[] = response.announcements.filter(
                (announcement: SolutionAnnouncement) => announcement.scope === SolutionAnnouncementScope.Portal
            )
            setAnnouncements(fetchedAnnouncements)
            setAnnouncementsCount(fetchedAnnouncements.length || 0)
        } catch (error) {
            console.error('Failed to load announcements:', error)
        } finally {
            setLoading(false)
        }
    }

    const filteredAnnouncements = announcements?.filter(announcement =>
        announcement.title.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const onAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
        event.preventDefault()
        if (expanded && announcements === null && !loading) {
            loadAnnouncements()
        }
    }

    const onDetailsClick = (announcement: SolutionAnnouncement) => {
        navigate(SOLUTION_ROUTE_PATHS.ANNOUNCEMENT_DETAILS(cloudName || '' , announcement.id || ''))
    }

    return (
        <Accordion sx={sx} key={portal.id} onChange={onAccordionChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${portal.id}-content`}
                id={`panel-${portal.id}-header`}
            >
                <Typography variant="h6" fontWeight="bold">
                    {portal.title}
                </Typography>
                <Chip
                    label={announcementsCount}
                    color="primary"
                    size="small"
                    sx={{ marginLeft: 1 ,marginTop: '4px' , fontWeight: 'bold'}} 
                />
            </AccordionSummary>
            <AccordionDetails>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={24} />
                    </Box>
                ) : filteredAnnouncements && filteredAnnouncements.length > 0 ? (
                    filteredAnnouncements.map(announcement => (
                        <AnnouncementTile
                            key={announcement.id}
                            announcement={announcement}
                            onActionClick={() => onDetailsClick(announcement)}
                        />
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No announcements available for this portal.
                    </Typography>
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default PortalAnnouncements
