import React from 'react'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Help } from 'genesis-suite/icons'

export default function HelpLink() {
    return (
        <React.Fragment>
            <ListItemIcon>
                <Help color="primary" />
            </ListItemIcon>
            <Link
                href="https://tada.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                color="textPrimary"
            >
                {'Help Center'}
            </Link>
        </React.Fragment>
    )
}
